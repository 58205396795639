import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Menu from '../component/common/Menu'
import Main from '../component/Main'
import { KEY } from '../constants/const'
import { isAllow } from '../function/isAllow'
import { PUBLIC_ROUTE } from '../route/RouteDefinition'
import { RootState } from '../store/_store'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'
import { Modal } from '@mui/material'

const Loading = styled.div`
  span {
    margin-bottom: 8px;
    i {
      background-color: #00a796;
    }
  }
  div {
    color: #00a796;
    font-weight: 500;
    font-size: 16px;
  }
`

const Wrapper = styled.div`
  display: flex;
`
const Layout = ({ children }) => {
  const route = useHistory()

  const { loading } = useSelector((state: RootState) => state.loadingSlice)

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAllow()) {
        localStorage.removeItem(KEY.ACCESS_TOKEN)
        localStorage.removeItem('expiredAtUTC')
        route.push(PUBLIC_ROUTE.LOGIN.path)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Wrapper>
      <Menu />
      <Main>{children}</Main>
      <Modal
        open={loading}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0,0,0,0.2)'
        }}
      >
        <Loading>
          <Spin tip="กำลังโหลด..." />
        </Loading>
      </Modal>
    </Wrapper>
  )
}
export default Layout
