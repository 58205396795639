import Box from '@mui/material/Box'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { AppButton } from '../../component/common/AppButton'
import AppInput from '../../component/common/AppInput'
import AppInputLabel from '../../component/common/AppInputLabel'
import { Button } from '../../component/ModalDouleButton'
import { InputBlock } from '../../page/Login'
import {
  ContentTop,
  ContentBottom
} from '../WasteRequest/DetailRequest/DetailStyle'
import { sendCode } from '../../api'

export const ModalDigital = ({
  open,
  onDimiss,
  nameProduct,
  id,
  senderId,
  getRedeemOrderList
}) => {
  const [code, setCode] = useState('')

  const handleCallApi = async () => {
    try {
      const res = await sendCode({
        id: id,
        redeemValue: code,
        status: 'sent'
      })
      if (!res.error) {
        onDimiss()
        setCode('')
        getRedeemOrderList()
      }
    } catch (error) {
      console.log(`error`, error)
    }
  }

  return (
    <>
      <Modal title="กรุณาใส่โค้ดส่วนลด" visible={open} onCancel={onDimiss}>
        <ContentTop>
          <div>{`${senderId.firstname} ${senderId.lastname}`}</div>
          <div>{nameProduct}</div>
        </ContentTop>
        <ContentBottom>
          <Box
            sx={{
              marginTop: '8px',
              color: '#2C2C2C'
            }}
          >
            <AppInputLabel>โค้ดส่วนลด</AppInputLabel>
            <InputBlock
              style={{
                width: '330px',
                height: '48px',
                border: `1px solid #CCDDD9`
              }}
            >
              <AppInput
                fullWidth
                type="text"
                placeholder="ใส่โค้ดส่วนลด"
                onChange={(e) =>
                  e.target.value.length < 15 && setCode(e.target.value)
                }
                sx={{
                  border: 'none',
                  background: 'transparent'
                }}
                value={code}
              />
            </InputBlock>
          </Box>
        </ContentBottom>

        <div style={{ display: 'flex', gap: '8px', marginLeft: '64px' }}>
          <AppButton
            sx={{ width: '157px', mt: '32px', height: '32px' }}
            onClick={() => handleCallApi()}
          >
            ยืนยัน
          </AppButton>

          <Button onClick={onDimiss}>ยกเลิก</Button>
        </div>
      </Modal>
    </>
  )
}
