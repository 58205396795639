import React from 'react'
import styled from 'styled-components'

const HeaderStyle = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  .title-root {
    color: #0847eb;
    text-decoration-line: underline;
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
  }

  .title-root-child {
    margin-left: 8px;
  }

  .title-sub {
    padding-left: 8px;
  }
`

const Header = ({ children }) => <HeaderStyle>{children}</HeaderStyle>
export default Header
