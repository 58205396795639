import moment from 'moment'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { updateStatusWasteRequest } from '../../../api'
import { AppButton } from '../../../component/common/AppButton'
import SelectSimple from '../../../component/SelectSimple'
import { TYPE_OF_DATE } from '../../../constants/date'
import { convertToThai } from '../../../utils/func'
import { AreaFieldAdd } from '../../ProductCatalog/AddNewProduct/ProductDescription/AreaFieldAdd'
import { Title } from '../../ProductCatalog/AddNewProduct/ProductImages/ImageItem'
import { ModalCheckingToPoint } from '../ModalCheckingToPoint'
import { ModalPendingToPoint } from '../ModalPendingToPoint'
import { ModalPendingToReceive } from '../ModalPendingToReceived'
import {
  CancelOrder,
  ContentField,
  HeaderDetail,
  LineBold,
  LineThin,
  MessageBlock,
  NoImg,
  NoInput,
  PointAdded,
  Status,
  TitleField,
  WrapperDetail,
  WrapperImg
} from './DetailStyle'
import { MessageCancelItem } from './MessageCancelItem'
import { ModalEditPoint } from './ModalEditPoint'

export const RequestInfo = ({
  dataDetail,
  setIsCancel,
  handleEditDescription,
  fetchDetail
}) => {
  const [pendingToReceived, setPendingToReceived] = useState(false)
  const [checkingToPoint, setCheckingToPoint] = useState(false)
  const [pendingToPoint, setPendingToPoint] = useState(false)
  const [editPoint, setEditPoint] = useState(false)
  const [valueDescription, setValueDescription] = useState('')
  const [statusChange, setStatusChange] = useState('')
  const route = useHistory()
  const idWaste = route.location.pathname.split('/')[3]

  const user = dataDetail.userId

  const handleChangeStatus = async (status) => {
    const res = await updateStatusWasteRequest({
      id: idWaste,
      status
    })
    if (!res.error) {
      fetchDetail()
    }
  }

  return (
    <>
      <WrapperDetail>
        <HeaderDetail>
          <div className="order-id">ออเดอร์เลขที่</div>
          <div className="id">{idWaste}</div>
          <div className={` status status-${dataDetail.status.value}`}>
            {dataDetail.status.name}
          </div>
          <div className="date-time">
            อัปเดตล่าสุด{' '}
            {convertToThai(dataDetail.updatedAt, TYPE_OF_DATE.DATE_HOUR_FULL)}
          </div>
        </HeaderDetail>

        <LineBold />

        {/* ====delivery detail====== */}

        <Title>ข้อมูลพัสดุ</Title>

        <TitleField>คนสร้างออเดอร์</TitleField>
        <ContentField>
          {dataDetail.createdByRef === 'officer' ? (
            <span>Admin</span>
          ) : (
            <span>User</span>
          )}{' '}
          : {dataDetail.createdBy ? dataDetail.createdBy.email : '-'}
        </ContentField>
        <div style={{ display: 'flex', gap: '100px' }}>
          <div>
            {/* Delievery company */}
            <TitleField>บริษัทขนส่ง</TitleField>
            <ContentField>
              {dataDetail.deliveryId.image ? (
                <img src={dataDetail.deliveryId.image} />
              ) : (
                <span></span>
              )}

              {dataDetail.deliveryId.name ? dataDetail.deliveryId.name : '-'}
            </ContentField>

            {/* Location */}

            <TitleField>จังหวัด</TitleField>
            <ContentField>
              {dataDetail.provinceId ? dataDetail.provinceId.name : '-'}
            </ContentField>

            {/* Created date */}

            <TitleField>วันที่สร้าง</TitleField>
            <ContentField>
              {convertToThai(dataDetail.createdAt, TYPE_OF_DATE.DATE_HOUR_FULL)}
            </ContentField>

            {/* User’s note */}

            <TitleField>ข้อความจากผู้ใช้</TitleField>
            {dataDetail.description ? (
              <ContentField>{dataDetail.description}</ContentField>
            ) : (
              <NoInput>ไม่ได้ระบุ</NoInput>
            )}
          </div>

          <div>
            {/* Tracking no. */}
            <TitleField>Tracking no.</TitleField>
            <ContentField>{dataDetail.trackingno || '-'}</ContentField>

            {/* Parcel weight*/}

            <TitleField>น้ำหนักพัสดุ</TitleField>
            {dataDetail.parcelweight ? (
              <ContentField>{dataDetail.parcelweight} กก.</ContentField>
            ) : (
              <NoInput>ไม่ได้ระบุ</NoInput>
            )}
          </div>
        </div>

        <LineThin />
        {/* ====Image====== */}

        <Title>รูปภาพ</Title>
        {dataDetail.productId.listImage.length > 0 ? (
          <div style={{ display: 'flex', gap: '18px' }}>
            {dataDetail.productId.listImage.map((img) => (
              <WrapperImg>
                {' '}
                <img src={img} alt="no-img" />
              </WrapperImg>
            ))}
          </div>
        ) : (
          <NoImg>ไม่มีรูปภาพ</NoImg>
        )}

        <LineThin />

        {/* ====Status====== */}
        {!['cancelled', 'completed', 'cancelledbyuser'].includes(
          dataDetail.status.value
        ) && (
          <CancelOrder onClick={() => setIsCancel(true)}>
            ยกเลิกออเดอร์นี้
          </CancelOrder>
        )}

        <Title>สถานะ</Title>

        <Status>
          <div className={`status ${dataDetail.status.value}`}></div>
          <ContentField> {dataDetail.status.name}</ContentField>
        </Status>
        {dataDetail.listStatus.length > 0 && (
          <SelectSimple
            width={200}
            options={dataDetail.listStatus}
            handleSelect={(value) => {
              setStatusChange(value)
              if (
                dataDetail.status.value === 'pending' &&
                ['received', 'checking'].includes(value)
              ) {
                setPendingToReceived(true)
              } else if (
                ['checking', 'received'].includes(dataDetail.status.value) &&
                value === 'completed'
              ) {
                setCheckingToPoint(true)
              } else if (
                dataDetail.status.value === 'pending' &&
                value === 'completed'
              ) {
                setPendingToPoint(true)
              } else if (value === 'cancelled') {
                setIsCancel(true)
              } else {
                handleChangeStatus(value)
              }
            }}
            type="value"
            placeholder="เปลี่ยนสถานะ"
            waste={true}
            disabled={dataDetail.status.value === 'cancelled'}
          />
        )}

        {/* For status point added */}

        {dataDetail.status.value === 'completed' && (
          <PointAdded>
            <div className="title">
              <Title>{dataDetail.value} คะแนน</Title>{' '}
              <img
                src="/images/edit-black.png"
                onClick={() => setEditPoint(true)}
              />
            </div>
            <div className="item">
              (น้ำหนักขยะผ่านเกณฑ์ {dataDetail.quantity} กก.)
            </div>
          </PointAdded>
        )}

        <div style={{ display: 'flex', gap: '16px', marginTop: '32px' }}>
          <div>
            <AreaFieldAdd
              title="ข้อความ"
              placeholder="ใส่ข้อความ"
              maxLength={100}
              minRow={4}
              handleChange={(e) => setValueDescription(e.target.value)}
              value={valueDescription}
              waste={true}
            />
            <AppButton
              sx={{ width: '128px', height: '32px' }}
              onClick={() => {
                handleEditDescription(valueDescription), setValueDescription('')
              }}
            >
              เพิ่มข้อความ
            </AppButton>
          </div>

          {/* When status is cancle */}
          {dataDetail.descriptionExtend.length > 0 && (
            <MessageBlock>
              <div style={{ fontSize: '12px', color: '#00A796' }}>
                ข้อความที่ฝากไว้
              </div>
              {dataDetail.descriptionExtend.map((item) => (
                <MessageCancelItem data={item} />
              ))}
            </MessageBlock>
          )}
        </div>

        {/* from pending to received or pending or checking */}
        <ModalPendingToReceive
          open={pendingToReceived}
          onDimiss={() => setPendingToReceived(false)}
          id={idWaste}
          fetchDetail={fetchDetail}
          statusChange={statusChange}
          trackingno={dataDetail.trackingno}
          name={user.firstname + ' ' + user.lastname}
        />

        {/* from checking to point added */}
        <ModalCheckingToPoint
          open={checkingToPoint}
          onDimiss={() => setCheckingToPoint(false)}
          id={idWaste}
          fetchDetail={fetchDetail}
          trackingno={dataDetail.trackingno}
          name={user.firstname + ' ' + user.lastname}
        />

        {/* from pending to point added */}
        <ModalPendingToPoint
          open={pendingToPoint}
          onDimiss={() => setPendingToPoint(false)}
          id={idWaste}
          fetchDetail={fetchDetail}
          trackingno={dataDetail.trackingno}
          name={user.firstname + ' ' + user.lastname}
        />

        {/* edit point */}
        <ModalPendingToPoint
          open={editPoint}
          onDimiss={() => setEditPoint(false)}
          id={idWaste}
          fetchDetail={fetchDetail}
          isEditPoint={true}
          trackingno={dataDetail.trackingno}
          name={user.firstname + ' ' + user.lastname}
        />
      </WrapperDetail>
    </>
  )
}
