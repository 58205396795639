import moment from 'moment'
import * as React from 'react'
import { useEffect } from 'react'
import {
  getBrand,
  getCatagory,
  getConfig,
  getAllProvince,
  getProfile
} from './api'
import AppRouter from './route'
import { useAppMaster } from './store/_hook'
import 'antd/dist/antd.css'
import { KEY } from './constants/const'
import { isAllow } from './function/isAllow'
import { brandList, catagoryList, provinceList } from './store/selectData'
import { useDispatch } from 'react-redux'
import { PUBLIC_ROUTE } from './route/RouteDefinition'
import { useHistory } from 'react-router-dom'

const App: React.FC = () => {
  const { updateConfigApp } = useAppMaster()
  const dispatch = useDispatch()
  const route = useHistory()

  useEffect(() => {
    ;(async () => {
      try {
        const accessToken = localStorage.getItem(KEY.ACCESS_TOKEN)

        const [res, catagories, brands, provinces] = await Promise.all([
          getConfig(),
          getCatagory(),
          getBrand(),
          getAllProvince()
        ])

        dispatch(catagoryList(catagories.data))
        dispatch(brandList(brands.data))
        dispatch(provinceList(provinces.data))

        if (res.data) {
          const dataConfig = res.data

          updateConfigApp({
            characterSeparate: dataConfig.CHARACTER_SEPARATE_AUTH.value,
            encryptUser: dataConfig.PUBLIC_AUTH.subConfigs.PUBLIC_BASE_U.value,
            encryptPass: dataConfig.PUBLIC_AUTH.subConfigs.PUBLIC_BASE_P.value,
            encryptPassNew:
              dataConfig.PUBLIC_AUTH.subConfigs.PUBLIC_USER_P.value,
            encryptPassConfirm:
              dataConfig.PUBLIC_AUTH.subConfigs.PUBLIC_USER_P_CONFIRM.value,
            encryptPublicUser:
              dataConfig.PUBLIC_AUTH.subConfigs.PUBLIC_USER_U.value,
            regex: dataConfig.REGEX.subConfigs,
            productType: dataConfig.PRODUCT_TYPE.subConfigs,
            inventory: dataConfig.INVENTORY.subConfigs,
            gender: dataConfig.GENDER.subConfigs,
            accountType: dataConfig.USERGROUP.subConfigs,
            status: dataConfig.STATUS.subConfigs,
            postCode: dataConfig.POST_CODE.subConfigs,
            statusTransaction: dataConfig.STATUS_TRANSACTION.subConfigs,
            serviceId: dataConfig.SERVICE.subConfigs,
            statusRedeem: dataConfig.STATUS_REDEEM.subConfigs
          })
        }
        if (!accessToken || !isAllow()) {
          localStorage.removeItem(KEY.ACCESS_TOKEN)
          localStorage.removeItem('expiredAtUTC')
          route.push(PUBLIC_ROUTE.LOGIN.path)
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  return <AppRouter />
}

export default App
