import { Table, TableContainer } from '@mui/material'
import React from 'react'

const TableBlock = ({ children }) => {
  return (
    <TableContainer sx={{ borderRadius: '6px', border: '1px solid #CCDDD9' }}>
      <Table style={{ borderRadius: '4px 4px 0px 0px', overflow: 'hidden' }}>
        {children}
      </Table>
    </TableContainer>
  )
}

export default TableBlock
