import React from 'react'
import styled from 'styled-components'

const StylePanel = styled.div`
  border-radius: 8px;
  margin-top: 32px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  background: #ffffff;
`

const TitlePanel = styled.div`
  padding: 12px 24px;
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  background: #2c2c2c;
  border-radius: 8px 8px 0px 0px;
`
const Content = styled.div`
  padding: 32px 24px;
`
const Panel = ({ title, children }) => (
  <StylePanel>
    <TitlePanel>{title}</TitlePanel>
    <Content>{children}</Content>
  </StylePanel>
)
export default Panel
