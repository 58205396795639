import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Upload } from 'antd'

type Props = {
  images: any
  setSelectedImage: any
  type?: string
}

const MAX_IMG = {
  ['logo']: 1,
  ['detail']: 10,
  ['waste']: 3
}

const ImageItem = ({ images, setSelectedImage, type }: Props): JSX.Element => {
  const [overSize, setOverSize] = useState(false)
  const [errorType, setErrorType] = useState(false)
  const [overTotal, setOverTotal] = useState(false)

  useEffect(() => {
    if (images) {
      validateUpload()
    }
  }, [images])

  const handleSelectImg = (type, fileList) => {
    if (fileList.length <= MAX_IMG[type]) {
      setSelectedImage(fileList)
      setOverTotal(false)
    } else {
      setOverTotal(true)
    }
  }

  const validateUpload = () => {
    const arrImgOverSize = images.filter(
      (item) => item.originFileObj && item.size > 2097152
    )

    const arrImgErrorType = images.filter(
      (item) =>
        item.originFileObj &&
        item.type !== 'image/png' &&
        item.type !== 'image/jpeg'
    )
    if (arrImgOverSize.length > 0) {
      setOverSize(true)
    } else setOverSize(false)
    if (arrImgErrorType.length > 0) {
      setErrorType(true)
    } else setErrorType(false)

    if (images.length > MAX_IMG[type]) {
      setOverTotal(true)
    } else setOverTotal(false)

    if (images.length === 0) {
      setOverTotal(false)
      setOverSize(false)
      setErrorType(false)
    }
  }

  const props = {
    beforeUpload: (file) => {
      const typeSupport =
        file.type === 'image/png' || file.type === 'image/jpeg'
      const sizeMax = file.size < 2097152 //maximum 2M

      if (!typeSupport) {
        setErrorType(true)
      } else if (!sizeMax) {
        setOverSize(true)
      } else {
        setErrorType(false)
        setOverSize(false)
      }
      return (typeSupport && sizeMax) || Upload.LIST_IGNORE
    },
    onChange: ({ fileList }) => {
      handleSelectImg(type, fileList)
    }
  }

  const handleDelete = (index) => {
    const arrImg = images.filter((item, i) => i !== index)
    setSelectedImage(arrImg)
  }

  return (
    <>
      <div>
        <HeaderBlock>
          {' '}
          <Title>
            {type === 'waste'
              ? 'รูปขยะ (ไม่จำเป็นต้องระบุ)'
              : type === 'logo'
              ? 'รูปโลโก้แบรนด์'
              : 'รูปสินค้า'}
          </Title>{' '}
          <Upload
            multiple={type === 'logo' ? false : true}
            fileList={images}
            listType="picture"
            className="upload-list-inline"
            {...props}
            disabled={images && images.length >= MAX_IMG[type]}
            accept="image/png, image/jpg"
          >
            <ButtonUpload disabled={images && images.length >= MAX_IMG[type]}>
              <img
                src={
                  images && images.length >= MAX_IMG[type]
                    ? '/images/upload-disabled.png'
                    : '/images/upload.png'
                }
                alt="Upload Image"
              />
              <span>เพิ่มรูป</span>
            </ButtonUpload>
          </Upload>
        </HeaderBlock>

        {images && images.length > 0 ? (
          <ImagePreview error={overTotal}>
            {images.map((item, index) => (
              <ImageStyle>
                <img
                  alt=""
                  width={'100%'}
                  height={'100%'}
                  src={
                    item.originFileObj
                      ? URL.createObjectURL(item.originFileObj)
                      : item
                  }
                  style={{ objectFit: 'scale-down' }}
                />
                <img
                  alt=""
                  src="/images/delete-img.png"
                  className="delete"
                  width={24}
                  onClick={() => handleDelete(index)}
                  style={{ cursor: 'pointer' }}
                />
              </ImageStyle>
            ))}
            {overSize && (
              <BlockImgError>
                <img src="/images/error-img.png" />
                <TitleError>ไม่สามารอัปโหลดได้</TitleError>
                <ContentError>ไฟล์มีขนาดใหญ่เกิน 2 MB</ContentError>
              </BlockImgError>
            )}

            {errorType && (
              <BlockImgError>
                <img src="/images/error-img.png" />
                <TitleError>ไม่สามารอัปโหลดได้</TitleError>
                <ContentError>ใช้ได้เฉพาะไฟล์ .png, .jpg</ContentError>
              </BlockImgError>
            )}
          </ImagePreview>
        ) : !overSize && !errorType ? (
          <NotImagePreview error={overTotal || overSize || errorType}>
            <img src="/images/image-default.png" />
            <div>ไม่มีรูปภาพในขณะนี้</div>
          </NotImagePreview>
        ) : (
          <ImagePreview>
            {overSize && (
              <BlockImgError>
                <img src="/images/error-img.png" />
                <TitleError>ไม่สามารอัปโหลดได้</TitleError>
                <ContentError>ไฟล์มีขนาดใหญ่เกิน 2 MB</ContentError>
              </BlockImgError>
            )}

            {errorType && (
              <BlockImgError>
                <img src="/images/error-img.png" />
                <TitleError>ไม่สามารอัปโหลดได้</TitleError>
                <ContentError>ใช้ได้เฉพาะไฟล์ .png, .jpg</ContentError>
              </BlockImgError>
            )}
          </ImagePreview>
        )}

        <CountImg>
          {' '}
          <img src="/images/img-sx.png" width="14px" /> (
          {(images && images.length) || 0}/{MAX_IMG[type]})
        </CountImg>

        {type !== 'waste' ? (
          <Note>
            <li className={`${overTotal && 'error'}`}>
              อัปโหลดได้ไม่เกิน {MAX_IMG[type]} รูป
            </li>
            <li>
              {type === 'logo'
                ? 'ขนาดภาพที่ใช้ได้ 160 x 160px '
                : 'ขนาดภาพที่แนะนำ 360 x 320px '}
            </li>
            <li>ขนาดไฟล์สูงสุดที่ 2 MB</li>
            <li>สกุลไฟล์ที่ใช้ได้ .png, .jpg</li>
          </Note>
        ) : (
          <Note>
            <li>ขนาดรูปภาพที่แนะนำ 320x360px</li>
            <li>ขนาดไฟล์สูงสุดไม่เกิน 2 MB </li>
            <li>สกุลไฟล์ที่ใช้ได้ .png, .jpg</li>
            <li className={`${overTotal && 'error'}`}>
              เพิ่มได้สูงสุด {MAX_IMG[type]} รูป
            </li>
          </Note>
        )}
      </div>
    </>
  )
}

export default ImageItem

export const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #00a796;
`
const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
`

const ImagePreview = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  width: 100%;
  min-height: 188px;
  background: #eff1f4;
  border: 1px solid ${({ error }) => (error ? '#FD4A1E' : '#ccddd9')};
  border-radius: 4px;
  margin-top: 24px;
  padding: 16px 20px;
`

export const Note = styled.ul`
  margin: 24px 24px 0px;
  font-weight: 400;
  font-size: 16px;
  color: #444444;

  .error {
    color: #fd4a1e;
    font-size: 16px;
  }
`

const ButtonUpload = styled.label`
  background: ${({ disabled }) => (disabled ? '#DADADA' : '#00a796')};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  color: ${({ disabled }) => (disabled ? '#444444' : '#fff')};
  padding: 10px 12px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  width: 228px;
  height: 48px;

  img {
    width: 24px !important;
    height: 24px !important;
    margin-right: 4px;
  }
`

export const NotImagePreview = styled(ImagePreview)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${({ error }) => (error ? '#FD4A1E' : '#ccddd9')};
  color: #6b7c78;
`

const ImageStyle = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
  height: 160px;
  background: #ffffff;
  border: 1px solid #ccddd9;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;

  .delete {
    position: absolute;
    width: 24px;
    bottom: 4px;
    right: 4px;
  }
`

export const CountImg = styled.div`
  text-align: right;
  color: #6b7c78;
  font-weight: 400;
  font-size: 12px;
`

const TitleError = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #6b7c78;
  margin-top: 4px;
`

const ContentError = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #6b7c78;
`

const BlockImgError = styled(ImageStyle)`
  border-color: red;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`
