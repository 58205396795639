import { Box, Grid, Theme, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { forgotPassword } from '../../api'
import { AppButton } from '../../component/common/AppButton'
import AppInput from '../../component/common/AppInput'
import AppInputLabel from '../../component/common/AppInputLabel'
import { encryptAuth } from '../../function/ecrypt'
import styled from 'styled-components'
import { PUBLIC_ROUTE } from '../../route/RouteDefinition'
import { useAppMaster } from '../../store/_hook'
import { ErrorDialog } from '../../component/ErrorDialog'
import { InputBlock } from '../Login'
import ModalBasic from '../../component/ModalBasic'
import customToast from '../../component/Toast'

const Tit = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #2c2c2c;
`
const Mes = styled.div`
  margin-left: 15px;
`

const ForgotPassword = () => {
  const {
    state: { configMaster }
  } = useAppMaster()

  const route = useHistory()
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMassage] = useState('')
  const [isError, setIsError] = useState(false)
  const [messageError, setMessage] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [isDisable, setIsDisable] = useState(false)

  const regex = configMaster.regex

  const handleSend = async () => {
    const emailFinal = encryptAuth(
      email,
      configMaster.encryptPublicUser,
      configMaster.characterSeparate
    )

    try {
      const res = await forgotPassword({ email: emailFinal })
      if (!res.error) {
        setIsError(false)
        // route.push(`${PUBLIC_ROUTE.RECOVER_PASSWORD.path}?token=${res.token}`)
        setOpenModal(true)
      } else {
        setIsError(true)
        setIsDisable(false)
        setMessage(res.message)
      }
    } catch (error) {
      console.log(error.message)
      setIsError(true)
    }
  }

  const handleValidate = (value) => {
    setEmail(value)
    //@ts-ignore
    const regexPass = new RegExp(regex.REGEX_EMAIL.value)
    if (!regexPass.test(value) && value !== '') {
      //@ts-ignore
      setErrorMassage(regex.REGEX_EMAIL.name)
    } else setErrorMassage('')
  }

  const mesSuccess = (
    <Mes>
      <Tit>ส่งอีเมลสำเร็จ</Tit>
    </Mes>
  )

  const handleResendEmail = () => {
    handleSend(), customToast.success(mesSuccess)
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        sx={{
          backgroundColor: (theme: Theme) => theme.color.gray1
        }}
      >
        <Box sx={{ maxWidth: '375px' }}>
          <Typography
            sx={{
              color: (theme: Theme) => theme.color.black,
              fontSize: '32px',
              fontWeight: 700
            }}
          >
            ลืมรหัสผ่าน
          </Typography>
          <Typography
            sx={{
              color: (theme: Theme) => theme.color.black,
              fontSize: '20px',
              fontWeight: 400
            }}
          >
            กรุณาใส่อีเมล เราจะส่งลิงก์ให้เพื่อทำการสร้างรหัสผ่านใหม่
          </Typography>
          <Box sx={{ mt: '22px' }}>
            <AppInputLabel>อีเมล</AppInputLabel>
            <InputBlock errors={isError || errorMessage !== ''}>
              <AppInput
                fullWidth
                type="text"
                placeholder="กรุณาใส่อีเมล"
                value={email}
                onChange={(e) => handleValidate(e.target.value)}
                sx={{
                  border: 'none',
                  background: 'transparent'
                }}
              />
              {email !== '' ? (
                <img
                  src="/icons/iconX.png"
                  onClick={() => {
                    setEmail(''), setErrorMassage(''), setIsDisable(false)
                  }}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <img src="/icons/email.png" />
              )}
            </InputBlock>
            <ErrorMessage>
              {(isError && email !== '') || errorMessage !== ''
                ? 'อีเมลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง'
                : isError && email === '' && 'จำเป็นต้องระบุ'}
            </ErrorMessage>
          </Box>
          <AppButton
            sx={{ width: '200px', mt: '32px' }}
            disabled={isDisable}
            onClick={() => {
              handleSend(), setIsDisable(true)
            }}
          >
            ยืนยัน
          </AppButton>
          <AppButton
            sx={{ width: '100px', mt: '32px', marginLeft: '10px' }}
            color="secondary"
            onClick={() => route.push(PUBLIC_ROUTE.LOGIN.path)}
          >
            กลับ
          </AppButton>
        </Box>

        <ModalBasic
          title="ส่งลิงก์ตั้งรหัสผ่านใหม่ไปที่อีเมลเเล้ว"
          open={openModal}
          onDimiss={() => {
            setOpenModal(false), setIsDisable(false)
          }}
        >
          <Content>กรุณาเข้าลิงก์ที่ส่งไปในอีเมล เพื่อตั้งรหัสผ่านใหม่</Content>
          <Resend onClick={() => handleResendEmail()}>ส่งอีเมลอีกครั้ง</Resend>
        </ModalBasic>
      </Grid>
    </>
  )
}

export default ForgotPassword

const ErrorMessage = styled.div`
  color: red;
`
export const Content = styled.div`
  padding: 4px 4px 8px 0;
  font-weight: 400;
  font-size: 16px;
`

const Resend = styled.div`
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-decoration-line: underline;
  color: #00a796;
  margin-bottom: 24px;
  cursor: pointer;
`
