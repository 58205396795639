import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { accountVerifications, checkExpiration } from '../../api'
import { KEY } from '../../constants/const'
import { PUBLIC_ROUTE } from '../../route/RouteDefinition'
import { remove } from '../../utils/theme'

export const AccountVerifications = () => {
  const [isSuccess, setIsSuccess] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const route = useHistory()
  const url = window.location.search
  const params = new URLSearchParams(url)

  const checkExpirationToken = async () => {
    remove()
    const res = await checkExpiration({
      token: params.get('token'),
      status: params.get('status')
    })
    if (res.error) {
      if (res.error == 422) {
        setIsSuccess(false)
        setIsLoading(false)
      } else {
        route.push(PUBLIC_ROUTE.NOT_FOUND.path)
        setIsLoading(false)
      }
    } else {
      checkVerify()
    }
  }

  const checkVerify = async () => {
    const res = await accountVerifications({
      token: params.get('token')
    })
    if (res.error) {
      setIsSuccess(false)
      setIsLoading(false)
    } else {
      setIsSuccess(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    checkExpirationToken()
  }, [])

  return (
    <>
      {isLoading ? (
        <WrapperMobile>
          <Title>กำลังตรวจสอบ....</Title>
        </WrapperMobile>
      ) : (
        <WrapperMobile>
          {isSuccess ? (
            <img src="/images/verify-success.png" />
          ) : (
            <img src="/images/verify-fail.png" />
          )}
          <Title>{isSuccess ? "ยืนยันตัวตนสำเร็จ!" : "ลิงก์ไม่ถูกต้อง"}</Title>
          <Message>
            {isSuccess
              ? 'คุณสามารถลงชื่อเข้าใช้ SWAP recycling แอปพลิเคชั่น ด้วยอีเมลที่สมัครสมาชิก'
              : 'ลิงก์การยืนยันตัวตนได้หมดอายุหรือถูกใช้แล้ว กรุณาลงชื่อเข้าใช้ SWAP recycling แอปพลิเคชั่น ด้วยอีเมลที่สมัครสมาชิก เพื่อตรวจสอบการยืนยันตัวตนอีกครั้ง'}
          </Message>
        </WrapperMobile>
      )}
    </>
  )
}

export const WrapperMobile = styled.div`
  margin: 45px 32px;
  text-align: center;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #0f0f0f;
  margin-top: 30px;
`

export const Message = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #757575;
  margin-top: 8px;
`
