import Typography from '@mui/material/Typography'
import React, { useState, useEffect } from 'react'
import { getAdminAccount, getUserRole } from '../../api'
import Layout from '../../layouts/DefaultLayout'
import TableBlock from '../../component/TableBlock'
import THead from '../../component/THead'
import { TableCellStyle } from '../ProductCatalog'
import { TableBody } from '@mui/material'
import styled from 'styled-components'
import AdminItem from '../../features/AdminAccount/AdminItem'
import { PAGE_SIZE } from '../ProductCatalog'
import PaginationSquare from '../../component/PaginationSquare'
import { Box } from '@material-ui/core'
import ProductEmpty from '../../features/ProductCatalog/ProductList/ProductEmpty'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const Number = styled.span`
  padding: 0 6px;
  font-weight: 700;
  font-size: 1.6rem;
`

const DisplayResult = ({ total }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ fontSize: '1.6rem', color: '#444' }}>แสดง</span>
      <Number>{total}</Number>
      <span style={{ fontSize: '1.6rem', color: '#444' }}>รายการ</span>
    </Box>
  )
}

export const AdminAccount = () => {
  const [total, setTotal] = useState(0)
  const [dataSearch, setDataSearch] = useState<any>({
    start: 1,
    number: 10
  })
  const [dataList, setDataList] = useState<any>([])
  const [roleList, setRoleList] = useState<any>([])

  const fetchData = async () => {
    try {
      const customerList = await getAdminAccount(dataSearch)
      if (!customerList.error) {
        setDataList(customerList.data)
        setTotal(customerList.total)
      }

      const roleList = await getUserRole(dataSearch)
      if (!roleList.error) {
        setRoleList(roleList.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [dataSearch])

  return (
    <>
      <Layout>
        <Wrapper>
          <Typography
            sx={{
              fontSize: '3.2rem',
              fontWeight: 'bold'
            }}
          >
            จัดการบัญชีแอดมิน
          </Typography>
        </Wrapper>
        <Wrap>
          <DisplayResult total={total} />
          <PaginationSquare
            dataSearch={dataSearch}
            setDataSearch={setDataSearch}
            totalPage={Math.ceil(total / PAGE_SIZE)}
          />
        </Wrap>
        {dataList && dataList.length > 0 ? (
          <TableBlock>
            <THead>
              <TableCellStyle>อีเมล</TableCellStyle>
              <TableCellStyle>หน้าที่</TableCellStyle>
              <TableCellStyle>ชื่อแอดมิน</TableCellStyle>
              <TableCellStyle>วันที่สร้าง</TableCellStyle>
              <TableCellStyle>วันที่ล็อกอินล่าสุด</TableCellStyle>
              <TableCellStyle></TableCellStyle>
            </THead>
            <TableBody>
              {dataList.map((item, index) => {
                return <AdminItem key={index} item={item} roleList={roleList} />
              })}
            </TableBody>
          </TableBlock>
        ) : (
          <>
            {/* <DisplayResult total={total} /> */}
            <ProductEmpty type="waste" />
          </>
        )}

        <div style={{ marginTop: 13 }}>
          <PaginationSquare
            dataSearch={dataSearch}
            setDataSearch={setDataSearch}
            totalPage={Math.ceil(total / PAGE_SIZE)}
          />
        </div>
      </Layout>
    </>
  )
}
