import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import SelectSimple from '../../../../component/SelectSimple'
import { PAGE_SIZE } from '../../../../page/ProductCatalog'
import { useAppMaster } from '../../../../store/_hook'
import { RootState } from '../../../../store/_store'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 8px;
  padding-top: 16px;
`
type Props = {
  setDataSearch: any
  dataSearch: any
}

const SelectField = ({ setDataSearch, dataSearch }: Props) => {
  const {
    state: { configMaster }
  } = useAppMaster()

  const { dataCatagory, dataBrand } = useSelector(
    (state: RootState) => state.selectSlice
  )

  const handleCategory = (id) => {
    const result = Object.values(dataCatagory).filter(
      (item) => item.id === Number(id)
    )

    return result[0].name
  }

  return (
    <>
      <Wrapper>
        {/* Catagory */}
        <SelectSimple
          options={Object.values(dataCatagory)}
          handleSelect={(value) => {
            setDataSearch({
              ...dataSearch,
              categoryId: Number(value),
              start: 1
            })
          }}
          type="id"
          placeholder="หมวดหมู่"
          value={
            dataSearch.categoryId ? handleCategory(dataSearch.categoryId) : ''
          }
          fontSize="1.6rem"
        />

        {/* Brand */}
        <SelectSimple
          options={Object.values(dataBrand)}
          handleSelect={(value) =>
            setDataSearch({ ...dataSearch, brandId: value, start: 1 })
          }
          type="id"
          placeholder="แบรนด์"
          value={dataSearch.brandId || ''}
          fontSize="1.6rem"
        />

        {/* Product Type */}
        <SelectSimple
          options={Object.values(configMaster.productType)}
          handleSelect={(value) =>
            setDataSearch({ ...dataSearch, productType: value, start: 1 })
          }
          type="value"
          placeholder="ชนิดสินค้า"
          value={dataSearch.productType || ''}
          fontSize="1.6rem"
        />

        {/* Status */}
        <SelectSimple
          options={[
            {
              value: 'active',
              id: 1000095,
              name: 'เปิดการใช้งาน'
            },
            {
              value: 'inactive',
              id: 1000098,
              name: 'ปิดการใช้งาน'
            }
          ]}
          handleSelect={(value) =>
            setDataSearch({ ...dataSearch, status: value, start: 1 })
          }
          type="value"
          placeholder="สถานะ"
          value={dataSearch.status || ''}
          fontSize="1.6rem"
        />
      </Wrapper>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          sx={{
            paddingTop: '8px',
            paddingBottom: '32px',
            fontWeight: 700,
            fontSize: '1.4rem',
            textDecoration: 'underline',
            cursor: 'pointer',
            width: '66px'
          }}
          style={{ color: '#6B7C78' }}
          onClick={() => setDataSearch({ start: 1, number: PAGE_SIZE })}
        >
          ล้างข้อมูล
        </Typography>
      </div>
    </>
  )
}

export default SelectField
