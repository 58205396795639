import { Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Layout from '../../layouts/DefaultLayout'
import styled from 'styled-components'
import DownloadIcon from '../../asset/images/download.svg'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import SelectSimple from '../../component/SelectSimple'
import AppInputLabel from '../../component/common/AppInputLabel'
import { Box } from '@material-ui/core'
import SucessIcon from '../../asset/images/sucess-icon.svg'
import EIcon from '../../asset/images/error-icon.svg'
import { deviceDetec } from '../../deviceDetec/deviceDetector'

const Error = ({ title, description }) => {
  return (
    <Box
      sx={{
        marginTop: 24
      }}
    >
      <Er>
        <img src={EIcon} alt="error-icon" />
        {title}
      </Er>
      <Des>{description}</Des>
    </Box>
  )
}

const Activity = () => {
  const [startDate, setStartDate] = useState(new Date(Date.now() - 86400000))
  const [endDate, setEndDate] = useState(new Date())
  const [isAfter, setIsAfter] = useState(false)
  const [typeDownload, setTypeDownload] = useState('')
  const [isNoti, setIsNoti] = useState(false)
  const [status, setStatus] = useState(0)
  const abortController = useRef(null)

  const handleChange = (value: string) => {
    setTypeDownload(value)
  }

  useEffect(() => {
    if (startDate && endDate) {
      setIsAfter(
        moment(moment(endDate).format('YYYY-MM-DD')).isSameOrAfter(
          moment(startDate).format('YYYY-MM-DD')
        )
      )
    }
  }, [startDate, endDate])

  const handleCancel = () => {
    abortController.current && abortController.current.abort()
    setIsNoti(false)
    setStatus(0)
  }

  const handleChangeThai = (typeDownload: any) => {
    switch (typeDownload) {
      case 'User':
        return 'รายงานข้อมูลผู้ใช้'
      case 'ProductHistory':
        return 'รายการแลกของ'
      default:
        return 'รายการส่งขยะ'
    }
  }

  const getApi = useCallback(async (startDate, endDate, typeDownload) => {
    setIsNoti(true)
    try {
      abortController.current = new AbortController()
      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/api/transaction/v1/export?start=${moment(startDate).format(
          'YYYY-MM-DD'
        )}&end=${moment(endDate).format('YYYY-MM-DD')}&type=${typeDownload}`,
        {
          signal: abortController.current.signal,
          method: 'GET',
          headers: {
            device: JSON.stringify(deviceDetec),
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            'Accept-Language': 'th'
          }
        }
      )
      setIsNoti(false)
      setStatus(response.status)
      if (response.status === 200) {
        const data = await response.blob()
        handleDownload(data, startDate, endDate, typeDownload)
      }
    } catch (error) {}
  }, [])

  const handleDownload = (
    res: any,
    startDate: any,
    endDate: any,
    typeDownload: any
  ) => {
    const url = window.URL.createObjectURL(new Blob([res]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      `SWAP Recycling data_${handleChangeThai(typeDownload)}_${moment(
        startDate
      ).format('YYYYMMDD')}_${moment(endDate).format('YYYYMMDD')}.csv`
    )
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  const handleStatus = (status: any) => {
    if (status > 0) {
      if (status === 404) {
        return (
          <Error
            title="ไม่พบประวัติที่คุณกำลังค้นหา!"
            description="โปรดลองเลือกข้อมูลใหม่อีกครั้ง"
          />
        )
      }
      if (status === 413) {
        return (
          <Error
            title="ไฟล์มีขนาดใหญ่เกินไปที่จะดาวน์โหลดได้"
            description="กรุณาเลือกช่วงเวลาใหม่อีกครั้ง"
          />
        )
      }
      if (status === 200) {
        return (
          <Box
            sx={{
              marginTop: '24px'
            }}
          >
            <Sucess>
              <img src={SucessIcon} alt="sucess-icon" />
              ดาวน์โหลดสำเร็จ!
            </Sucess>
          </Box>
        )
      }
      return (
        <Error
          title="เกิดข้อผิดพลาด!"
          description="ไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้งภายหลัง"
        />
      )
    } else {
      return null
    }
  }

  return (
    <Layout>
      <Wrapper>
        <Typography
          sx={{
            fontSize: '3.2rem',
            fontWeight: 'bold'
          }}
        >
          ดาวน์โหลดข้อมูล
        </Typography>
      </Wrapper>

      <ContainerContent>
        <Tit>กรุณาเลือกข้อมูลเพื่อดาวน์โหลดเป็นไฟล์ .CSV</Tit>

        <Box
          sx={{
            margin: '24px 0px',
            color: '#CCDDD9'
          }}
        >
          <AppInputLabel>ประเภทข้อมูล</AppInputLabel>

          <SelectSimple
            width={428}
            options={[
              { value: 'WasteHistory', name: 'รายการส่งขยะ' },
              { value: 'ProductHistory', name: 'รายการแลกของ' },
              { value: 'User', name: 'รายงานข้อมูลผู้ใช้' }
            ]}
            handleSelect={(value: any) => {
              handleChange(value), setStatus(0)
            }}
            type="value"
            placeholder="เลือกประเภทข้อมูล"
            value={typeDownload}
          />
        </Box>

        <Box
          sx={{
            margin: '24px 0px'
          }}
        >
          <AppInputLabel>ช่วงเวลา</AppInputLabel>
          <ContainerInput>
            <InputGroup isAfter={isAfter}>
              <Second>
                <Picker>
                  <Pickerday>
                    <DatePickerDay
                      selected={startDate}
                      onChange={(date: any) => {
                        setStartDate(date), setStatus(0)
                      }}
                      placeholderText="เริ่มวันที่"
                      maxDate={moment().toDate()}
                    />
                    <img src="/images/calendar.png" className="icon-calendar" />
                  </Pickerday>
                </Picker>

                <Line />

                <Picker>
                  <Pickerday>
                    <DatePickerDay
                      selected={endDate}
                      onChange={(date: any) => {
                        setEndDate(date), setStatus(0)
                      }}
                      placeholderText="เลือกวันที่"
                      maxDate={moment().toDate()}
                    />
                    <img src="/images/calendar.png" className="icon-calendar" />
                  </Pickerday>
                </Picker>
              </Second>
            </InputGroup>
          </ContainerInput>
          {!isAfter && <Pagram>วันที่สิ้นสุดไม่ถูกต้อง</Pagram>}
        </Box>
        <ButtonGroup>
          {isNoti ? (
            <MesLoading>
              อดใจรอสักครู่ ระบบกำลังประมวลผล...{' '}
              <Strong onClick={() => handleCancel()}>ยกเลิก</Strong>
            </MesLoading>
          ) : (
            <ButtonDownload
              isDisable={!typeDownload || !startDate || !endDate || !isAfter}
              onClick={() => {
                getApi(startDate, endDate, typeDownload), setStatus(0)
              }}
            >
              <img src={DownloadIcon} alt="download-icon" />
              ดาวน์โหลดไฟล์
            </ButtonDownload>
          )}
        </ButtonGroup>
        {handleStatus(status)}
      </ContainerContent>
    </Layout>
  )
}

export default Activity

const ContainerContent = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 24px;
`

const Tit = styled.div`
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  color: #000000;
  margin-bottom: 24px;
`

const ButtonGroup = styled.div``

const ContainerInput = styled.div``

const InputGroup = styled.div`
  width: 428px;
  border: ${({ isAfter }) => (isAfter ? '1px solid #ccddd9' : '1px solid red')};
  border-radius: 4px;
  display: flex;
`

const ButtonDownload = styled.button`
  padding: 8px 16px 8px 12px;
  background: ${({ isDisable }) => (isDisable ? '#DADADA' : '#00a796')};
  border-radius: 4px;
  color: ${({ isDisable }) => (isDisable ? '#444444' : '#ffffff')};
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  img {
    margin-right: 6px;
  }
  pointer-events: ${({ isDisable }) => isDisable && 'none'};
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`

const Second = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
`

const DatePickerDay = styled(DatePicker)`
  width: 134px;
  border: none !important;
`

const Pickerday = styled.label`
  position: relative;

  .icon-calendar {
    position: absolute;
    z-index: 2;
    right: -54px;
    top: -1px;
  }
`

const Line = styled.div`
  height: 24px;
  width: 1px;
  background: #ccd1dd;
  margin-right: 16px;
  margin-left: 16px;
`

const Picker = styled.div`
  width: 50%;
`

const MesLoading = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #757575;
`

const Strong = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: #00a796;
  text-decoration: underline;
  cursor: pointer;
`
const Sucess = styled.div`
  img {
    margin-right: 6.7px;
  }
`

const Er = styled.div`
  img {
    margin-right: 6.7px;
  }
  font-weight: 400;
  font-size: 16px;
  color: #0f0f0f;
`

const Des = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  margin-left: 28px;
  margin-top: 8px;
`

const Pagram = styled.p`
  color: #fd4a1e;
  font-size: 12px;
`
