import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { KEY } from '../../constants/const'
import { isAllow } from '../../function/isAllow'

export const getSystemAdminPath = (path: string): string => {
  return `${path}`
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const accessToken = localStorage.getItem(KEY.ACCESS_TOKEN)
  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken && isAllow() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

export default PrivateRoute
