import * as React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AddNewProduct from '../features/ProductCatalog/AddNewProduct'
import { EditProduct } from '../features/ProductCatalog/EditProduct'
import { ProductDetail } from '../features/ProductCatalog/ProductDetail'
import { AddRequest } from '../features/WasteRequest/AddRequest'
import { DetailRequest } from '../features/WasteRequest/DetailRequest'
import { AccountVerifications } from '../page/AccountVerifications'
import ForgotPassword from '../page/ForgotPassword'
import Login from '../page/Login'
import { ProductCatalog } from '../page/ProductCatalog'
import RecoverPassword from '../page/RecoverPassword'
import { WasteRequest } from '../page/WasteRequest'
import PrivateRoute, { getSystemAdminPath } from './RouteConfig/PrivateRoute'
import {
  customerAccountEdit,
  PUBLIC_ROUTE,
  SYSTEM_ADMIN_ROUTE
} from './RouteDefinition'
import AccountManagement from '../page/AccountManagement'
import { RedeemOrderList } from '../page/RedeemOrderList'
import { AddRedeemRequest } from '../features/RedeemOrderList/AddRedeemRequest'
import { RecoverPasswordApp } from '../page/RecoverPasswordApp'
import { ResultChangePass } from '../page/RecoverPasswordApp/ResultChangePass'
import CreateAccount from '../features/AccountManager/CreateAccount'
import CustomerAccountDetail from '../features/AccountManager/AccountDetail'
import EditAccount from '../features/AccountManager/AccountEdit'
import { DetailRedeem } from '../features/RedeemOrderList/DetailRedeem'
import { AdminAccount } from '../page/AdminAccount'
import VerifyUser from '../page/UserVerify'
import NotFound from '../page/NotFound'
import Activity from '../page/Activity'

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={PUBLIC_ROUTE.LOGIN.path} component={Login} />
        <Route
          exact
          path={PUBLIC_ROUTE.FORGOT_PASSWORD.path}
          component={ForgotPassword}
        />
        <Route
          exact
          path={PUBLIC_ROUTE.RECOVER_PASSWORD.path}
          component={RecoverPassword}
        />

        <Route
          exact
          path={PUBLIC_ROUTE.ACCOUNT_VERIFY.path}
          component={AccountVerifications}
        />

        <Route
          exact
          path={PUBLIC_ROUTE.USER_VERIFY.path}
          component={VerifyUser}
        />

        <Route exact path={PUBLIC_ROUTE.NOT_FOUND.path} component={NotFound} />

        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.HOME.path)}
          component={WasteRequest}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.ACTIVITY.path)}
          component={Activity}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.PRODUCT_CATALOG.path)}
          component={ProductCatalog}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.ADD_NEW_PRODUCT.path)}
          component={AddNewProduct}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.PRODUCT_DETAIL_ITEM.path)}
          component={ProductDetail}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.PRODUCT_DETAIL_EDIT.path)}
          component={EditProduct}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.WASTE_REQUEST.path)}
          component={WasteRequest}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.ADD_NEW_REQUEST.path)}
          component={AddRequest}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(
            SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_CREATE.path
          )}
          component={CreateAccount}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(
            SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_ITEM.path
          )}
          component={CustomerAccountDetail}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(
            SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_MANAGER_EDIT.path
          )}
          component={EditAccount}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(
            SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_ITEM_WASTE_REQUEST.path
          )}
          component={DetailRequest}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(
            SYSTEM_ADMIN_ROUTE.WASTE_REQUEST_DETAIL.path
          )}
          component={DetailRequest}
        />
        <PrivateRoute
          exact
          path={getSystemAdminPath(
            SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_MANAGER.path
          )}
          component={AccountManagement}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.REDEEM_ORDER_LIST.path)}
          component={RedeemOrderList}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.ADD_REDEEM_ORDER.path)}
          component={AddRedeemRequest}
        />
        <Route
          exact
          path={PUBLIC_ROUTE.RECOVER_PASSWORD_APP.path}
          component={RecoverPasswordApp}
        />
        <Route
          exact
          path={PUBLIC_ROUTE.RECOVER_PASSWORD_STATUS.path}
          component={ResultChangePass}
        />

        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.REDEEM_DETAIL.path)}
          component={DetailRedeem}
        />
        <PrivateRoute
          exact
          path={getSystemAdminPath(SYSTEM_ADMIN_ROUTE.ADMIN_ACCOUNT.path)}
          component={AdminAccount}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default AppRouter
