import { InputLabel, InputLabelProps, Theme } from '@mui/material'
import React from 'react'

const AppInputLabel = ({ sx, children, ...props }: InputLabelProps) => {
  return (
    <InputLabel
      sx={{
        color: (theme: Theme) => theme.color.blackStone,
        fontSize: '1.2rem',
        fontWeight: '400',
        ...sx
      }}
      {...props}
    >
      {children}
    </InputLabel>
  )
}

export default AppInputLabel
