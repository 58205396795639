import React, { useState, useEffect } from 'react'
import Layout from '../../layouts/DefaultLayout'
import Typography from '@mui/material/Typography'
import { AppButtonStyle } from '../../features/ProductCatalog/ProductDetail'
import {
  Title,
  Wrapper
} from '../../features/ProductCatalog/ProductList/ProductInfo'
import addIcon from '../../asset/images/Vector1.svg'
import { SearchBlock, TableCell } from '../WasteRequest'
import searchIcon from '../../asset/images/Vector2.svg'
import {
  InputSearch,
  ImageSearch
} from '../../features/ProductCatalog/ProductList/SearchFilter'
import RedeemFillter from '../../features/RedeemOrderList/RedeemFilter'
import DisplayResult from '../../features/ProductCatalog/ProductList/DisplayResult'
import ProductEmpty from '../../features/ProductCatalog/ProductList/ProductEmpty'
import PaginationSquare from '../../component/PaginationSquare'
import { PAGE_SIZE, TableCellStyle } from '../ProductCatalog'
import TableBlock from '../../component/TableBlock'
import THead from '../../component/THead'
import { TableBody } from '@mui/material'
import sortImg from '../../asset/images/Vector3.svg'
import RedeemItemList from '../../features/RedeemOrderList/RedeemItemList'
import Box from '@mui/material/Box'
import TextTitle from '../../features/ProductCatalog/ProductList/TextTitle'
import starImage from '../../asset/images/star.svg'
import { SYSTEM_ADMIN_ROUTE } from '../../route/RouteDefinition'
import { getSystemAdminPath } from '../../route/RouteConfig/PrivateRoute'
import { useHistory } from 'react-router-dom'
import { getRedeemOrder } from '../../api'
import { redeemDetailLink } from '../../route/RouteDefinition'
import useDebounce from '../../hook/useDebounce'

export const RedeemOrderList = () => {
  const [total, setTotal] = useState(0)
  const [dataSearch, setDataSearch] = useState<any>({
    start: 1,
    number: PAGE_SIZE
  })
  const [dataList, setDataList] = useState<any>([])
  const [dataAdd, setDataAdd] = useState('')
  const route = useHistory()
  const debouncedSearch = useDebounce(dataSearch, 300)

  const getRedeemOrderList = async () => {
    const res = await getRedeemOrder(debouncedSearch)
    if (res) {
      setDataList(res.data)
      setTotal(res.total)
    }
  }

  useEffect(() => {
    getRedeemOrderList()
  }, [debouncedSearch])

  return (
    <>
      <Layout>
        <Wrapper>
          <Typography
            sx={{
              fontSize: '3.2rem',
              fontWeight: 'bold'
            }}
          >
            จัดการออเดอร์แลกของ
          </Typography>

          <AppButtonStyle
            sx={{ width: '269px', mt: '32px' }}
            onClick={() =>
              route.push(
                getSystemAdminPath(SYSTEM_ADMIN_ROUTE.ADD_REDEEM_ORDER.path)
              )
            }
          >
            <img src={addIcon} />
            <Title>สร้างออเดอร์แลกของ</Title>
          </AppButtonStyle>
        </Wrapper>

        <SearchBlock>
          <Wrapper>
            <InputSearch
              placeholder="ค้นหาด้วย ชื่อ, อีเมล, เบอร์โทรศัพท์, เลขออเดอร์"
              onChange={(e) =>
                setDataSearch({
                  ...dataSearch,
                  keyword: e.target.value,
                  start: 1
                })
              }
            />
            <ImageSearch src={searchIcon} />
          </Wrapper>
        </SearchBlock>

        <RedeemFillter dataSearch={dataSearch} setDataSearch={setDataSearch} />

        {dataList && dataList.length != 0 ? (
          <>
            <Wrapper>
              <DisplayResult total={total} />
              <PaginationSquare
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
                totalPage={Math.ceil(total / PAGE_SIZE)}
              />
            </Wrapper>

            <TableBlock>
              <THead>
                <TableCell
                  onClick={() =>
                    setDataSearch({
                      ...dataSearch,
                      optionSort: 'id',
                      typeSort: !dataSearch.typeSort
                        ? 'ASC'
                        : dataSearch.typeSort === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                    })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  เลขที่
                  <img src={sortImg} style={{ marginLeft: '7px' }} />
                </TableCell>
                <TableCellStyle>รูปสินค้า</TableCellStyle>
                <TableCellStyle>ข้อมูลสินค้า</TableCellStyle>
                <TableCellStyle>วันที่สั่ง</TableCellStyle>
                <TableCellStyle>ผู้ใช้</TableCellStyle>
                <TableCellStyle>สถานะ</TableCellStyle>
                <TableCellStyle></TableCellStyle>
              </THead>
              <TableBody>
                {dataList.map((item, index) => {
                  return (
                    <RedeemItemList
                      key={index}
                      item={item}
                      getRedeemOrderList={getRedeemOrderList}
                      handleSelect={() => {
                        route.push({
                          pathname: redeemDetailLink(item.id),
                          state: {
                            productItem: item,
                            id: item.id
                          }
                        })
                      }}
                    />
                  )
                })}
              </TableBody>
            </TableBlock>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '8px'
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <img
                  src={starImage}
                  width={9}
                  height={24}
                  style={{ marginRight: '9px' }}
                />
                <TextTitle />
              </Box>
              <PaginationSquare
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
                totalPage={Math.ceil(total / PAGE_SIZE)}
              />
            </Box>
          </>
        ) : (
          <>
            <DisplayResult total={total} />
            <ProductEmpty type="redeem" />
          </>
        )}
      </Layout>
    </>
  )
}
