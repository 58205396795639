import { createSlice } from '@reduxjs/toolkit'

const loadingSlice = createSlice({
  name: 'globalLoaing',
  initialState: {
    loading: false
  },
  reducers: {
    globalLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

const { reducer, actions } = loadingSlice
export const { globalLoading } = actions
export default reducer
