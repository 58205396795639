import styled from 'styled-components'

export const WrapperDetail = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  height: fit-content;
`

export const HeaderDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 3fr;
  align-items: center;

  .order-id {
    font-weight: 700;
    font-size: 20px;
    color: #2c2c2c;
    width: 140px;
  }

  .id {
    font-weight: 400;
    font-size: 20px;
    color: #2c2c2c;
  }

  .status {
    text-align: center;
    border-radius: 8px;
    padding: 4px;
    width: 160px;
    font-weight: 700;
    font-size: 14px;
  }

  .status-redeem {
    text-align: center;
    border-radius: 8px;
    padding: 4px;
    width: 160px;
    font-weight: 700;
    font-size: 14px;
  }

  .pending {
    background: #ffe5b8;
    color: #a15b00;
  }

  .preparing {
    background: #ddeeff;
    color: #0059b3;
  }

  .sent {
    background: #f7dcde;
    color: #e5141f;
  }
  .completed {
    background: #e1f9e9;
    color: #167335;
  }
  .cancelled {
    background: #dedede;
    color: #2c2c2c;
  }
  .cancelledbyuser {
    background: #dedede;
    color: #2c2c2c;
  }

  .date-time {
    font-weight: 400;
    font-size: 12px;
    color: #6b7c78;
    text-align: right;
  }
`

export const LineBold = styled.div`
  border-top: 1px solid #6b7c78;
  margin-top: 16px;
  margin-bottom: 24px;
`

export const TitleField = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #6b7c78;
  margin-top: 24px;
`

export const ContentField = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #2c2c2c;

  img {
    width: 20px;
    margin-right: 8px;
    margin-bottom: 5px;
  }
`

export const ContentFieldBrand = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #2c2c2c;
  width: 400px;
  word-wrap: break-word;

  img {
    width: 20px;
    margin-right: 8px;
    margin-bottom: 5px;
  }
`

export const TextNormal = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #b9bac2;
`

export const NoInput = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #b9bac2;
`

export const LineThin = styled.div`
  border-top: 1px solid #ccddd9;
  margin: 24px 0;
`

export const NoImg = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #b9bac2;
  margin: 24px 0px 0px 24px;
`

export const CancelOrder = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #444444;
  text-decoration-line: underline;
  text-align: right;
  cursor: pointer;
`

export const Status = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin: 26px 0px;
  .status {
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
  .redeem-pending {
    background: #a15b00;
  }

  .redeem-cancelled {
    background: #dedede;
  }

  .redeem-preparing {
    background: #0436ea;
  }

  .redeem-sent {
    background: #e5141f;
  }

  .redeem-completed {
    background: #167335;
  }
`

export const UserHeader = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: flex-end;
  gap: 8px;
  img {
    width: 56px;
    height: 56px;
    border-radius: 4px;
  }
`

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: #00a796;
  border-radius: 50%;
  margin-bottom: 8px;
`

export const PointAdded = styled.div`
  background: #eff1f4;
  border-radius: 8px;
  padding: 12px 24px;
  width: 300px;
  margin-top: 24px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item {
    font-weight: 400;
    font-size: 12px;
    color: #6b7c78;
    margin-top: 4px;
  }

  img {
    cursor: pointer;
  }
`

export const ContentTop = styled.div`
  margin: 0 64px 32px;
`

export const ContentBottom = styled.div`
  margin: 0 28px 16px 64px;
  display: flex;
  gap: 16px;
  align-items: flex-end;

  .earn-point {
    font-weight: 400;
    font-size: 16px;
    color: #2c2c2c;
    margin-bottom: 8px;
  }

  .number-point {
    font-weight: 700;
    font-size: 24px;
    color: #00a796;
    margin-bottom: 8px;
  }

  .unit-point {
    font-weight: 400;
    font-size: 16px;
    color: #6b7c78;
    margin-bottom: 8px;
  }
`

export const Column = styled.div`
  border-left: 1px solid #ccddd9;
  height: 68px;
`

export const MessageBlock = styled.div`
  margin-left: 24px;
`

export const InfoMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #6b7c78;
  margin-top: 12px;
`

export const WrapperImg = styled.div`
  width: 140px;
  height: 140px;

  img {
    width: 100%;
  }
`
export const StatusRedeem = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin: 26px 0px;
  .status {
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
  .redeem-pending {
    background: #a15b00;
  }

  .redeem-cancelled {
    background: #dedede;
  }

  .redeem-preparing {
    background: #0436ea;
  }

  .redeem-sent {
    background: #e5141f;
  }

  .redeem-completed {
    background: #167335;
  }
`
