import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Panel from '../../../component/Panel'
import Box from '@mui/material/Box'
import AppInputLabel from '../../../component/common/AppInputLabel'
import AppInput from '../../../component/common/AppInput'
import SelectSimple from '../../../component/SelectSimple'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/_store'
import { useAppMaster } from '../../../store/_hook'
import { getDistrict, getWard } from '../../../api'
import { Required } from '../../ProductCatalog/AddNewProduct/ProductInfo'
import { alphaOnly } from '../../../function/alpha'

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #00a796;
  margin-bottom: 24px;
`
const Info = styled.div`
  display: flex;
  gap: 80px;
  margin-bottom: 40px;
`

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  div {
    display: flex;
    .container {
      display: block;
      position: relative;
      padding-left: 27px;
      margin-left: 27px;
      cursor: pointer;
      font-size: 14px;
      & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      & input:checked ~ .checkmark {
        background-color: #00a796;
      }

      & .checkmark:after {
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
      & input:checked ~ .checkmark:after {
        display: block;
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #dadada;
      border-radius: 50%;
      margin-top: 3px;
      &:after {
        content: '';
        position: absolute;
        display: none;
      }
    }
  }
  img {
    cursor: pointer;
  }
`

const Button = styled.button`
  width: 100%;
  height: 32px;
  background: #00a796;
  color: #ffffff;
  border-radius: 4px;
  text-align: center;
  img {
    margin-right: 9px;
  }
`

const Notice = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #6b7c78;
`

const Line = styled.div`
  border-bottom: 1px solid #ccddd9;
  margin-bottom: 24px;
`

type Props = {
  itemProcess: any
  indexItem: any
  handleUpdate: any
  isShow?: boolean
  handleDelete: any
  type?: StringConstructor
  handleChangeDefault?: any
  isCheck?: boolean
}

const AddressForm = ({
  itemProcess,
  indexItem,
  handleUpdate,
  isShow,
  handleDelete,
  type,
  handleChangeDefault,
  isCheck
}: Props): JSX.Element => {
  const [itemToAdd, setItemToAdd] = useState(itemProcess)
  const [districtList, setdistrictList] = useState([])
  const [wardList, setWardList] = useState([])

  const {
    state: { configMaster }
  } = useAppMaster()

  const { dataProvince } = useSelector((state: RootState) => state.selectSlice)

  const handleGetDistrict = async (idProvince: any) => {
    const res = await getDistrict({ parentId: idProvince })
    if (!res.error) {
      setdistrictList(res.data)
    }
  }

  const handleGetWard = async (idDistrict: any) => {
    const res = await getWard({ parentId: idDistrict })
    if (!res.error) {
      setWardList(res.data)
    }
  }

  const handleValidatePhone = (e: any) => {
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      e.target.value.length <= 10 &&
        setItemToAdd({
          ...itemToAdd,
          phone: e.target.value
        })
    }
  }

  const handleGetPostCode = (id: any) => {
    if (wardList) {
      let obj = wardList.find((item) => item.id == id)
      return obj ? obj.postcode : ''
    }
    return
  }

  useEffect(() => {
    handleUpdate(indexItem, itemToAdd)
  }, [itemToAdd])

  useEffect(() => {
    handleGetDistrict(itemToAdd.province.id)
  }, [])

  useEffect(() => {
    handleGetWard(itemToAdd.district.id)
  }, [])

  useEffect(() => {
    setItemToAdd(itemProcess)
  }, [itemProcess])

  return (
    <>
      {indexItem != 0 && <Line />}
      <BlockTitle>
        <div>
          {indexItem == 0 ? (
            <Title> ข้อมูลที่อยู่จัดส่งหลัก (ไม่จำเป็นต้องระบุ) </Title>
          ) : (
            <Title>
              {' '}
              ข้อมูลที่อยู่จัดส่ง{indexItem + 1} (ไม่จำเป็นต้องระบุ){' '}
            </Title>
          )}
          {isShow && (
            <>
              <label className="container">
                ตั้งเป็นที่อยู่จัดส่งหลัก
                <input
                  type="radio"
                  className="radio"
                  name="radio"
                  value={'true'}
                  defaultChecked={itemToAdd.isDefault}
                  onChange={(e) => {
                    setItemToAdd({
                      ...itemToAdd,
                      isDefault: e.target.value
                    }),
                      handleChangeDefault(indexItem)
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </>
          )}
        </div>
        {isShow && (
          <img
            src="/icons/recycle.svg"
            alt=""
            onClick={() => handleDelete(indexItem)}
          />
        )}
      </BlockTitle>

      <Info>
        <Box width={400}>
          <AppInputLabel
            sx={{
              marginBottom: '4px'
            }}
          >
            ชื่อ (ที่จำเป็น)
          </AppInputLabel>
          <AppInput
            fullWidth
            value={itemToAdd.firstname}
            errors={isCheck && itemToAdd.firstname.trim() === ''}
            placeholder="ใส่ชื่อ"
            onKeyDown={(e) => alphaOnly(e)}
            onChange={(e) =>
              setItemToAdd({
                ...itemToAdd,
                firstname: e.target.value
              })
            }
          />
          {isCheck && itemToAdd.firstname.trim() === '' && (
            <Required>จำเป็นต้องระบุ</Required>
          )}
        </Box>
        <Box width={400}>
          <AppInputLabel>นามสกุล (ที่จำเป็น)</AppInputLabel>
          <AppInput
            fullWidth
            value={itemToAdd.lastname}
            errors={isCheck && itemToAdd.lastname.trim() === ''}
            placeholder="ใส่นามสกุล"
            onKeyDown={(e) => alphaOnly(e)}
            onChange={(e) =>
              setItemToAdd({
                ...itemToAdd,
                lastname: e.target.value
              })
            }
          />
          <AppInputLabel
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#6B7C78',
              paddingTop: '4px'
            }}
          >
            {isCheck && itemToAdd.lastname.trim() === '' && (
              <Required>จำเป็นต้องระบุ</Required>
            )}
          </AppInputLabel>
        </Box>
      </Info>

      <Box
        sx={{
          width: '400px',
          marginBottom: '40px'
        }}
      >
        <AppInputLabel
          sx={{
            marginBottom: '4px'
          }}
        >
          เบอร์โทรศัพท์ (ที่จำเป็น)
        </AppInputLabel>
        <AppInput
          fullWidth
          value={itemToAdd.phone}
          errors={
            (isCheck && itemToAdd.phone.trim() === '') ||
            (itemToAdd.phone.trim() !== '' && itemToAdd.phone.length != 10)
          }
          placeholder="ใส่เบอร์โทรศัพท์"
          onChange={(e) => handleValidatePhone(e)}
        />
        {isCheck && itemToAdd.phone.trim() === '' && (
          <Required>จำเป็นต้องระบุ</Required>
        )}
        {itemToAdd.phone.trim() !== '' && itemToAdd.phone.length != 10 && (
          <Required>โทรศัพท์ไม่ถูกต้อง</Required>
        )}
      </Box>

      <Info>
        <Box width={400}>
          <AppInputLabel
            sx={{
              marginBottom: '4px'
            }}
          >
            ที่อยู่ (บ้านเลขที่, ซอย, ถนน) (ที่จำเป็น)
          </AppInputLabel>
          <AppInput
            fullWidth
            value={itemToAdd.address}
            errors={isCheck && itemToAdd.address.trim() === ''}
            placeholder="ใส่ที่อยู่"
            onChange={(e) =>
              setItemToAdd({
                ...itemToAdd,
                address: e.target.value
              })
            }
          />
          {isCheck && itemToAdd.address.trim() === '' && (
            <Required>จำเป็นต้องระบุ</Required>
          )}
        </Box>
        <Box width={400}>
          <AppInputLabel
            sx={{
              marginBottom: '4px'
            }}
          >
            จังหวัด (ที่จำเป็น){' '}
          </AppInputLabel>
          <SelectSimple
            width={400}
            options={Object.values(dataProvince)}
            type="id"
            placeholder="เลือกจังหวัด"
            value={itemToAdd.province.name || itemToAdd.province || ''}
            error={isCheck && itemToAdd.province.toString().trim() === ''}
            handleSelect={(value) => {
              handleGetDistrict(value)
              setWardList([])
              setItemToAdd({
                ...itemToAdd,
                province: value,
                district: '',
                ward: '',
                postcode: ''
              })
            }}
          />
          {isCheck && itemToAdd.province.toString().trim() === '' && (
            <Required>จำเป็นต้องระบุ</Required>
          )}
        </Box>
      </Info>
      <Info>
        <Box width={400}>
          <AppInputLabel
            sx={{
              marginBottom: '4px'
            }}
          >
            เขต/อำเภอ (ที่จำเป็น)
          </AppInputLabel>
          <SelectSimple
            width={400}
            options={Object.values(districtList)}
            disabled={districtList.length == 0}
            type="id"
            placeholder="เลือก เขต/อำเภอ"
            error={isCheck && itemToAdd.district.toString().trim() === ''}
            value={itemToAdd.district.name || itemToAdd.district || ''}
            handleSelect={(value) => {
              handleGetWard(value),
                setItemToAdd({
                  ...itemToAdd,
                  district: value,
                  ward: '',
                  postcode: ''
                })
            }}
          />
          {isCheck && itemToAdd.district.toString().trim() === '' && (
            <Required>จำเป็นต้องระบุ</Required>
          )}
        </Box>
        <Box width={400}>
          <AppInputLabel
            sx={{
              marginBottom: '4px'
            }}
          >
            แขวง/ตำบล (ที่จำเป็น)
          </AppInputLabel>
          <SelectSimple
            width={400}
            options={Object.values(wardList)}
            disabled={wardList.length == 0}
            type="id"
            placeholder="เลือก แขวง/ตำบล"
            error={isCheck && itemToAdd.ward.toString().trim() === ''}
            value={itemToAdd.ward.name || itemToAdd.ward || ''}
            handleSelect={(value) => {
              setItemToAdd({
                ...itemToAdd,
                ward: value,
                postcode: handleGetPostCode(value)
              })
            }}
          />
          {isCheck && itemToAdd.ward.toString().trim() === '' && (
            <Required>จำเป็นต้องระบุ</Required>
          )}
        </Box>
      </Info>
      <Box
        sx={{
          width: '400px',
          marginBottom: '40px'
        }}
      >
        <AppInputLabel
          sx={{
            marginBottom: '4px'
          }}
        >
          รหัสไปรษณีย์ (ที่จำเป็น)
        </AppInputLabel>
        <SelectSimple
          width={400}
          options={Object.values(configMaster.postCode)}
          type="value"
          placeholder="เลือกรหัสไปรษณีย์"
          error={isCheck && itemToAdd.postcode.toString().trim() === ''}
          value={(type ? itemToAdd.postcode.name : itemToAdd.postcode) || ''}
          handleSelect={(value) => {
            setItemToAdd({
              ...itemToAdd,
              postcode: value
            })
          }}
        />
        {isCheck && itemToAdd.postcode.toString().trim() === '' && (
          <Required>จำเป็นต้องระบุ</Required>
        )}
      </Box>
    </>
  )
}

const EditAddress = ({
  handleUpdate,
  handleDelete,
  handlePush,
  addressExtend,
  handleChangeDefault,
  handlePushDefault,
  isCheck
}) => {
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    addressExtend.length == 1 ? setIsShow(false) : setIsShow(true)
  }, [addressExtend])

  useEffect(() => {
    if (addressExtend.length == 0) {
      handlePushDefault()
    }
  }, [])

  return (
    <>
      <Panel title={'หัวข้อ ( 2/2)  : ข้อมูลที่อยู่'}>
        {addressExtend.map((item, index) => (
          <AddressForm
            itemProcess={item}
            indexItem={index}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            isShow={isShow}
            key={index}
            handleChangeDefault={handleChangeDefault}
            isCheck={isCheck}
          />
        ))}
        {addressExtend.length == 5 ? (
          <Notice>เพิ่มที่อยู่ได้สูงสุด 5 รายการ</Notice>
        ) : (
          <Button onClick={handlePush}>
            <img src="/icons/plus-icon.svg" alt="" />
            <span>เพิ่มที่อยู่</span>
          </Button>
        )}
      </Panel>
    </>
  )
}

export default EditAddress
