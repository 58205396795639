import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Message, Title, WrapperMobile } from '../AccountVerifications'

export const ResultChangePass = () => {
  const location = useLocation()

  const status = location.state
  return (
    <>
      <WrapperMobile>
        {status ? (
          <img src="/images/verify-success.png" />
        ) : (
          <img src="/images/verify-fail.png" />
        )}
        <Title>{status ? 'เปลี่ยนรหัสผ่านสำเร็จ' : 'ลิงก์ไม่ถูกต้อง'}</Title>
        <Message>
          {status
            ? 'คุณสามารถลงชื่อเข้าใช้งาน SWAP Recycling แอปพลิเคชั่น ด้วยรหัสผ่านใหม่ของคุณ'
            : 'ลิงก์การเปลี่ยนรหัสผ่านด้หมดอายุหรือถูกใช้แล้ว'}
        </Message>
      </WrapperMobile>
    </>
  )
}
