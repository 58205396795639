import { height } from '@mui/system'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  Contact,
  HeaderInfo,
  UserName,
  InfoUser
} from '../../WasteRequest/AddRequest/AddRequestInfo'

import { ImgProduct, DetailProduct, ImgProductDetail } from './AddProductInfo'

export const InfoProduct = ({
  productDetail,
  setProductDetail,
  setProductType,
  setProductId
}) => {
  useEffect(() => {
    setProductType(
      productDetail.productType ? productDetail.productType.value : ''
    )
  }, [])

  return (
    <>
      <InfoUser>
        <HeaderInfo>
          <div>ข้อมูลสินค้า</div>
          <img
            src="/images/icon-close.png"
            onClick={() => {
              setProductDetail({}), setProductId(null)
            }}
          />
        </HeaderInfo>
        <Contact
          style={{
            gap: '20px',
            marginLeft: '0px',
            marginTop: '10px',
            height: '80px'
          }}
        >
          <ImgProductDetail>
            <img
              src={
                productDetail.logo
                  ? productDetail.logo
                    ? productDetail.logo
                    : '/images/product-example.png'
                  : '/images/product-example.png'
              }
            />
          </ImgProductDetail>

          <DetailProduct>
            <div className="bold-text">{productDetail.name || ''}</div>
            <div className="primary-text">{productDetail.point || 0} คะแนน</div>
            <div className="thin-text">
              {productDetail.brandId ? productDetail.brandId.name : ''}{' '}
              <span>|</span>{' '}
              {productDetail.productType ? productDetail.productType.name : ''}
            </div>
          </DetailProduct>
        </Contact>
      </InfoUser>
    </>
  )
}
