import { Box, Grid, Theme, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { login } from '../../api'
import image from '../../asset/images/logo.png'
import { AppButton } from '../../component/common/AppButton'
import AppInput from '../../component/common/AppInput'
import AppInputLabel from '../../component/common/AppInputLabel'
import { KEY } from '../../constants/const'
import { encryptAuth } from '../../function/ecrypt'
import { isAllow } from '../../function/isAllow'
import { PUBLIC_ROUTE, SYSTEM_ADMIN_ROUTE } from '../../route/RouteDefinition'
import { _login } from '../../store/authSlice'
import { useAppMaster } from '../../store/_hook'

export const BoxStyle = styled(Box)`
  position: relative;
`
export const InputBlock = styled.div`
  background: #ffffff;
  border: 1px solid ${({ errors }) => (errors ? '#FD4A1E' : '#ccddd9')};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;

  img {
    padding: 15px;
  }

  .type {
    padding: 13px 24px 13px 8px;
    color: #6b7c78;
    font-size: 16px;
  }
`

const Login = () => {
  const {
    state: { configMaster }
  } = useAppMaster()

  const dispatch = useDispatch()

  const route = useHistory()
  const [isError, setIsError] = useState(false)
  const [inputEmail, setInputEmail] = useState('')
  const [inputPassword, setInputPassword] = useState('')

  const onSubmit = async () => {
    localStorage.removeItem(KEY.ACCESS_TOKEN)
    localStorage.removeItem('expiredAtUTC')
    localStorage.removeItem('refreshToken')

    const email = encryptAuth(
      inputEmail,
      configMaster.encryptUser,
      configMaster.characterSeparate
    )
    const password = encryptAuth(
      inputPassword,
      configMaster.encryptPass,
      configMaster.characterSeparate
    )

    try {
      const res = await login({
        email,
        password
      })

      if (res.token) {
        localStorage.setItem(KEY.ACCESS_TOKEN, res.token.token)
        localStorage.setItem('expiredAtUTC', res.token.expiredAt)
        localStorage.setItem('refreshToken', res.refreshToken.token)

        route.push(SYSTEM_ADMIN_ROUTE.WASTE_REQUEST.path)
        dispatch(_login(res.token.token))
      } else {
        setIsError(true)
      }
    } catch (error) {
      setIsError(true)
    }
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        sx={{
          backgroundColor: (theme: Theme) => theme.color.gray1
        }}
      >
        <Box sx={{ maxWidth: '375px' }}>
          <img src={image} />
          <Typography
            sx={{
              color: (theme: Theme) =>
                isError ? theme.color.red : theme.color.black,
              fontSize: '28px',
              textAlign: 'center',
              fontWeight: 400,
              margin: '40px 23px 8px'
            }}
          >
            {isError
              ? 'อีเมลหรือรหัสผ่านไม่ถูกต้อง'
              : 'เข้าสู่ระบบสำหรับพนักงาน'}
          </Typography>
          <form>
            <BoxStyle>
              <AppInputLabel htmlFor="email">อีเมล</AppInputLabel>
              <InputBlock>
                <AppInput
                  fullWidth
                  type="text"
                  placeholder="กรุณาใส่อีเมล"
                  value={inputEmail}
                  onChange={(e) => setInputEmail(e.target.value)}
                  sx={{
                    border: 'none',
                    background: 'transparent'
                  }}
                />
                {inputEmail !== '' ? (
                  <img
                    src="/icons/iconX.png"
                    onClick={() => {
                      setInputEmail('')
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <img src="/icons/email.png" />
                )}
              </InputBlock>
            </BoxStyle>
            <Box sx={{ mt: '22px' }}>
              <AppInputLabel htmlFor="password">รหัสผ่าน</AppInputLabel>
              <InputBlock>
                <AppInput
                  fullWidth
                  type="password"
                  placeholder="กรุณาใส่รหัส"
                  value={inputPassword}
                  onChange={(e) => setInputPassword(e.target.value)}
                  sx={{
                    border: 'none',
                    background: 'transparent'
                  }}
                />
                {inputPassword !== '' ? (
                  <img
                    src="/icons/iconX.png"
                    onClick={() => {
                      setInputPassword('')
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <img src="/icons/password.png" />
                )}
              </InputBlock>
            </Box>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 700,
                color: (theme: Theme) => theme.color.gray7,
                textDecorationLine: 'underline',
                textAlign: 'center',
                mt: '8px',
                cursor: 'pointer'
              }}
              onClick={() => route.push(PUBLIC_ROUTE.FORGOT_PASSWORD.path)}
            >
              ลืมรหัส
            </Typography>
            <Box textAlign="center">
              <AppButton sx={{ width: '200px', mt: '32px' }} onClick={onSubmit}>
                เข้าสู่ระบบ
              </AppButton>
            </Box>
          </form>
        </Box>
      </Grid>
    </>
  )
}

export default Login
