import { createSlice } from '@reduxjs/toolkit'

const selectSlice = createSlice({
  name: 'catagoryList',
  initialState: {
    dataCatagory: [],
    dataBrand: [],
    dataProvince: []
  },
  reducers: {
    catagoryList: (state, action) => {
      state.dataCatagory = { ...action.payload }
    },
    brandList: (state, action) => {
      state.dataBrand = { ...action.payload }
    },
    provinceList: (state, action) => {
      state.dataProvince = { ...action.payload }
    }
  }
})

const { reducer, actions } = selectSlice
export const { catagoryList, brandList, provinceList } = actions
export default reducer
