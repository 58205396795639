import React from 'react'
import styled from 'styled-components'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Box from '@mui/material/Box'
import AppInputLabel from '../../../../component/common/AppInputLabel'
import { Required } from '../ProductInfo'

type Props = {
  title: string
  placeholder: string
  maxLength?: number
  minRow: number
  handleChange?: any
  value?: any
  waste?: boolean
  isTerm?: boolean
  error?: boolean
  isMarginTop?: boolean
}

const TextareaAutosizeStyle = styled(TextareaAutosize)`
  padding: 16px;
  border: 1px solid ${({ error }) => (error ? '#fd4a1e' : '#ccddd9')};
  border-radius: 4px;
  color: #2c2c2c;
  resize: none;
`

export const AreaFieldAdd = ({
  title,
  placeholder,
  maxLength,
  minRow,
  handleChange,
  value,
  waste,
  isTerm,
  error,
  isMarginTop
}: Props) => {
  return (
    <>
      <Box
        sx={{
          marginTop: isMarginTop && '40px',
          color: '#2C2C2C',
          width: waste ? '300px' : '746px',
          fontSize: '16px'
        }}
      >
        {!isTerm && <AppInputLabel>{title}</AppInputLabel>}
        <TextareaAutosizeStyle
          aria-label="minimum height"
          minRows={minRow}
          placeholder={placeholder}
          style={{ width: '100%' }}
          onChange={handleChange}
          value={value}
          maxLength={maxLength}
          error={error}
        />
        {maxLength && !error && (
          <AppInputLabel
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#6B7C78'
            }}
          >
            <div>{!waste ? `สูงสุด ${maxLength} ตัวอักษร` : ''}</div>
            <div>
              ({value ? value.length : 0}/{maxLength})
            </div>
          </AppInputLabel>
        )}
        {error && <Required>จำเป็นต้องระบุ</Required>}
      </Box>
    </>
  )
}
