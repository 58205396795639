import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { AppButton } from '../../../component/common/AppButton'
import { Button } from '../../../component/ModalDouleButton'
import { RadioItem } from './RadioItem'
import { useHistory } from 'react-router-dom'
import { SYSTEM_ADMIN_ROUTE } from '../../../route/RouteDefinition'
import {
  customerAccountDetail,
  customerAccountEdit
} from '../../../route/RouteDefinition'
import { getUserDetail } from '../../../api'

export const Line = styled.div`
  border-top: 1px solid #ccddd9;
  margin: 24px 0px;
  width: 100%;
`

export const ModalAddress = ({
  onDimiss,
  open,
  userIdSelected,
  addressSelected,
  setAddressSelected,
  userDetail
}) => {
  const history = useHistory()
  const [details, setDetails] = useState([])
  const [addressExtend, setaddressExtend] = useState([])
  const [addressId, setAddressId] = useState(null)

  const fetchDetail = async (id) => {
    let data = { id }
    try {
      const userDetail = await getUserDetail(data)
      if (!userDetail.error) {
        setDetails(userDetail.data)
        setaddressExtend(userDetail.data.addressExtend)
      }
    } catch (error) {}
  }

  const handlePushAddress = () => {
    setAddressSelected(addressId)
  }

  useEffect(() => {
    fetchDetail(userIdSelected)
  }, [userIdSelected])

  return (
    <>
      <Modal title="กรุณาเลือกที่อยู่จัดส่ง" visible={open} onCancel={onDimiss}>
        <FormControl sx={{ width: '100%' }}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            defaultValue={userDetail.addressId}
            onChange={(e) => setAddressId(e.target.value)}
          >
            {addressExtend.map((item, index) => {
              return (
                <>
                  <FormControlLabel
                    value={item.id}
                    control={<Radio />}
                    label={<RadioItem item={item} />}
                    key={index}
                  />
                  <Line />
                </>
              )
            })}
          </RadioGroup>
        </FormControl>

        <ButtonAdd
          onClick={() =>
            history.push({
              pathname: customerAccountEdit(userIdSelected),
              state: {
                id: userIdSelected,
                dataDetail: details
              }
            })
          }
        >
          + เพิ่มที่อยู่ใหม่
        </ButtonAdd>
        <div style={{ display: 'flex', gap: '8px' }}>
          <AppButton
            sx={{ width: '157px', mt: '32px', height: '32px' }}
            onClick={() => {
              handlePushAddress()
              onDimiss()
            }}
          >
            ยืนยัน
          </AppButton>

          <Button onClick={onDimiss}>กลับ</Button>
        </div>
      </Modal>
    </>
  )
}

const ButtonAdd = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #2c2c2c;
  text-align: center;
  width: 93%;
  border: 1px solid #ccddd9;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  width: 100%;
`
