import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { userVerify, checkExpiration } from '../../api'
import { PUBLIC_ROUTE } from '../../route/RouteDefinition'
import { remove } from '../../utils/theme'

const BlockSuccess = styled.div`
  position: fixed;
  top: 35%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .v-icon {
    display: flex;
    justify-content: center;
  }

  .description {
    text-align: center;
  }
`

const Title = styled.div`
  color: #0f0f0f;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  justify-content: center;
  margin: 25px 0px;
`

const VerifyUser = () => {
  const [isSuccess, setIsSuccess] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const route = useHistory()
  const url = window.location.search
  const params = new URLSearchParams(url)

  const checkExpirationToken = async () => {
    remove()
    const res = await checkExpiration({
      token: params.get('token'),
      status: params.get('status')
    })
    if (res.error) {
      if (res.error == 422) {
        setIsSuccess(false)
        setIsLoading(false)
      } else {
        route.push(PUBLIC_ROUTE.NOT_FOUND.path)
        setIsLoading(false)
      }
    } else {
      checkVerify()
    }
  }

  const checkVerify = async () => {
    const res = await userVerify({
      token: params.get('token')
    })
    if (res.error) {
      setIsSuccess(false)
      setIsLoading(false)
    } else {
      setIsSuccess(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    checkExpirationToken()
  }, [])
  return (
    <>
      {isLoading ? (
        <BlockSuccess>
          <Title>กำลังตรวจสอบ....</Title>
        </BlockSuccess>
      ) : (
        <BlockSuccess>
          <div className="v-icon">
            {isSuccess ? (
              <img src="/images/verify-success.png" />
            ) : (
              <img src="/images/verify-fail.png" />
            )}
          </div>
          <Title>{isSuccess ? "ยืนยันตัวตนสำเร็จ!" : "ลิงก์ไม่ถูกต้อง"}</Title>
          <div className="description">
            {isSuccess
              ? 'คุณสามารถลงชื่อเข้าใช้ SWAP recycling แอปพลิเคชั่น ด้วยอีเมลที่สมัครสมาชิก'
              : 'ลิงก์การยืนยันตัวตนได้หมดอายุหรือถูกใช้แล้ว กรุณาลงชื่อเข้าใช้ SWAP recycling แอปพลิเคชั่น ด้วยอีเมลที่สมัครสมาชิก เพื่อตรวจสอบการยืนยันตัวตนอีกครั้ง'}
          </div>
        </BlockSuccess>
      )}
    </>
  )
}

export default VerifyUser
