import React from 'react'
import { useHistory } from 'react-router-dom'
import { customerAccountDetail } from '../../../route/RouteDefinition'
import { Title } from '../../ProductCatalog/AddNewProduct/ProductImages/ImageItem'
import { AppButtonStyle } from '../../ProductCatalog/ProductDetail'
import {
  ContentField,
  Dot,
  TitleField,
  UserHeader,
  WrapperDetail
} from './DetailStyle'

export const UserDetail = ({ dataDetail }) => {
  const route = useHistory()
  const user = dataDetail.userId

  return (
    <>
      <WrapperDetail>
        <Title>ข้อมูลผู้ใช้</Title>

        <UserHeader>
          <img
            src={user.avatar ? user.avatar : 'avatar/images/avt-default.png'}
            alt="alt"
          />
          <div>
            <TitleField>คะแนนคงเหลือ</TitleField>
            <ContentField>{user.balance} คะแนน</ContentField>
          </div>
          {user.status === 'active' && <Dot />}
        </UserHeader>

        {/* Name*/}
        <TitleField>ชื่อ</TitleField>
        <ContentField>​{user.firstname + ' ' + user.lastname}</ContentField>

        {/* Email*/}
        <TitleField>อีเมล</TitleField>
        <ContentField>{user.email}</ContentField>

        {/* Phone*/}
        <TitleField>เบอร์โทร</TitleField>
        <ContentField>{user.phone}</ContentField>

        <div style={{ display: 'flex', gap: '80px' }}>
          <div>
            {/* Gender*/}
            <TitleField>เพศ</TitleField>
            <ContentField>{user.gender.name || '-'}</ContentField>
          </div>
          <div>
            {/* age*/}
            <TitleField>อายุ</TitleField>
            <ContentField>{user.age}</ContentField>
          </div>
        </div>

        {/* Location*/}
        <TitleField>จังหวัด</TitleField>
        <ContentField>
          {dataDetail
            ? dataDetail.provinceId
              ? dataDetail.provinceId.name
              : '-'
            : '-'}
        </ContentField>

        {/* Address*/}
        <TitleField>ที่อยู่</TitleField>
        <ContentField>{user.address || '-'}</ContentField>

        {/* Total waste weight*/}
        <TitleField>น้ำหนักขยะที่ส่งมาทั้งหมด</TitleField>
        <ContentField>{user.totalWeight} กิโลกรัม </ContentField>

        {/* go to user account */}
        <AppButtonStyle
          sx={{ width: '100%', mt: '32px' }}
          onClick={() =>
            route.push({
              pathname: customerAccountDetail(user.id),
              state: {
                id: user.id,
                name: user.firstname.toLowerCase()
              }
            })
          }
        >
          <img src="/images/user-icon.png" alt="usder" />
          ไปที่บัญชีผู้ใช้
        </AppButtonStyle>
      </WrapperDetail>
    </>
  )
}
