import React from 'react'
import { Modal } from 'antd'
import { AppButton } from '../common/AppButton'
import { Message, Title, WrapperMobile } from '../../page/AccountVerifications'

const ModalError = ({ content, open, onDimiss }) => (
  <Modal visible={open} onCancel={onDimiss}>
    <WrapperMobile>
      <img src="/images/verify-fail.png" />
      <Title>ไม่สามารถทำรายการได้!</Title>
      <Message>{content}</Message>
      <AppButton sx={{ width: '157px', mt: '16px' }} onClick={onDimiss}>
        ตกลง
      </AppButton>
    </WrapperMobile>
  </Modal>
)
export default ModalError
