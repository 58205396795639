import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { AppButton } from '../../../../component/common/AppButton'
import { customerAccountEdit } from '../../../../route/RouteDefinition'
import { Typography } from '@mui/material'
import { forceLogout } from '../../../../api'
import moment from 'moment'
import Tippy from '@tippyjs/react'
import ModalDoubleButton from '../../../../component/ModalDouleButton'
// import moment from 'moment-timezone'
import { toBuddhistYear } from '../../../../utils/theme'
import { convertToThai } from '../../../../utils/func'
import { TYPE_OF_DATE } from '../../../../constants/date'
import customToast from '../../../../component/Toast'

const Wrapper = styled.div`
  padding: 16px 24px;
  border-radius: 4px;
  background: #ffffff;
  margin-top: 31px;
`
const Profile = styled.div`
  display: grid;
  grid-template-columns: 0fr 0.7fr 0.7fr;
  column-gap: 6rem;
  .profile-detail {
    .profile-item {
      display: flex;
      color: #2c2c2c;
      .title {
        width: 150px !important;
        font-weight: 700;
        font-size: 16px;
      }
      .active {
        display: flex;
        align-items: center;
      }
      div:last-child {
        display: flex;
        justify-content: start;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
`
const BlockRight = styled.div``
const BlockLink = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    text-decoration: underline;
    color: #6b7c78;
    cursor: pointer;
  }
`
const ImgProfile = styled.div`
  div:last-child {
    text-align: center;
  }
  img {
    width: 131px;
    height: 131px;
    border-radius: 4px;
  }
  .balance {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #00a796;
      font-size: 16px;
      font-weight: 700;
      margin-left: 5px;
    }
  }
`
const BlockLeft = styled.div`
  border-right: 1px solid #6b7c78;
`

const BlockTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .title {
    font-weight: 700;
    font-size: 16px;
    color: #00a796;
    display: flex;
    align-items: end;
  }
`
const ActiveStatus = styled.div`
  width: 12px;
  height: 12px;
  background: ${({ active }) => (active ? '#167335' : '#E5141F')};
  border-radius: 50%;
  margin-right: 8px;
`

export const AppButtonStyle = styled(AppButton)`
  width: 150px !important;
  margin: 0px !important;
`

const BlockProfile = ({ dataDetail, idRouter }) => {
  const route = useHistory()
  const [isOpen, setIsOpen] = useState(false)

  const handleForceLogout = async () => {
    setIsOpen(false)
    await forceLogout({ id: dataDetail.id })
  }

  return (
    <>
      <Wrapper>
        <BlockTop>
          <div className="title">ข้อมูลบัญชี</div>

          <AppButtonStyle
            sx={{ width: '250px', mt: '32px' }}
            onClick={() => {
              route.push({
                pathname: customerAccountEdit(dataDetail.id),
                state: {
                  name: idRouter,
                  dataDetail: dataDetail
                }
              })
            }}
          >
            <img src="/images/edit.png" alt="edit" />
            ข้อมูลบัญชี
          </AppButtonStyle>
        </BlockTop>

        <Profile>
          <ImgProfile className="img-info">
            <div className="img-profile">
              <img
                src={
                  dataDetail.avatar
                    ? dataDetail.avatar
                    : '/images/avt-default.png'
                }
                alt=""
              />
            </div>
            <div className="balance">
              คะแนนคงเหลือ <span>{dataDetail.balance || 0}</span>
            </div>
          </ImgProfile>
          <BlockLeft>
            <div className="profile-detail">
              <div className="profile-item">
                <div className="title">ชื่อ</div>
                <div>{`${dataDetail.firstname} ${dataDetail.lastname}`}</div>
              </div>
              <div className="profile-item">
                <div className="title"> ประเภทบัญชี</div>
                <div>{dataDetail.groupId ? dataDetail.groupId.name : '-'}</div>
              </div>
              <div className="profile-item">
                <div className="title">อีเมล</div>
                <div style={{ display: 'flex' }}>
                  {dataDetail.email}
                  {'  '}
                  {dataDetail.isWattingVerifyEmail && (
                    <Tippy
                      offset={[30, 10]}
                      content={'รอการยืนยันทางอีเมล'}
                      className="icon-active"
                    >
                      <img
                        src="/icons/warning-icon.svg"
                        alt=""
                        width={13}
                        height={12}
                        style={{ marginTop: '7px', marginLeft: '5px' }}
                      />
                    </Tippy>
                  )}
                </div>
              </div>
              <div className="profile-item">
                <div className="title">เบอร์โทรศัพท์</div>
                <div>{dataDetail.phone}</div>
              </div>
              <div className="profile-item">
                <div className="title">วันเกิด</div>
                <div>
                  {dataDetail.birthday !== 'Invalid date'
                    ? dataDetail.birthday
                      ? convertToThai(
                          dataDetail.birthday,
                          TYPE_OF_DATE.DATE_ONLY
                        )
                      : '-'
                    : '-'}
                </div>
              </div>
              <div className="profile-item">
                <div className="title">จังหวัด</div>
                <div>
                  {dataDetail.province == 0
                    ? '-'
                    : dataDetail.province
                    ? dataDetail.province.name
                    : '-'}
                </div>
              </div>
            </div>
          </BlockLeft>
          <BlockRight>
            <div className="profile-detail">
              <div className="profile-item">
                <div className="title">เพศ</div>
                <div>
                  {dataDetail.gender
                    ? dataDetail.gender.name
                      ? dataDetail.gender.name
                      : '-'
                    : '-'}
                </div>
              </div>
              <div className="profile-item">
                <div className="title" style={{ flex: '150px 0 0' }}>
                  ที่อยู่
                </div>
                <div>{dataDetail.address ? dataDetail.address : '-'}</div>
              </div>
              <div className="profile-item">
                <div className="title">วันที่สร้าง</div>
                <div>
                  {dataDetail.createdAt
                    ? convertToThai(
                        dataDetail.createdAt,
                        TYPE_OF_DATE.DATE_HOUR_FULL
                      )
                    : '-'}
                </div>
              </div>
              <div className="profile-item">
                <div className="title">สถานะ</div>
                <div>
                  <div className="active">
                    <ActiveStatus
                      active={
                        dataDetail.status
                          ? dataDetail.status.value === 'active'
                          : ''
                      }
                    />
                  </div>
                  <div>
                    {dataDetail.status
                      ? dataDetail.status.value === 'active'
                        ? 'เปิดใช้งาน'
                        : 'ปิดใช้งาน'
                      : '-'}
                  </div>
                </div>
              </div>
            </div>
          </BlockRight>
        </Profile>
        <BlockLink>
          <div className="link">
            <span onClick={() => setIsOpen(true)}>บังคับให้ออกจากระบบ</span>
          </div>
          <Typography sx={{ fontSize: '12px', color: '#6B7C78' }}>
            ลอคอินล่าสุด{' '}
            <span className="last-login">
              {dataDetail.lastOnlineAt
                ? convertToThai(
                    dataDetail.lastOnlineAt,
                    TYPE_OF_DATE.DATE_HOUR_FULL
                  )
                : '-'}
            </span>
          </Typography>
        </BlockLink>
        <ModalDoubleButton
          title={'บังคับให้ออกจากระบบ'}
          open={isOpen}
          onDimiss={() => setIsOpen(false)}
          handleButtonGreen={() => handleForceLogout()}
          handleButtonWhite={() => setIsOpen(false)}
          textButtonGreen="ยืนยัน"
          textButtonWhite="กลับ"
        >
          บังคับให้ออกจากระบบ
        </ModalDoubleButton>
      </Wrapper>
    </>
  )
}
export default BlockProfile
