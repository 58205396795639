import React, { useState } from 'react'
import styled from 'styled-components'
import Panel from '../../../../component/Panel'
import ImageItem from './ImageItem'

type Props = {
  setDataAdd: any
  dataAdd: any
}

export const Line = styled.div`
  border-top: 1px solid #ccddd9;
  margin: 24px 8px;
`

const ProductImage = ({ setDataAdd, dataAdd }: Props): JSX.Element => {
  return (
    <>
      <Panel title="หัวข้อ (2/4)  : รูปสินค้า   ">
        <ImageItem
          images={
            typeof dataAdd.logo === 'string'
              ? dataAdd.logo !== ''
                ? [dataAdd.logo]
                : []
              : dataAdd.logo
          }
          setSelectedImage={(arrImg) =>
            setDataAdd({ ...dataAdd, logo: arrImg })
          }
          type="logo"
        />
        <Line />
        <ImageItem
          images={dataAdd.listImage}
          setSelectedImage={(arrImg) =>
            setDataAdd({ ...dataAdd, listImage: arrImg })
          }
          type="detail"
        />
      </Panel>
    </>
  )
}

export default ProductImage
