import Box from '@mui/material/Box'
import React, { useState, useEffect } from 'react'
import AppInput from '../../component/common/AppInput'
import AppInputLabel from '../../component/common/AppInputLabel'
import { ContentBottom } from '../WasteRequest/DetailRequest/DetailStyle'
import styled from 'styled-components'
import SelectSimple from '../../component/SelectSimple'
import { getDelivery } from '../../api'
import { Modal } from 'antd'
import { AppButton } from '../../component/common/AppButton'
import { updateStatusRedeemRequest } from '../../api'

export const Button = styled.button`
  width: 92px;
  height: 32px;
  font-weight: 700;
  font-size: 16px;
  background: #ffffff;
  margin-top: 32px;
  border: 1px solid #ccddd9;
  border-radius: 4px;
`

export const ContentTop = styled.div`
  margin: 0 64px 32px;
  div:first-child {
    font-weight: 700;
    font-size: 16px;
    color: #444444;
  }
`

type Props = {
  open?: any
  onDimiss?: any
  item?: any
  statusChange?: any
  senderId?: any
  getRedeemOrderList?: any
}

export const ModalDelivery = ({
  open,
  onDimiss,
  item,
  statusChange,
  getRedeemOrderList
}: Props) => {
  const [deliveryList, setDeliveryList] = useState([])
  const [deliveryId, setDeliveryId] = useState(null)
  const [trackingNo, setTrackingNo] = useState('')
  const [other, setOther] = useState('')
  const [dataAdd, setDataAdd] = useState({
    id: item.id
  })
  const [isOther, setIsOther] = useState(false)

  const handleGetDelivery = async () => {
    const res = await getDelivery()
    if (!res.error) {
      setDeliveryList(res.data)
    }
  }

  const handleChange = async () => {
    const res = await updateStatusRedeemRequest({
      id: item.id,
      deliveryId: Number(deliveryId),
      trackingno: trackingNo,
      status: statusChange,
      deliveryDescription: other
    })
    if (!res.error) {
      setTrackingNo('')
      setOther('')
      setDeliveryId(null)
      onDimiss()
      getRedeemOrderList()
    }
  }

  const handleDelivery = (delivery) => {
    const result = deliveryList.find((item) => item.id === Number(delivery))

    return result ? result.name : ''
  }

  const handleCheckDelivery = (delivery) => {
    const result = deliveryList.find((item) => item.id === Number(delivery))
    if (result.value === 'other') {
      setIsOther(true)
    } else {
      setIsOther(false)
    }
  }

  useEffect(() => {
    handleGetDelivery()
  }, [])

  return (
    <>
      <Modal title="กรุณาใส่ข้อมูลการส่งของ" visible={open} onCancel={onDimiss}>
        <ContentTop>
          <div>
            {item.senderId
              ? `${item.senderId.firstname} ${item.senderId.lastname}`
              : `-`}
          </div>
          <div>{item.productId ? item.productId.name : '-'}</div>
        </ContentTop>
        <ContentBottom>
          <Box
            sx={{
              width: '300px',
              marginTop: '8px',
              color: '#2C2C2C'
            }}
          >
            <AppInputLabel>บริษัทขนส่ง</AppInputLabel>
            <SelectSimple
              width={300}
              options={Object.values(deliveryList)}
              type="id"
              placeholder="เลือกบริษัทขนส่ง"
              value={handleDelivery(deliveryId)}
              handleSelect={(value) => {
                handleCheckDelivery(value)
                setDeliveryId(value)
              }}
            />
          </Box>
        </ContentBottom>
        {isOther && (
          <ContentBottom>
            <Box
              sx={{
                width: '300px',
                marginTop: '8px',
                color: '#2C2C2C'
              }}
            >
              <AppInputLabel>จัดส่งอื่นๆ</AppInputLabel>
              <AppInput
                fullWidth
                placeholder="จัดส่งอื่นๆ"
                onChange={(e) => setOther(e.target.value)}
              />
            </Box>
          </ContentBottom>
        )}
        <ContentBottom>
          <Box
            sx={{
              width: '300px',
              marginTop: '8px',
              color: '#2C2C2C'
            }}
          >
            <AppInputLabel>Tracking no.</AppInputLabel>
            <AppInput
              fullWidth
              placeholder="ใส่Tracking no."
              onChange={(e) => setTrackingNo(e.target.value)}
            />
          </Box>
        </ContentBottom>
        <div style={{ display: 'flex', gap: '8px', marginLeft: '64px' }}>
          <AppButton
            sx={{ width: '157px', mt: '32px', height: '32px' }}
            onClick={() => handleChange()}
          >
            ยืนยัน
          </AppButton>

          <Button>ยกเลิก</Button>
        </div>
      </Modal>
    </>
  )
}
