import React from 'react'
import styled from 'styled-components'

const StyleMain = styled.main`
  padding: 56px 24px;
  background: #eff1f4;
  width: 100%;
  margin-left: 234px;
`

const Main = ({ children }) => (
  <StyleMain className="" style={{ minHeight: '100vh' }}>
    {children}
  </StyleMain>
)
export default Main
