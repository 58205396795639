import { request } from './base-api'

export const login = async (data: any) =>
  await request('POST', '/api/auth/login', data)

export const logout = async () =>
  await request('POST', '/api/auth/logOut', {}, 'Bearer')

export const getConfig = async () =>
  await request('POST', '/api/config/getConfigPublic', {}, 'Bearer')

export const forgotPassword = async (data: any) =>
  await request('POST', '/api/auth/forgotPassword', data, 'Bearer')

export const recoverPassword = async (data: any) =>
  await request('POST', '/api/auth/recoverPassword', data, 'Bearer')

export const accountVerifications = async (data: any) =>
  await request('POST', '/api/user/accountVerifications', data, 'Bearer')

export const createProduct = async (data: any) =>
  await request('POST', '/api/product/create', data)

export const getCatagory = async () =>
  await request('POST', '/api/category/findAll', {})

export const getBrand = async () =>
  await request('POST', '/api/brand/findAll', {})

export const getProductList = async (data: any) =>
  await request('POST', '/api/product/search', data)

export const editProductDetail = async (data: any) =>
  await request('POST', '/api/product/update', data)

export const deleteProductDetail = async (data: any) =>
  await request('POST', '/api/product/remove', data)

export const setStatus = async (data: any) =>
  await request('POST', '/api/product/setStatus', data)

export const uploadImg = async (data: any) =>
  await request('POST', '/api/config/uploadImage', data, null, { img: true })

export const getListCustomerAccount = async (data: any) =>
  await request('POST', '/api/user/search', data)

export const validateTokenForgotPass = async (data: any) =>
  await request('POST', '/api/auth/validateTokenForgotPassword', data, 'Bearer')

export const getUserDetail = async (data: any) =>
  await request('POST', '/api/user/details', data)

export const getAllProvince = async () =>
  await request('POST', '/api/province/findAll', {}, 'Bearer')

export const createAccount = async (data: any) =>
  await request('POST', '/api/user/create', data)

export const setUserStatus = async (data: any) =>
  await request('POST', '/api/user/setStatus', data)

export const updateUserAccount = async (data: any) =>
  await request('POST', '/api/user/update', data)

export const getDistrict = async (data: any) =>
  await request('POST', '/api/district/findDistrict', data, 'Bearer')

export const getWard = async (data: any) =>
  await request('POST', '/api/ward/findWard', data, 'Bearer')

export const getWasteList = async (data: any) =>
  await request('GET', '/api/transaction/v1/searchWasteOrder', data)

export const getAdminAccount = async (data: any) =>
  await request('POST', '/api/officer/search', data)

export const getUserRole = async (data: any) =>
  await request('POST', '/api/userrole/search', data)

export const setAdminStatus = async (data: any) =>
  await request('POST', '/api/officer/setStatus', data)

export const resetPassWordAdmin = async (data: any) =>
  await request('POST', '/api/officer/resetpassword', data)

export const getRedeemOrder = async (data: any) =>
  await request('GET', '/api/transaction/v1/searchRedeemOrder', data)

export const searchUserTrans = async (data: any) =>
  await request('GET', '/api/user/v1/searchUserTransaction', data)

export const deliveryList = async () =>
  await request('POST', '/api/delivery/findAll', {})

export const provinceList = async () =>
  await request('POST', '/api/province/findAll', {}, 'Bearer')

export const getProductRedeem = async (data: any) =>
  await request('GET', '/api/product/v1/searchProductRedeem', data)

export const searchUserTransRedeem = async (data: any) =>
  await request('GET', '/api/user/v1/searchUserTransaction', data)

export const searchDetailProduct = async (data: any) =>
  await request('POST', '/api/product/v1/detailsProductTransaction', data)

export const searchDetailUser = async (data: any) =>
  await request('POST', '/api/user/v1/detailUserTransaction', data)

export const getRedeemDetail = async (data: any) =>
  await request('POST', '/api/transaction/v1/detailRedeem', data)

export const createRequestSendWaste = async (data: any) =>
  await request('POST', '/api/transaction/v1/createRequestSendWaste', data)

export const detailSendWaste = async (data: any) =>
  await request('POST', '/api/transaction/v1/detailSendWaste', data)

export const detailUserTransaction = async (data: any) =>
  await request('POST', '/api/user/v1/detailUserTransaction', data)

export const requestSendRedeem = async (data: any) =>
  await request('POST', '/api/transaction/v1/createRequestRedeem', data)

export const verifySendRedeem = async (data: any) =>
  await request('POST', '/api/transaction/v1/verifyRedeem', data)

export const cancelWasteRequest = async (data: any) =>
  await request('POST', '/api/transaction/v1/cancelWasteRequest', data)

export const cancleRequestRedeem = async (data: any) =>
  await request('POST', '/api/transaction/v1/cancelRedeemRequest', data)

export const getProfile = async () =>
  await request('POST', '/api/auth/getProfile', {})

export const updateRequestRedeem = async (data: any) =>
  await request('POST', '/api/transaction/v1/updateRequestRedeem', data)

export const updateDescriptionWasteRequest = async (data: any) =>
  await request(
    'POST',
    '/api/transaction/v1/updateDescriptionWasteRequest',
    data
  )

export const updateDescriptionRedeemRequest = async (data: any) =>
  await request(
    'POST',
    '/api/transaction/v1/updateDescriptionRedeemRequest',
    data
  )

export const updateStatusWasteRequest = async (data: any) =>
  await request('POST', '/api/transaction/v1/updateStatusWasteRequest', data)

export const verifySendWaste = async (data: any) =>
  await request('POST', '/api/transaction/v1/verifySendWaste', data)

export const getDelivery = async () =>
  await request('POST', '/api/delivery/findAll', {})

export const updateStatusRedeemRequest = async (data: any) =>
  await request('POST', '/api/transaction/v1/updateStatusRedeemRequest', data)

export const forceLogout = async (data: any) =>
  await request('POST', '/api/user/v1/forceLogout', data)

export const getUserHistory = async (data: any) =>
  await request('POST', '/api/user/v1/historyTransaction', data)

export const userVerify = async (data: any) =>
  await request('POST', '/api/user/userVerifications', data)

export const updateRequestSendWaste = async (data: any) =>
  await request('POST', '/api/transaction/v1/updateRequestSendWaste', data)

export const checkExpiration = async (data: any) =>
  await request('POST', '/api/user/verifyToken', data)

export const sendCode = async (data: any) =>
  await request('POST', '/api/transaction/v1/sendRedeemCodeRedeemRequest', data)

export const productDetail = async (data: any) =>
  await request('POST', '/api/product/details', data)

export const getExport = async (data: any) => {
  await request('GET', '/api/transaction/v1/export', data)
}
