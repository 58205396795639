import { Theme, ThemeOptions, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { getProfile, logout } from '../../../api'
import textLogo from '../../../asset/images/TextLogo.png'
import { KEY } from '../../../constants/const'
import { SYSTEM_ADMIN_ROUTE } from '../../../route/RouteDefinition'
import { ArrowUp, iconMenu } from './iconMenu'

const Menu = () => {
  const [isShow, setIsShow] = useState(false)
  const [isRotated, setIsRotated] = useState(false)
  const [email, setEmail] = useState('')

  const route = useHistory()
  const { pathname } = route.location

  const handleLogout = async () => {
    await logout()
    localStorage.removeItem(KEY.ACCESS_TOKEN)
    localStorage.removeItem('expiredAtUTC')
  }

  useEffect(() => {
    if (pathname.includes('/order')) {
      setIsShow(true)
    }
    if (pathname === '/') {
      route.push(SYSTEM_ADMIN_ROUTE.WASTE_REQUEST.path)
    }
  }, [pathname])

  const getProfileData = async () => {
    const res = await getProfile()
    if (res && !res.error) {
      setEmail(res.data.email)
    }
  }

  useEffect(() => {
    getProfileData()
  }, [])

  return (
    <BoxStyle
      sx={{
        background: (theme: Theme) => theme.color.ellipse3,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '236px',
        height: '100%'
      }}
    >
      <div>
        <HeaderMenu>
          <img src={textLogo} />
          <Divider />
        </HeaderMenu>

        <MainMenu>
          {/* Order */}
          <MenuItem
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            isShow={isShow}
            active={pathname.includes('/order')}
          >
            <div
              style={{ width: '100%', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                setIsShow(!isShow)
                setIsRotated(!isRotated)
                route.push(SYSTEM_ADMIN_ROUTE.WASTE_REQUEST.path)
              }}
            >
              <Box sx={{ width: '25px', lineHeight: 1 }}>{iconMenu.Order}</Box>{' '}
              <Typography
                sx={{
                  width: '80%',
                  fontSize: '1.6rem',
                  color: (theme: Theme) => theme.color.white,
                  padding: '0 10px'
                }}
              >
                จัดการออเดอร์
              </Typography>{' '}
              <IconDropDown className={isRotated && 'rotate'}>
                {ArrowUp}
              </IconDropDown>
            </div>
            {isShow && (
              <SubMenu>
                <TextTitle
                  className={`sub-menu-item ${
                    pathname.includes(SYSTEM_ADMIN_ROUTE.WASTE_REQUEST.path) &&
                    ' active'
                  }`}
                  onClick={() =>
                    route.push(SYSTEM_ADMIN_ROUTE.WASTE_REQUEST.path)
                  }
                >
                  ออเดอร์ส่งขยะ
                </TextTitle>
                <TextTitle
                  className={`sub-menu-item ${
                    pathname.includes(
                      SYSTEM_ADMIN_ROUTE.REDEEM_ORDER_LIST.path
                    ) && ' active'
                  }`}
                  onClick={() =>
                    route.push(SYSTEM_ADMIN_ROUTE.REDEEM_ORDER_LIST.path)
                  }
                >
                  ออเดอร์แลกของ
                </TextTitle>
              </SubMenu>
            )}
          </MenuItem>

          {/* Product  Catalog */}
          <MenuItem
            onClick={() => route.push(SYSTEM_ADMIN_ROUTE.PRODUCT_CATALOG.path)}
            active={pathname.includes(SYSTEM_ADMIN_ROUTE.PRODUCT_CATALOG.path)}
          >
            <Box sx={{ width: '25px', lineHeight: 1 }}>{iconMenu.Product}</Box>{' '}
            <TextTitle>จัดการสินค้า</TextTitle>
          </MenuItem>

          {/* Customer */}
          <MenuItem
            onClick={() =>
              route.push(SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_MANAGER.path)
            }
            active={pathname.includes(
              SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_MANAGER.path
            )}
          >
            <Box sx={{ width: '25px', lineHeight: 1 }}>{iconMenu.Customer}</Box>
            <TextTitle>จัดการบัญชีผู้ใช้</TextTitle>
          </MenuItem>

          {/* Admin */}
          <MenuItem
            onClick={() => route.push(SYSTEM_ADMIN_ROUTE.ADMIN_ACCOUNT.path)}
            active={pathname.includes(SYSTEM_ADMIN_ROUTE.ADMIN_ACCOUNT.path)}
          >
            <Box sx={{ width: '25px', lineHeight: 1 }}>{iconMenu.User}</Box>
            <TextTitle>จัดการบัญชีแอดมิน</TextTitle>
          </MenuItem>

          {/* Activities */}
          <MenuItem
            onClick={() => route.push(SYSTEM_ADMIN_ROUTE.ACTIVITY.path)}
            active={pathname.includes(SYSTEM_ADMIN_ROUTE.ACTIVITY.path)}
          >
            <Box sx={{ width: '25px', lineHeight: 1 }}>
              {iconMenu.Activities}
            </Box>
            <TextTitle>ดาวโหลดข้อมูล</TextTitle>
          </MenuItem>
        </MainMenu>
      </div>

      <FooterMenu>
        <DividerMargin />
        <div>{email}</div>
        <div className="logout" onClick={handleLogout}>
          ออกจากระบบ
        </div>
      </FooterMenu>
    </BoxStyle>
  )
}

export default Menu

const BoxStyle = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const HeaderMenu = styled.div`
  padding-top: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
`

const Divider = styled.div`
  border-top: 1px solid #6b7c78;
  width: 184px;
`

const DividerMargin = styled.div`
  border-top: 1px solid #6b7c78;
  width: 184px;
  margin-bottom: 8px;
`

const MainMenu = styled.div`
  margin-top: 8px;
`

const MenuItem = styled.div`
  padding: 12px 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background: ${({ isShow }) => (isShow ? '#1C1C1C' : 'transparent')};

  span,
  p {
    color: ${({ isShow, active }) =>
      isShow || active ? '#00a796' : '#ffffff'};
    padding: 0px 10px;
  }
  svg path {
    fill: ${({ isShow, active }) => (isShow || active ? '#00a796' : '#ffffff')};
  }
  :hover {
    background: #444444;
  }
`

const SubMenu = styled.div`
  padding-top: 8px;
  padding-left: 52px;

  .active {
    color: #00a796 !important;
  }

  .sub-menu-item {
    padding: 8px 0px;
    color: #ffffff;

    :hover {
      color: #00a796;
    }
  }
`

const FooterMenu = styled.div`
  color: #ffffff;
  padding: 8px 26px 32px 26px;
  .logout {
    font-weight: 700;
    font-size: 14px;
    text-decoration-line: underline;
    color: #00a796;
    cursor: pointer;
  }
`
const TextTitle = styled.span`
  font-size: 1.6rem !important;
  display: block;
`

const IconDropDown = styled.span`
  line-height: 0;
  &.rotate {
    transform: rotate(180deg);
  }
`
