import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { recoverPassword, validateTokenForgotPass } from '../../api'
import AppInput from '../../component/common/AppInput'
import { encryptAuth } from '../../function/ecrypt'
import { PUBLIC_ROUTE } from '../../route/RouteDefinition'
import { useAppMaster } from '../../store/_hook'
import { InputBlock } from '../Login'
import { ErrorPass } from '../RecoverPassword'
import eyeIcon from '../../asset/images/eye.svg'

export const RecoverPasswordApp = () => {
  const [newPassword, setNewpassword] = useState('')
  const [confirmPassword, setConfirmpassword] = useState('')
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isPassEmty, setIsPassEmty] = useState(false)
  const [isConfirmPassEmty, setIsConfirmPassEmty] = useState(false)
  const [isLessCharacter, setIsLessCharacter] = useState(false)
  const [isNotLowerCharacter, setIsNotLowerCharacter] = useState(false)
  const [isNotNumber, setIsNotNumber] = useState(false)
  const [isNotMatch, setIsNotMatch] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const route = useHistory()
  // const token = route.location.search.substring(7)
  const url = window.location.search
  const params = new URLSearchParams(url)

  const {
    state: { configMaster }
  } = useAppMaster()

  const checkToken = async () => {
    const res = await validateTokenForgotPass({
      token: params.get('token')
    })

    if (!res.isValid) {
      route.push({
        pathname: PUBLIC_ROUTE.RECOVER_PASSWORD_STATUS.path,
        state: res.isValid
      })
    }
  }

  const handleSubmit = async () => {
    setIsCheck(true)
    if (newPassword.trim() === '') {
      setIsPassEmty(true)
    }
    if (confirmPassword.trim() === '') {
      setIsConfirmPassEmty(true)
    }
    if (
      newPassword !== '' &&
      confirmPassword !== '' &&
      !isLessCharacter &&
      !isNotLowerCharacter &&
      !isNotNumber &&
      !isNotMatch
    ) {
      const res = await validateTokenForgotPass({
        token: params.get('token')
      })
      if (!res.isValid) {
        route.push({
          pathname: PUBLIC_ROUTE.RECOVER_PASSWORD_STATUS.path,
          state: res.isValid
        })
      } else {
        const password = encryptAuth(
          newPassword,
          configMaster.encryptPassNew,
          configMaster.characterSeparate
        )
        const passwordConfirm = encryptAuth(
          confirmPassword,
          configMaster.encryptPassConfirm,
          configMaster.characterSeparate
        )

        const res = await recoverPassword({
          token: params.get('token'),
          password: password,
          passwordConfirm: passwordConfirm
        })
        if (res.data) {
          route.push({
            pathname: PUBLIC_ROUTE.RECOVER_PASSWORD_STATUS.path,
            state: true
          })
        } else
          route.push({
            pathname: PUBLIC_ROUTE.RECOVER_PASSWORD_STATUS.path,
            state: false
          })
      }
    }
  }

  useEffect(() => {
    checkToken()
  }, [])

  useEffect(() => {
    if (isCheck) {
      const rgxNumber = /\d/
      newPassword.length < 8
        ? setIsLessCharacter(true)
        : setIsLessCharacter(false)
      newPassword.toLocaleUpperCase() === newPassword
        ? setIsNotLowerCharacter(true)
        : setIsNotLowerCharacter(false)
      !rgxNumber.test(newPassword)
        ? setIsNotNumber(true)
        : setIsNotNumber(false)
      newPassword.trim() !== '' && setIsPassEmty(false)
      confirmPassword.trim() !== '' && setIsConfirmPassEmty(false)
      confirmPassword !== '' &&
      newPassword !== '' &&
      confirmPassword !== newPassword
        ? setIsNotMatch(true)
        : setIsNotMatch(false)
    }
  }, [newPassword, confirmPassword])

  return (
    <>
      <WrapperMobile>
        <Header>สร้างรหัสผ่านใหม่</Header>

        <div>
          <Title>รหัสผ่าน</Title>
          <InputBlock
            style={{
              border:
                !isPassEmty &&
                !isLessCharacter &&
                !isNotLowerCharacter &&
                !isNotNumber
                  ? '1px solid #DEDEDE'
                  : '1px solid red',
              borderRadius: '8px',
              marginTop: '8px'
            }}
          >
            <AppInput
              fullWidth
              type={showPassword ? 'text' : 'password'}
              placeholder="กรอกรหัสผ่าน"
              onChange={(e) => {
                setIsCheck(true), setNewpassword(e.target.value)
              }}
              value={newPassword}
              sx={{
                border: 'none',
                background: 'transparent'
              }}
            />
            {!showPassword ? (
              <img
                src="/icons/icon-hide.png"
                onClick={() => setShowPassword(true)}
              />
            ) : (
              <img src={eyeIcon} onClick={() => setShowPassword(false)} />
            )}
          </InputBlock>
          {isPassEmty && <div style={{ color: 'red' }}>กรุณากรอกรหัสผ่าน</div>}
        </div>

        <ErrorPass>
          <ul style={{ marginLeft: '20px' }}>
            <li className={isCheck && isLessCharacter && `error`}>
              มีอย่างน้อย 8 ตัวอักษร
            </li>
            <li className={isCheck && isNotLowerCharacter && `error`}>
              มีตัวอักษร a-z อย่างน้อย 1 ตัว
            </li>
            <li className={isCheck && isNotNumber && `error`}>
              มีตัวเลข 0-9 อย่างน้อย 1 ตัว
            </li>
          </ul>
        </ErrorPass>

        <div>
          <Title>ยืนยันรหัสผ่านใหม่</Title>
          <InputBlock
            style={{
              border:
                (isNotMatch && confirmPassword !== '') || isConfirmPassEmty
                  ? '1px solid red'
                  : '1px solid #DEDEDE',
              borderRadius: '8px',
              marginTop: '8px'
            }}
          >
            <AppInput
              fullWidth
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="กรอกรหัสผ่านอีกครั้ง"
              onChange={(e) => {
                if (e.target.value !== newPassword) {
                  setIsNotMatch(true)
                } else setIsNotMatch(false)
                setConfirmpassword(e.target.value)
              }}
              value={confirmPassword}
              sx={{
                border: 'none',
                background: 'transparent'
              }}
            />
            {!showConfirmPassword ? (
              <img
                src="/icons/icon-hide.png"
                onClick={() => setShowConfirmPassword(true)}
              />
            ) : (
              <img
                src={eyeIcon}
                onClick={() => setShowConfirmPassword(false)}
              />
            )}
          </InputBlock>
          {isConfirmPassEmty && (
            <div style={{ color: 'red' }}>กรุณากรอกรหัสผ่านอีกครั้ง</div>
          )}
          {isNotMatch && isCheck && confirmPassword !== '' && (
            <div style={{ color: 'red' }}>ยืนยันรหัสผ่านไม่ถูกต้อง</div>
          )}
        </div>

        <ButtonSubmit onClick={handleSubmit}>เปลี่ยนรหัสผ่าน</ButtonSubmit>
      </WrapperMobile>
    </>
  )
}

const WrapperMobile = styled.div`
  margin: 24px 16px;
  .error {
    color: red;
  }
`

const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #0f0f0f;
  padding-bottom: 16px;
`

const Title = styled(Header)`
  font-size: 16px;
  padding-bottom: 0px;
`

const ButtonSubmit = styled.button`
  background: #00a796;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  padding: 8px;
  margin-top: 24px;
`
