import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { AppButtonStyle } from '../../features/ProductCatalog/ProductDetail'
import {
  Title,
  Wrapper
} from '../../features/ProductCatalog/ProductList/ProductInfo'
import Layout from '../../layouts/DefaultLayout'
import addIcon from '../../asset/images/Vector1.svg'
import Typography from '@mui/material/Typography'
import {
  ImageSearch,
  InputSearch
} from '../../features/ProductCatalog/ProductList/SearchFilter'
import searchIcon from '../../asset/images/Vector2.svg'
import FilterWaste from '../../features/WasteRequest/FilterWaste'
import DisplayResult from '../../features/ProductCatalog/ProductList/DisplayResult'
import PaginationSquare from '../../component/PaginationSquare'
import { PAGE_SIZE, TableCellStyle } from '../ProductCatalog'
import THead from '../../component/THead'
import TableBlock from '../../component/TableBlock'
import { Box, TableBody } from '@mui/material'
import RequestItem from '../../features/WasteRequest/RequestItem'
import { SYSTEM_ADMIN_ROUTE } from '../../route/RouteDefinition'
import { useHistory } from 'react-router-dom'
import sortImg from '../../asset/images/Vector3.svg'
import { getWasteList } from '../../api'
import ProductEmpty from '../../features/ProductCatalog/ProductList/ProductEmpty'
import useDebounce from '../../hook/useDebounce'

export const WasteRequest = () => {
  const route = useHistory()
  const [total, setTotal] = useState(0)
  const [dataList, setDataList] = useState<any>()
  const [dataSearch, setDataSearch] = useState<any>({
    start: 1,
    number: PAGE_SIZE
  })

  const debouncedSearch = useDebounce(dataSearch, 300)

  const fetchData = async () => {
    try {
      const wasteList = await getWasteList(dataSearch)

      if (!wasteList.error) {
        setDataList(wasteList.data)
        setTotal(wasteList.total)
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchData()
  }, [debouncedSearch])

  return (
    <>
      <Layout>
        {/* Title and Button Add */}
        <Wrapper>
          <Typography
            sx={{
              fontSize: '3.2rem',
              fontWeight: 'bold'
            }}
          >
            จัดการออเดอร์ส่งขยะ
          </Typography>
          <AppButtonStyle
            sx={{ width: '269px', mt: '32px' }}
            onClick={() => route.push(SYSTEM_ADMIN_ROUTE.ADD_NEW_REQUEST.path)}
          >
            <img src={addIcon} />
            <Title>สร้างออเดอร์ส่งขยะใหม่</Title>
          </AppButtonStyle>
        </Wrapper>
        {/* Search and Filter */}
        <SearchBlock>
          <Wrapper>
            <InputSearch
              placeholder="ค้นหาด้วย ชื่อ, อีเมล, เบอร์โทรศัพท์"
              onChange={(e) =>
                setDataSearch({
                  ...dataSearch,
                  keyword: e.target.value,
                  start: 1
                })
              }
              value={dataSearch.keyword || ''}
            />
            <ImageSearch src={searchIcon} />
          </Wrapper>
          <ImageSearch src={searchIcon} />
        </SearchBlock>
        <FilterWaste dataSearch={dataSearch} setDataSearch={setDataSearch} />

        {dataList && dataList.length > 0 ? (
          <>
            {/* Pagination */}
            <Wrapper>
              <DisplayResult total={total} />
              <PaginationSquare
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
                totalPage={Math.ceil(total / PAGE_SIZE)}
              />
            </Wrapper>
            {/* Waste Request List */}
            <TableBlock>
              <THead>
                <TableCell
                  onClick={() =>
                    setDataSearch({
                      ...dataSearch,
                      optionSort: 'id',
                      typeSort: !dataSearch.typeSort
                        ? 'ASC'
                        : dataSearch.typeSort === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                    })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  เลขที่
                  <img src={sortImg} style={{ marginLeft: '7px' }} />
                </TableCell>
                <TableCellStyle>ข้อมูลพัสดุ</TableCellStyle>
                <TableCellStyle>ข้อมูลผู้ใช้</TableCellStyle>
                <TableCellStyle>วันที่สร้าง</TableCellStyle>
                <TableCellStyle>สถานะ</TableCellStyle>
                <TableCellStyle></TableCellStyle>
              </THead>
              <TableBody>
                {dataList.map((request) => (
                  <RequestItem
                    dataItem={request}
                    fetchData={fetchData}
                    key={request.id}
                  />
                ))}
              </TableBody>
            </TableBlock>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '8px'
              }}
            >
              <PaginationSquare
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
                totalPage={Math.ceil(total / PAGE_SIZE)}
              />
            </Box>
          </>
        ) : (
          <>
            <DisplayResult total={total} />
            <ProductEmpty type="waste" />
          </>
        )}
      </Layout>
    </>
  )
}

export const SearchBlock = styled.div`
  margin-top: 40px;
  position: relative;
`

export const TableCell = styled(TableCellStyle)`
  display: flex;
`
