import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { productDetail } from '../../../api'
import { AppButton } from '../../../component/common/AppButton'
import Header from '../../../component/Header'
import Layout from '../../../layouts/DefaultLayout'
import { PAGE_SIZE } from '../../../page/ProductCatalog'
import {
  productEditItemLink,
  SYSTEM_ADMIN_ROUTE
} from '../../../route/RouteDefinition'
import { Title } from '../AddNewProduct'
import { ProductContactDetail } from './ProductContactDetail'
import { ProductDescriptionDetail } from './ProductDescriptionDetail'
import { ProductImageDetail } from './ProductImageDetail'
import { ProductInfoDetail } from './ProductInfoDetail'

const Wrapper = styled.div``

export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const AppButtonStyle = styled(AppButton)`
  img {
    padding-right: 8px;
  }
`

export const ProductDetail = () => {
  const route = useHistory()
  const location = useLocation()
  const [dataDetail, setDataDetail] = useState()
  const idProduct = location.pathname.split('/')[2]

  const fetchDetail = async () => {
    const res = await productDetail({
      id: idProduct
    })

    if (!res.error) {
      setDataDetail(res.data)
    }
  }

  useEffect(() => {
    fetchDetail()
  }, [])

  return (
    <Layout>
      {dataDetail && (
        <>
          <Header>
            <div
              className="title-root"
              onClick={() =>
                route.push(SYSTEM_ADMIN_ROUTE.PRODUCT_CATALOG.path)
              }
            >
              รายการสินค้า{' '}
            </div>
            <div className="title-sub">
              {' '}
              / รายละเอียดสินค้า : {dataDetail.name}{' '}
            </div>
          </Header>

          <Wrapper>
            <TitleBlock>
              <Title>รายละเอียดสินค้า</Title>
              <AppButtonStyle
                sx={{ width: '250px', mt: '32px' }}
                onClick={() => route.push(productEditItemLink(dataDetail.id))}
              >
                <img src="/images/edit.png" alt="edit" />
                แก้ไขรายละเอียดสินค้า
              </AppButtonStyle>
            </TitleBlock>

            <ProductInfoDetail dataDetail={dataDetail} />

            <ProductImageDetail dataDetail={dataDetail} />

            <ProductDescriptionDetail dataDetail={dataDetail} />

            <ProductContactDetail dataDetail={dataDetail} />

            <div>
              <AppButtonStyle
                sx={{ width: '250px', mt: '32px' }}
                onClick={() => route.push(productEditItemLink(dataDetail.id))}
              >
                <img src="/images/edit.png" alt="edit" />
                แก้ไขรายละเอียดสินค้า
              </AppButtonStyle>

              <AppButton
                sx={{ width: '134px', mt: '32px', marginLeft: '10px' }}
                color="secondary"
                onClick={() =>
                  route.push(SYSTEM_ADMIN_ROUTE.PRODUCT_CATALOG.path)
                }
              >
                กลับ
              </AppButton>
            </div>
          </Wrapper>
        </>
      )}
    </Layout>
  )
}
