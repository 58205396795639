import Box from '@mui/material/Box'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Panel from '../../../component/Panel'
import {
  ImageSearch,
  InputSearch,
  Wrapper
} from '../../ProductCatalog/ProductList/SearchFilter'
import {
  Contact,
  HeaderInfo,
  InfoUser
} from '../../WasteRequest/AddRequest/AddRequestInfo'
import searchIcon from '../../../asset/images/Vector2.svg'
import { Title } from '../../ProductCatalog/AddNewProduct/ProductImages/ImageItem'
import { InfoProduct } from './InfoProduct'
import { getProductRedeem, searchDetailProduct } from '../../../api'
import { ProductList } from './ProductList'

export const AddProductInfo = ({
  setDataAdd,
  dataAdd,
  error,
  setProductType
}) => {
  const [dataListProduct, setDataListProduct] = useState<any>([])
  const [keySearchProduct, setKeySearchProduct] = useState('')
  const [showProductList, setShowProductList] = useState(false)
  const [productDetail, setProductDetail] = useState({})
  const [productId, setProductId] = useState(null)

  const fetchProduct = async () => {
    const res = await getProductRedeem({ keyword: keySearchProduct })
    if (!res.error) {
      setDataListProduct(res.data)
    }
  }

  const fetchProductDetail = async (id: any) => {
    const res = await searchDetailProduct({ id })
    if (!res.error) {
      setProductDetail(res.data)
    }
  }

  useEffect(() => {
    fetchProduct()
  }, [keySearchProduct])

  useEffect(() => {
    setDataAdd({ ...dataAdd, productId: productId ? productId : null })
  }, [productId])

  return (
    <>
      <Panel title="หัวข้อ (2/2)  : สินค้าที่ต้องการเเลก">
        <Box>
          <Title>ข้อมูลสินค้า</Title>
          <Wrapper style={{ margin: '33px 0px 0px 24px' }}>
            <InputSearch
              placeholder="ค้นหาสินค้า"
              onClick={() => {
                setShowProductList(!showProductList)
              }}
              onChange={(e) => setKeySearchProduct(e.target.value)}
              error={error && !dataAdd.productId}
            />
            <ImageSearch src={searchIcon} />
            {showProductList && (
              <ProductList
                dataListProduct={dataListProduct}
                setShowProductList={setShowProductList}
                fetchProductDetail={fetchProductDetail}
                setProductId={setProductId}
              />
            )}
          </Wrapper>
          <div
            style={{
              fontSize: '12px',
              marginLeft: '24px',
              color: error && !dataAdd.productId ? 'red' : '#6B7C78'
            }}
          >
            {error && !dataAdd.productId
              ? 'จำเป็นต้องเลือก'
              : ' ค้นหาด้วย ชื่อสินค้า แบรนด์ หรือ หมวดหมู่ '}
          </div>
        </Box>
        {Object.keys(productDetail).length != 0 ? (
          <InfoProduct
            productDetail={productDetail}
            setProductDetail={setProductDetail}
            setProductType={setProductType}
            setProductId={setProductId}
          />
        ) : (
          ''
        )}
      </Panel>
    </>
  )
}

export const ImgProduct = styled.div`
  width: 60px;
  height: 60px;
  border: 1px solid white;
  border-radius: 4px;
  background: white;
  img {
    object-fit: scale-down;
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }
`
export const ImgProductDetail = styled.div`
  width: 80px;
  height: 80px;
  border: 1px solid white;
  border-radius: 4px;
  background: white;
  img {
    object-fit: scale-down;
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }
`

export const DetailProduct = styled.div`
  .bold-text {
    font-weight: 700;
    font-size: 14px;
    color: #2c2c2c;
  }

  .primary-text {
    font-weight: 400;
    font-size: 16px;
    color: #00a796;
    margin-top: 6px;
  }
  .thin-text {
    font-weight: 400;
    font-size: 14px;
    color: #6b7c78;
    margin-top: 6px;
  }

  span {
    padding: 0px 16px;
  }
`
