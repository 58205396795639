import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { updateUserAccount } from '../../../api'
import { AppButton } from '../../../component/common/AppButton'
import Header from '../../../component/Header'
import ModalDoubleButton from '../../../component/ModalDouleButton'
import Layout from '../../../layouts/DefaultLayout'
import { Content } from '../../../page/ForgotPassword'
import { SYSTEM_ADMIN_ROUTE } from '../../../route/RouteDefinition'
import { Title } from '../../ProductCatalog/AddNewProduct'
import EditAddress from './EditAddress'
import EditProfile from './EditProfile'
import ModalBasic from '../../../component/ModalBasic'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const AppButtonStyle = styled(AppButton)`
  img {
    padding-right: 8px;
  }
`

const EditAccount = () => {
  const route = useHistory()
  const location = useLocation()
  const [isBack, setIsBack] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [isCheckEx, setIsCheckEx] = useState(false)
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [valid, setValid] = useState(true)
  const [isPhone, setIsPhone] = useState(true)

  const { name, dataDetail } = location.state

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  const validate = (email) => {
    if (validateEmail(email)) {
      setValid(true)
    } else {
      setValid(false)
    }
    return false
  }

  const dataToUpdate = {
    ...dataDetail,
    gender:
      Object.keys(dataDetail.gender).length === 0 ? '' : dataDetail.gender,
    date:
      dataDetail.birthday !== 'Invalid date'
        ? moment(dataDetail.birthday).date().toString()
        : '',
    month:
      dataDetail.birthday !== 'Invalid date'
        ? (moment(dataDetail.birthday).month() + 1).toString()
        : '',
    year:
      dataDetail.birthday !== 'Invalid date'
        ? moment(dataDetail.birthday).year().toString()
        : ''
  }

  delete dataToUpdate.district
  delete dataToUpdate.feature
  delete dataToUpdate.ward

  const [dataAdd, setDataAdd] = useState(dataToUpdate)

  const { addressExtend: addressArray } = dataDetail

  const [addressExtendArr, setAddressExtendArr] = useState(addressArray)

  const handlePush = () => {
    const newAddress = [...addressExtendArr]
    newAddress.push({
      firstname: '',
      lastname: '',
      phone: '',
      province: '',
      district: '',
      ward: '',
      address: '',
      postcode: '',
      isDefault: false
    })
    setAddressExtendArr(newAddress)
  }
  const handlePushDefault = () => {
    const newAddress = [...addressExtendArr]
    newAddress.push({
      firstname: '',
      lastname: '',
      phone: '',
      province: '',
      district: '',
      ward: '',
      address: '',
      postcode: '',
      isDefault: true
    })
    setAddressExtendArr(newAddress)
  }

  const handleUpdate = (indexItem, itemToAdd) => {
    let newListUpdate = [...addressExtendArr]
    newListUpdate.forEach((item, index) => {
      if (index === indexItem) {
        item.firstname = itemToAdd.firstname
        item.lastname = itemToAdd.lastname
        item.phone = itemToAdd.phone
        item.province = itemToAdd.province
        item.district = itemToAdd.district
        item.ward = itemToAdd.ward
        item.address = itemToAdd.address
        item.postcode = itemToAdd.postcode
        item.isDefault = JSON.parse(itemToAdd.isDefault)
      }
    })
    setAddressExtendArr(newListUpdate)
  }

  const handleChangeDefault = (indexItem) => {
    let newList = [...addressExtendArr]
    newList.forEach((item, index) => {
      if (index !== indexItem) {
        return (item.isDefault = false)
      }
    })

    setAddressExtendArr(newList)
  }

  const handleDelete = (indexItem) => {
    let newList = [...addressExtendArr]
    newList.splice(indexItem, 1)
    setAddressExtendArr(newList)
  }

  const handleFormat = (addressExtendArr) => {
    return addressExtendArr.map((item) => {
      return {
        ...item,
        district:
          typeof item.district === 'object' ? item.district.id : item.district,
        postcode:
          typeof item.postcode === 'object'
            ? item.postcode.value
            : item.postcode,
        ward: typeof item.ward === 'object' ? item.ward.id : item.ward,
        province:
          typeof item.province === 'object' ? item.province.id : item.province
      }
    })
  }

  const dataOrigin = {
    ...dataAdd,
    birthday:
      dataAdd.year !== '' && dataAdd.month !== '' && dataAdd.date !== ''
        ? dataAdd.year.concat('/', dataAdd.month, '/', dataAdd.date)
        : '',
    province: dataAdd.province
      ? typeof dataAdd.province === 'object'
        ? dataAdd.province.id
        : dataAdd.province
      : '',
    groupId: dataAdd.groupId
      ? typeof dataAdd.groupId === 'object'
        ? dataAdd.groupId.value
        : dataAdd.groupId
      : '',
    gender: dataAdd.gender
      ? typeof dataAdd.gender === 'object'
        ? dataAdd.gender.value
        : dataAdd.gender
      : '',
    status: dataAdd.status
      ? typeof dataAdd.status === 'object'
        ? dataAdd.status.value
        : dataAdd.status
      : '',

    addressExtend: handleFormat(addressExtendArr)
  }

  delete dataOrigin.date
  delete dataOrigin.month
  delete dataOrigin.year

  const { email, firstname, lastname, phone, groupId } = dataOrigin

  const { addressExtend: arr } = dataOrigin

  const checkRequired = () => {
    if (
      email.trim() === '' ||
      firstname.trim() === '' ||
      lastname.trim() === '' ||
      phone.trim() === '' ||
      groupId === '' ||
      valid === false ||
      isPhone === false
    ) {
      return true
    } else {
      return false
    }
  }

  const checkValidPhoneEx = () => {
    for (const item of arr) {
      if (item.phone.trim().length != 10) {
        return true
      }
    }
    return false
  }

  const checkRequiredEx = () => {
    for (const item of arr) {
      if (
        item.address.trim() === '' ||
        item.firstname.trim() === '' ||
        item.lastname.trim() === '' ||
        item.phone.trim() === '' ||
        item.postcode.toString().trim() === '' ||
        item.province.toString().trim() === '' ||
        item.district.toString().trim() === '' ||
        item.ward.toString().trim() === ''
      ) {
        return true
      }
    }
    return false
  }

  const handlePushUpdate = async () => {
    const isRequiredEx = checkRequiredEx()
    const isRequired = checkRequired()
    const checkPhoneValid = checkValidPhoneEx()
    try {
      if (isRequiredEx || isRequired || checkPhoneValid) {
        setIsCheckEx(true)
        setIsCheck(true)
        setIsWarningModal(true)
      } else {
        const res = await updateUserAccount(dataOrigin)
        if (!res.error) {
          route.push(SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_MANAGER.path)
        }
      }
    } catch (error) {
      console.log(`error`, error)
    }
  }

  useEffect(() => {
    setIsPhone(dataOrigin.phone.length == 10)
  }, [dataOrigin.phone])

  return (
    <>
      <Layout>
        <Header>
          <div className="title-root" onClick={() => setIsBack(true)}>
            จัดการบัญชีผู้ใช้{' '}
          </div>
          <div className="title-sub"> / แก้ไขบัญชีผู้ใช้ : {name} </div>
        </Header>

        <Wrapper>
          <Title>แก้ไขบัญชีผู้ใช้</Title>
        </Wrapper>

        <EditProfile
          dataAdd={dataAdd}
          setDataAdd={setDataAdd}
          isCheck={isCheck}
          validate={validate}
          valid={valid}
          isPhone={isPhone}
        />
        <EditAddress
          addressExtend={addressExtendArr}
          handlePush={handlePush}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          handleChangeDefault={handleChangeDefault}
          handlePushDefault={handlePushDefault}
          isCheck={isCheckEx}
        />

        <div>
          <AppButtonStyle
            sx={{ width: '200px', mt: '32px' }}
            onClick={() => handlePushUpdate()}
          >
            <img src="/images/save.png" alt="save" />
            บันทึก
          </AppButtonStyle>

          <AppButton
            sx={{ width: '134px', mt: '32px', marginLeft: '10px' }}
            color="secondary"
            onClick={() => setIsBack(true)}
          >
            ยกเลิก
          </AppButton>
        </div>

        <ModalDoubleButton
          title="ยังไม่ได้บันทึกข้อมูล"
          open={isBack}
          onDimiss={() => setIsBack(false)}
          handleButtonGreen={() => setIsBack(false)}
          handleButtonWhite={() =>
            route.push(SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_MANAGER.path)
          }
          textButtonGreen="อยู่ต่อหน้านี้"
          textButtonWhite="ออก"
        >
          <Content>คุณแน่ใจว่าจะออกจากหน้านี้ ข้อมูลทั้งหมดจะถูกลบ</Content>
        </ModalDoubleButton>

        <ModalBasic
          title=""
          open={isWarningModal}
          onDimiss={() => setIsWarningModal(false)}
        >
          <Content>กรุณาใส่ข้อมูลที่จำเป็นทั้งหมด</Content>
        </ModalBasic>
      </Layout>
    </>
  )
}

export default EditAccount
