import { useAppDispatch, useAppSelector } from '../hooks/useAppRedux'
import { _updateConfig } from './masterSlice'

export const useAppMaster = () => {
  const dispatch = useAppDispatch()
  return {
    state: useAppSelector((state) => state.master),
    updateConfigApp: (data: Object) => dispatch(_updateConfig(data))
  }
}
