import React from 'react'
import styled from 'styled-components'
import Panel from '../../../component/Panel'
import { Line } from '../AddNewProduct/ProductImages'
import {
  CountImg,
  Note,
  NotImagePreview
} from '../AddNewProduct/ProductImages/ImageItem'
import { BlueTitle } from './ProductInfoDetail'

const ImageBlock = styled.div`
  background: #eff1f4;
  border: 1px solid #ccddd9;
  border-radius: 8px;
  width: 100%;
  height: auto;
  padding: 14px 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`

export const Image = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
  height: 160px;
  background: #ffffff;
  border: 1px solid #ccddd9;
  box-sizing: border-box;
  border-radius: 4px;
`

export const ProductImageDetail = ({ dataDetail }) => {
  return (
    <Panel title="หัวข้อ (2/4)  : รูปสินค้า   ">
      <BlueTitle>รูปโลโก้แบรนด์</BlueTitle>
      {dataDetail.logo !== '' ? (
        <ImageBlock>
          <Image>
            <img src={dataDetail.logo} width="100%" />
          </Image>
        </ImageBlock>
      ) : (
        <NotImagePreview>
          <img src="/images/image-default.png" />
          <div>ไม่มีรูปภาพในขณะนี้</div>
        </NotImagePreview>
      )}
      <CountImg>
        {' '}
        <img src="/images/img-sx.png" width="14px" /> (
        {dataDetail.logo !== '' ? 1 : 0}/1)
      </CountImg>
      <Note>
        <li>อัปโหลดได้ไม่เกิน 1 รูป</li>
        <li>ขนาดภาพที่ใช้ได้ 160 x 160px</li>
        <li>ขนาดไฟล์สูงสุดที่ 2 MB</li>
        <li>สกุลไฟล์ที่ใช้ได้ .png, .jpg</li>
      </Note>
      <Line />
      <BlueTitle>รูปสินค้า</BlueTitle>
      {dataDetail.listImage.length > 0 ? (
        <ImageBlock>
          {dataDetail.listImage.map((img) => (
            <Image>
              <img
                src={img}
                width="100%"
                height="100%"
                style={{ objectFit: 'scale-down' }}
              />
            </Image>
          ))}
        </ImageBlock>
      ) : (
        <NotImagePreview>
          <img src="/images/image-default.png" />
          <div>ไม่มีรูปภาพในขณะนี้</div>
        </NotImagePreview>
      )}
      <CountImg>
        {' '}
        <img src="/images/img-sx.png" width="14px" /> (
        {dataDetail.listImage.length || 0}/10)
      </CountImg>
      <Note>
        <li>อัปโหลดได้ไม่เกิน 10 รูป</li>
        <li>ขนาดภาพที่แนะนำ 360 x 320px</li>
        <li>ขนาดไฟล์สูงสุดที่ 2 MB</li>
        <li>สกุลไฟล์ที่ใช้ได้ .png, .jpg</li>
      </Note>
    </Panel>
  )
}
