export const SYSTEM_ADMIN_ROUTE = {
  HOME: {
    name: 'SystemAdminHomePage',
    path: '/'
  },
  PRODUCT_CATALOG: {
    name: 'ProductCatalog',
    path: '/product-catalog'
  },
  ADD_NEW_PRODUCT: {
    name: 'Add New Product',
    path: '/product-catalog/add-new-product'
  },
  PRODUCT_DETAIL_ITEM: {
    name: 'ProductDetail',
    path: '/product-catalog/:name'
  },
  PRODUCT_DETAIL_EDIT: {
    name: 'ProductEdit',
    path: '/product-catalog/edit/:name'
  },
  WASTE_REQUEST: {
    name: 'Waste Request',
    path: '/order/waste-request'
  },
  ADD_NEW_REQUEST: {
    name: 'Add New Request',
    path: '/order/waste-request/add-request'
  },
  WASTE_REQUEST_DETAIL: {
    name: 'Wastet Request Detai',
    path: '/order/waste-request/:name'
  },
  CUSTOMER_ACCOUNT_MANAGER: {
    name: 'Customer account management',
    path: '/customer-account-management'
  },
  REDEEM_ORDER_LIST: {
    name: 'Redeem Order List',
    path: '/order/redeem-order'
  },
  ADD_REDEEM_ORDER: {
    name: 'Add Redeem Order',
    path: '/order/redeem-order/add-redeem-order'
  },
  CUSTOMER_ACCOUNT_CREATE: {
    name: 'Customer account management',
    path: '/customer-account-management/create'
  },
  CUSTOMER_ACCOUNT_ITEM: {
    name: 'Customer Account Detail',
    path: '/customer-account-management/:name'
  },
  CUSTOMER_ACCOUNT_ITEM_WASTE_REQUEST: {
    name: 'Customer Account Detail',
    path: '/customer-account-management/waste-request/:name'
  },
  CUSTOMER_ACCOUNT_MANAGER_EDIT: {
    name: 'Customer account management',
    path: '/customer-account-management/edit/:name'
  },
  REDEEM_DETAIL: {
    name: 'Redeem Detail',
    path: '/order/redeem-order/:name'
  },
  ADMIN_ACCOUNT: {
    name: 'Admin Account',
    path: '/admin-account'
  },
  ACTIVITY: {
    name: 'Admin Activity',
    path: '/activity'
  }
}

export const PUBLIC_ROUTE = {
  LOGIN: {
    name: 'LoginPage',
    path: '/login'
  },
  FORGOT_PASSWORD: {
    name: 'ForgotPage',
    path: '/forgot'
  },
  RECOVER_PASSWORD: {
    name: 'recoverPassword',
    path: '/recoverpassword'
  },
  ACCOUNT_VERIFY: {
    name: 'accountVerifications',
    path: '/account-verifications'
  },
  RECOVER_PASSWORD_APP: {
    name: 'recoverPasswordApp',
    path: '/recover-password-user'
  },
  RECOVER_PASSWORD_STATUS: {
    name: 'recoverPasswordApp',
    path: '/recover-password-status'
  },
  USER_VERIFY: {
    name: 'userVerifications',
    path: '/user-verifications'
  },
  NOT_FOUND: {
    name: 'not found',
    path: '/not-found'
  }
}

export const productDetailItemLink = (name: any): any => {
  return SYSTEM_ADMIN_ROUTE.PRODUCT_DETAIL_ITEM.path.replace(
    '/:name',
    `/${name}`
  )
}

export const productEditItemLink = (name: any): any => {
  return SYSTEM_ADMIN_ROUTE.PRODUCT_DETAIL_EDIT.path.replace(
    '/:name',
    `/${name}`
  )
}

export const wasteDetailRequestLink = (name: any): any => {
  return SYSTEM_ADMIN_ROUTE.WASTE_REQUEST_DETAIL.path.replace(
    '/:name',
    `/${name}`
  )
}

export const customerAccountDetail = (name: any): any => {
  return SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_ITEM.path.replace(
    '/:name',
    `/${name}`
  )
}

export const customerAccountWasteDetail = (name: any): any => {
  return SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_ITEM_WASTE_REQUEST.path.replace(
    '/:name',
    `/${name}`
  )
}

export const customerAccountEdit = (name: any): any => {
  return SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_MANAGER_EDIT.path.replace(
    '/:name',
    `/${name}`
  )
}
export const redeemDetailLink = (name: any): any => {
  return SYSTEM_ADMIN_ROUTE.REDEEM_DETAIL.path.replace('/:name', `/${name}`)
}
