import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  Contact,
  HeaderInfo,
  UserName,
  InfoUser
} from '../../WasteRequest/AddRequest/AddRequestInfo'
import { customerAccountEdit } from '../../../route/RouteDefinition'
import { getUserDetail } from '../../../api'

const InfoUserComponent = ({
  userDetail,
  setUserDetail,
  setIsChangeAddress,
  userIdSelected,
  addressSelected,
  setUserIdSelected
}) => {
  const [details, setDetails] = useState([])
  const [addressExtend, setAddressExtend] = useState([])
  const [objAddress, setObjAddress] = useState({
    address: '',
    postcode: '',
    ward: '',
    province: '',
    district: ''
  })
  const history = useHistory()

  const fetchDetail = async (id) => {
    let data = { id }
    try {
      const userDetail = await getUserDetail(data)
      if (!userDetail.error) {
        setDetails(userDetail.data)
        setAddressExtend(userDetail.data.addressExtend)
      }
    } catch (error) {}
  }

  const handleGetObjAddress = () => {
    const addressDefault = addressExtend.find(
      (item) => item.id == addressSelected
    )

    return setObjAddress({
      ...objAddress,
      address: addressDefault ? addressDefault.address : '',
      postcode: addressDefault
        ? addressDefault.postcode
          ? addressDefault.postcode.name
          : ''
        : '',
      ward: addressDefault
        ? addressDefault.ward
          ? addressDefault.ward.name
          : ''
        : '',
      province: addressDefault
        ? addressDefault.province
          ? addressDefault.province.name
          : ''
        : '',
      district: addressDefault
        ? addressDefault.district
          ? addressDefault.district.name
          : ''
        : ''
    })
  }

  useEffect(() => {
    handleGetObjAddress()
  }, [addressSelected])

  useEffect(() => {
    fetchDetail(userIdSelected)
  }, [userIdSelected])

  return (
    <>
      <InfoUser>
        <HeaderInfo>
          <div>ข้อมูลผู้ใช้</div>
          <img
            src="/images/icon-close.png"
            onClick={() => {
              setUserIdSelected(null)
              setUserDetail({})
            }}
          />
        </HeaderInfo>

        <UserName>
          <div className="name">{`${userDetail.firstname} ${userDetail.lastname}`}</div>
          <div className="pointer">คะแนนที่มี {userDetail.balance || 0}</div>
        </UserName>
        <Contact style={{ flexDirection: 'column', gap: '24px' }}>
          <Row>
            <div>
              <div className="text-thin">อีเมล</div>
              <div>{userDetail.email || '-'}</div>
            </div>
            <div>
              <div className="text-thin">เบอร์โทรศัพท์</div>
              <div>{userDetail.phone || '-'}</div>
            </div>
            <div>
              <div className="text-thin">จังหวัด</div>
              <div>
                {userDetail.province == 0 ? '-' : userDetail.province.name}
              </div>
            </div>
          </Row>
          <div>
            <div className="text-thin">ที่อยู่</div>
            <div style={{ fontSize: '16px' }}>
              {addressSelected === userDetail.addressId
                ? `${userDetail.deliveryAddress} `
                : `${objAddress.address} ${objAddress.ward} ${objAddress.district} ${objAddress.province} ${objAddress.postcode} `}
              {userDetail.addressExtend.length != 0 ? (
                <strong onClick={() => setIsChangeAddress(true)}>
                  เปลี่ยนที่อยู่จัดส่ง
                </strong>
              ) : (
                <strong
                  onClick={() =>
                    history.push({
                      pathname: customerAccountEdit(userIdSelected),
                      state: {
                        id: userIdSelected,
                        dataDetail: details
                      }
                    })
                  }
                >
                  เพิ่มที่อยู่
                </strong>
              )}
            </div>
          </div>
        </Contact>
      </InfoUser>
    </>
  )
}

const Row = styled.div`
  display: flex;
  gap: 88px;
  font-size: 14px;
  .text-thin {
    font-weight: 400;
    font-size: 12px;
    color: #6b7c78;
  }
`

export default InfoUserComponent
