import styled from '@emotion/styled'
import { Box, TableBody } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PaginationSquare from '../../component/PaginationSquare'
import Layout from '../../layouts/DefaultLayout'
import DisplayResult from '../../features/ProductCatalog/ProductList/DisplayResult'
import ProductTopInfo from '../../features/ProductCatalog/ProductList/ProductInfo'
import SearchFilter from '../../features/ProductCatalog/ProductList/SearchFilter'
import TextTitle from '../../features/ProductCatalog/ProductList/TextTitle'
import SelectField from '../../features/ProductCatalog/ProductList/SelectField'
import ProductItem from '../../features/ProductCatalog/ProductList/ProductItem'
import ProductEmpty from '../../features/ProductCatalog/ProductList/ProductEmpty'
import { productDetailItemLink } from '../../route/RouteDefinition'
import { useHistory } from 'react-router-dom'
import { getProductList } from '../../api'
import THead from '../../component/THead'
import TableCell from '@mui/material/TableCell'
import TableBlock from '../../component/TableBlock'
import starImage from '../../asset/images/star.svg'
import useDebounce from '../../hook/useDebounce'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const PAGE_SIZE = 10
export const ProductCatalog = () => {
  const [dataSearch, setDataSearch] = useState<any>({
    start: 1,
    number: PAGE_SIZE
  })
  const [dataList, setDataList] = useState<any>()
  const [total, setTotal] = useState(0)
  const route = useHistory()
  const debouncedSearch = useDebounce(dataSearch, 300)

  const fetchData = async () => {
    try {
      const productList = await getProductList(dataSearch)

      if (!productList.error) {
        setDataList(productList.data)
        setTotal(productList.total)
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [debouncedSearch])
  return (
    <>
      <Layout>
        <ProductTopInfo />
        <SearchFilter dataSearch={dataSearch} setDataSearch={setDataSearch} />
        <SelectField dataSearch={dataSearch} setDataSearch={setDataSearch} />
        {dataList && dataList.length > 0 ? (
          <>
            <Wrapper>
              <DisplayResult total={total} />
              <PaginationSquare
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
                totalPage={Math.ceil(total / PAGE_SIZE)}
              />
            </Wrapper>
            <TableBlock>
              <THead>
                <TableCellStyle>รูปภาพ</TableCellStyle>
                <TableCellStyle className="table-cell">
                  ข้อมูลสินค้า
                </TableCellStyle>
                <TableCellStyle className="table-cell-product">
                  ชนิดสินค้า
                </TableCellStyle>
                <TableCellStyle>มูลค่า</TableCellStyle>
                <TableCellStyle>จำนวน</TableCellStyle>
                <TableCellStyle className="table-cell-name">
                  แบรนด์
                </TableCellStyle>
                <TableCellStyle className="table-cell-status">
                  หมวดหมู่
                </TableCellStyle>
                <TableCellStyle className="table-cell-status">
                  สถานะ
                </TableCellStyle>
              </THead>
              <TableBody>
                {dataList.map((item, index) => (
                  <ProductItem
                    dataItem={item}
                    handleSelect={() =>
                      route.push(productDetailItemLink(item.id))
                    }
                    key={index}
                  />
                ))}
              </TableBody>
            </TableBlock>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '8px'
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <img
                  src={starImage}
                  width={9}
                  height={24}
                  style={{ marginRight: '9px' }}
                />
                <TextTitle />
              </Box>
              <PaginationSquare
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
                totalPage={Math.ceil(total / PAGE_SIZE)}
              />
            </Box>
          </>
        ) : (
          <>
            <DisplayResult total={total} />
            <ProductEmpty type="product" />
          </>
        )}
      </Layout>
    </>
  )
}

export const TableCellStyle = styled(TableCell)`
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 1.6rem !important;
`
