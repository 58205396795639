import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import React, { useEffect, useState } from 'react'
import Multiselect from 'multiselect-react-dropdown'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import { AntSwitch } from '../../../component/AntSwitch'
import AppInput from '../../../component/common/AppInput'
import AppInputLabel from '../../../component/common/AppInputLabel'
import Panel from '../../../component/Panel'
import SelectSimple from '../../../component/SelectSimple'
import { InputBlock } from '../../../page/Login'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/_store'
import { useAppMaster } from '../../../store/_hook'
import { handleType } from '../../../function/handleType'
import moment from 'moment'
import { checkEmptyString } from '../../../utils/theme'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'

type Props = {
  setDataAdd: any
  dataAdd: any
  isCheck?: boolean
  isBefore: any
  setIsBefore: any
  isHourBefore?: any
  setIsHourBefore?: any
  isAllow?: any
  type?: StringConstructor
}
export const handleTime = (startDate, startHours) => {
  const dateObj = new Date(startDate)
  const hourObj = new Date(startHours)

  const month =
    dateObj.getUTCMonth() + 1 > 9
      ? dateObj.getUTCMonth() + 1
      : `0${dateObj.getUTCMonth() + 1}`
  const day =
    dateObj.getUTCDate() > 9 ? dateObj.getUTCDate() : `0${dateObj.getUTCDate()}`

  return (
    dateObj.getUTCFullYear() +
    '/' +
    month +
    '/' +
    day +
    ' ' +
    hourObj.getHours() +
    ':' +
    hourObj.getMinutes()
  )
}

const handleFormat = (date: Date) => {
  const timeObj = new Date(date)

  const day = moment(new Date()).format('YYYY/MM/DD')

  return day + ' ' + timeObj.getHours() + ':' + timeObj.getMinutes()
}

const ProductInfo = ({
  setDataAdd,
  dataAdd,
  isCheck,
  isBefore,
  setIsBefore,
  isHourBefore,
  setIsHourBefore,
  isAllow,
  type
}: Props): JSX.Element => {
  const [startDate, setStartDate] = useState(new Date(dataAdd.startDate))
  const [endDate, setEndDate] = useState(new Date(dataAdd.endDate))
  const [startHours, setStartHours] = useState(new Date(dataAdd.startDate))
  const [endHours, setEndHours] = useState(new Date(dataAdd.endDate))

  const [categoryEdit, setCategoryEdit] = useState(dataAdd.categories)
  const { dataCatagory, dataBrand } = useSelector(
    (state: RootState) => state.selectSlice
  )

  console.log("RENDER-HOUR", endHours)
  console.log("RENDER-DATE", endDate)

  const {
    state: { configMaster }
  } = useAppMaster()

  const handleCheckAdd = () => {

    console.log("Phuong", endHours)
    console.log("Phuong", startHours)
    if (
      moment(moment(dataAdd.startDate).format('YYYY/MM/DD')).isSame(
        moment(dataAdd.endDate).format('YYYY/MM/DD')
      )
    ) {
      setIsBefore(false)
      setIsHourBefore(
        moment(moment(endHours).format('YYYY/MM/DD HH:mm')).isAfter(
          moment(startHours).format('YYYY/MM/DD HH:mm')
        )
      )
    } else {
      setIsHourBefore(true)
      setIsBefore(
        moment(moment(endDate).format('YYYY/MM/DD')).isBefore(
          moment(startDate).format('YYYY/MM/DD')
        )
      )
    }
  }

  useEffect(() => {
    handleCheckAdd()
  }, [endHours, startHours, startDate, endDate])

  return (
    <>
      <Panel title="หัวข้อ (1/4)  : ข้อมูลสินค้า">
        <Box sx={{ fontSize: '1.2rem' }}>
          สถานะการใช้งาน
          <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={(e) =>
                setDataAdd({
                  ...dataAdd,
                  status: e.target.checked ? 'active' : 'inactive'
                })
              }
              defaultChecked={dataAdd.status === 'active'}
            />
            <Status>
              {dataAdd.status === 'active' ? 'เปิดใช้งาน' : 'ปิดการใช้งาน'}
            </Status>
          </Stack>
        </Box>

        {/* Product Name */}
        <Box
          sx={{
            marginTop: '40px',
            color: '#2C2C2C',
            width: '746px'
          }}
        >
          <AppInputLabel>ชื่อสินค้า</AppInputLabel>
          <AppInput
            fullWidth
            type="text"
            placeholder="ใส่ชื่อสินค้า"
            errors={isCheck && checkEmptyString(dataAdd.name)}
            sx={{
              fontSize: '16px'
            }}
            value={dataAdd.name || ''}
            onChange={(e) => {
              e.target.value.length <= 80 &&
                setDataAdd({ ...dataAdd, name: e.target.value })
            }}
          />
          <AppInputLabel
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#6B7C78',
              paddingTop: '4px'
            }}
          >
            {isCheck && checkEmptyString(dataAdd.name) ? (
              <Required>จำเป็นต้องระบุ</Required>
            ) : (
              <div>สูงสุด 80 ตัวอักษร</div>
            )}

            <div>({dataAdd.name.length}/80)</div>
          </AppInputLabel>
        </Box>

        {/* Product Type */}
        <Box
          sx={{
            marginTop: '46px',
            color: '#2C2C2C'
          }}
        >
          <AppInputLabel>ชนิดสินค้า</AppInputLabel>

          <SelectSimple
            width={428}
            options={Object.values(configMaster.productType)}
            handleSelect={(value) =>
              setDataAdd({ ...dataAdd, productType: value })
            }
            type="value"
            placeholder="เลือกชนิดสินค้า"
            value={dataAdd.productType.name || dataAdd.productType}
            error={isCheck && dataAdd.productType === ''}
          />
          {isCheck && dataAdd.productType === '' && (
            <Required>จำเป็นต้องระบุ</Required>
          )}
        </Box>

        {/* Brand Type */}
        <Inventory>
          <Box
            sx={{
              marginTop: '46px',
              color: '#2C2C2C',
              marginBottom: '20px'
            }}
          >
            <AppInputLabel>แบรนด์</AppInputLabel>

            <SelectSimple
              width={428}
              options={Object.values(dataBrand)}
              handleSelect={(value) =>
                setDataAdd({ ...dataAdd, brandId: value == 1 ? '0' : value })
              }
              type="id"
              placeholder="เลือกแบรนด์"
              value={
                dataAdd.brandId.name ||
                (dataAdd.brandId == '0' ? 'อื่นๆ' : dataAdd.brandId) ||
                ''
              }
              error={isCheck && dataAdd.brandId === ''}
            />
            {isCheck && dataAdd.brandId === '' && (
              <Required>จำเป็นต้องระบุ</Required>
            )}
          </Box>
          {dataAdd.brandId == '0' && (
            <Box
              sx={{
                marginTop: '46px',
                color: '#2C2C2C'
              }}
            >
              <AppInputLabel>ชื่อแบรนด์อื่นๆ</AppInputLabel>

              <AppInput
                fullWidth
                type="text"
                placeholder="ใส่ชื่อแบรนด์"
                errors={isCheck && checkEmptyString(dataAdd.brandName)}
                sx={{
                  fontSize: '16px'
                }}
                value={dataAdd.brandName || ''}
                onChange={(e) => {
                  e.target.value.length <= 50 &&
                    setDataAdd({ ...dataAdd, brandName: e.target.value })
                }}
              />
              <AppInputLabel
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: '#6B7C78',
                  paddingTop: '4px'
                }}
              >
                {isCheck && checkEmptyString(dataAdd.brandName) ? (
                  <Required>จำเป็นต้องระบุ</Required>
                ) : (
                  <div>สูงสุด 50 ตัวอักษร</div>
                )}

                <div>(0/50)</div>
              </AppInputLabel>
            </Box>
          )}
        </Inventory>

        {/* Categories Type */}
        <Box
          sx={{
            marginTop: '46px',
            color: '#2C2C2C'
          }}
        >
          <AppInputLabel>หมวดหมู่สินค้า</AppInputLabel>
          <MultiselectStyle
            selectedValues={type && categoryEdit}
            displayValue="name"
            onKeyPressFn={function noRefCheck() {}}
            onRemove={(item) => {
              let arrCatagory = []
              setCategoryEdit(item)
              item.map((item) => arrCatagory.push(item.id))
              setDataAdd({ ...dataAdd, categories: item })
            }}
            onSearch={function noRefCheck() {}}
            onSelect={(item) => {
              let arrCatagory = []
              setCategoryEdit(item)
              item.map((item) => arrCatagory.push(item.id))
              setDataAdd({ ...dataAdd, categories: arrCatagory })
            }}
            placeholder="เลือกหมวดหมู่"
            options={Object.values(dataCatagory)}
            showCheckbox
            errors={isCheck && dataAdd.categories.length === 0}
          />
          {isCheck && dataAdd.categories.length === 0 && (
            <Required>จำเป็นต้องระบุ</Required>
          )}
        </Box>

        {/* Value */}
        <Box
          sx={{
            marginTop: '46px',
            color: '#2C2C2C'
          }}
        >
          <AppInputLabel>มูลค่า</AppInputLabel>

          <InputBlock
            style={{
              width: '342px',
              height: '48px',
              border: `1px solid ${
                isCheck && dataAdd.value === '' ? '#fd4a1e' : '#CCDDD9'
              }`
            }}
          >
            <AppInput
              fullWidth
              type="number"
              placeholder="0"
              onChange={(e) =>
                setDataAdd({ ...dataAdd, value: e.target.value })
              }
              onKeyDown={(e) => handleType(e)}
              sx={{
                border: 'none',
                background: 'transparent'
              }}
              value={dataAdd.value || ''}
              errors={isCheck && Number(dataAdd.value) === 0}
            />
            <div className="type">คะแนน</div>
          </InputBlock>
          {isCheck && Number(dataAdd.value) === 0 && (
            <Required style={{ position: 'absolute' }}>จำเป็นต้องระบุ</Required>
          )}
        </Box>

        {/* Inventory Type */}
        <Inventory>
          <Box
            sx={{
              marginTop: '46px',
              color: '#2C2C2C'
            }}
          >
            <AppInputLabel>คลังสินค้า</AppInputLabel>

            <SelectSimple
              width={428}
              options={Object.values(configMaster.inventory)}
              handleSelect={(value) =>
                setDataAdd({ ...dataAdd, inventory: value })
              }
              type="value"
              placeholder="เลือกสถานที่คลังสินค้า"
              value={dataAdd.inventory.name || dataAdd.inventory || ''}
              error={isCheck && dataAdd.inventory === ''}
            />

            {isCheck && dataAdd.inventory === '' && (
              <Required style={{ position: 'absolute' }}>
                จำเป็นต้องระบุ
              </Required>
            )}
          </Box>

          {/* Quantity */}
          <Box
            sx={{
              marginTop: '46px',
              color: '#2C2C2C'
            }}
          >
            <AppInputLabel>จำนวนสินค้า</AppInputLabel>
            <InputBlock
              style={{
                width: '342px',
                height: '48px',
                border: `1px solid ${
                  isCheck && dataAdd.quantity === '' ? '#fd4a1e' : '#CCDDD9'
                }`
              }}
            >
              <AppInput
                fullWidth
                type="number"
                placeholder="0"
                onChange={(e) =>
                  setDataAdd({ ...dataAdd, quantity: e.target.value })
                }
                onKeyDown={(e) => handleType(e)}
                sx={{
                  border: 'none',
                  background: 'transparent'
                }}
                value={dataAdd.quantity}
                errors={isCheck && dataAdd.quantity === ''}
              />
              <div className="type">ชิ้น</div>
            </InputBlock>

            {isCheck && dataAdd.quantity === '' && (
              <Required style={{ position: 'absolute' }}>
                จำเป็นต้องระบุ
              </Required>
            )}
          </Box>
        </Inventory>

        <Validity>
          <BoldText>ช่วงเวลาที่เเสดงสินค้าหน้าเเอพ</BoldText>

          <ValidityBlock>
            <Time isCl>
              <Box>
                <AppInputLabel> แสดงตั้งเเต่</AppInputLabel>
                <TimeBlock>
                  <DatePickerDay
                    selected={type ? new Date(dataAdd.startDate) : startDate}
                    onChange={(date: Date) => {
                      setStartDate(date)
                      setDataAdd({
                        ...dataAdd,
                        startDate: handleTime(date, startHours)
                      })
                    }}
                    placeholderText="เลือกวันที่"
                    wrapperClassName="wrapperClassName"
                    minDate={moment().toDate()}
                  />
                  <img src="/images/calendar.png" className="icon-calendar" />
                </TimeBlock>
              </Box>

              <Box>
                <AppInputLabel> เวลา</AppInputLabel>
                <TimeBlock>
                  <DatePickerHour
                    selected={type ? new Date(dataAdd.startDate) : startHours}
                    onChange={(date) => {
                      setStartHours(date)
                      setDataAdd({
                        ...dataAdd,
                        startDate: handleTime(startDate, date)
                      })
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm"
                    placeholderText="00:00"
                  />
                  <img src="/images/clock.png" className="icon-clock" />
                </TimeBlock>
              </Box>
            </Time>
            <Time isBefore={isBefore}>
              <Box>
                <AppInputLabel>แสดงจนถึง</AppInputLabel>
                <TimeBlock>
                  <DatePickerDay
                    selected={type ? new Date(dataAdd.endDate) : endDate}
                    onChange={(date: Date) => {
                      setEndDate(date)
                      setDataAdd({
                        ...dataAdd,
                        endDate: handleTime(date, endHours)
                      })
                    }}
                    placeholderText="เลือกวันที่"
                    minDate={moment().toDate()}
                  />
                  <img src="/images/calendar.png" className="icon-calendar" />
                </TimeBlock>
                {isBefore && <Required>วันที่สิ้นสุดไม่ถูกต้อง</Required>}
              </Box>

              <Box>
                <AppInputLabel> เวลา</AppInputLabel>
                <TimeBlockEnd
                  isHourBefore={isHourBefore}
                  type={type}
                  isAllow={isAllow}
                >
                  <DatePickerHour
                    selected={type ? new Date(dataAdd.endDate) : endHours}
                    onChange={(date) => {
                      setEndHours(date)
                      setDataAdd({
                        ...dataAdd,
                        endDate: handleTime(endDate, date)
                      })
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm"
                    placeholderText="00:00"
                  />
                  <img src="/images/clock.png" className="icon-clock" />
                </TimeBlockEnd>
                {isAllow && !isHourBefore && (
                  <Required>กรุณาตั้งค่าเวลาสิ้นสุดในการแสดงสินค้า</Required>
                )}
              </Box>
            </Time>
          </ValidityBlock>
        </Validity>
      </Panel>
    </>
  )
}

export default ProductInfo

const MultiselectStyle = styled(Multiselect)`
  border: 1px solid ${({ errors }) => (errors ? '#FD4A1E' : '#CCDDD9')};
  border-radius: 4px;
  width: 359px;
  height: 48px;
  padding: 0px 4px;
  position: relative;

  .search-wrapper {
    border: none;
  }

  span {
    position: absolute;
    justify-content: space-between;
    background: #ccddd9;
    color: #03030c;
    font-weight: 700;
    font-size: 16px;
    width: 138px;
    border-radius: 8px;
  }

  span:nth-child(1) {
    right: -155px;
    top: 1px;
  }

  span:nth-child(2) {
    top: -1px;
    right: -300px;
  }
  span:nth-child(3) {
    right: -445px;
    top: -1px;
  }
  span:nth-child(4) {
    left: 796px;
    top: -1px;
  }

  span:nth-child(5) {
    left: 941px;
    top: -1px;
  }

  span:nth-child(6) {
    right: -155px;
    top: 32px;
  }
  span:nth-child(7) {
    right: -300px;
    top: 32px;
  }

  span:nth-child(8) {
    right: -445px;
    top: 32px;
  }
  span:nth-child(8) {
    left: 796px;
    top: 32px;
  }
`

const MessageLabel = styled.span`
  color: #fd4a1e;
  font-size: 12px;
`

const Inventory = styled.div`
  display: flex;
  align-items: end;
  gap: 48px;

  .input-quantity {
    margin-left: 57px;
  }
`

const Validity = styled.div`
  margin-top: 80px;
`

const BoldText = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #00a796;
  margin-bottom: 32px;
`

export const Time = styled.div`
  display: flex;
  gap: 16px;
  input {
    padding: 12px 16px;
    border: ${({ isBefore }) =>
      isBefore ? '1px solid red' : '1px solid #ccddd9'};
    border-radius: 4px;
    font-size: 16px;
    color: #6b7c78;
  }
`

const ValidityBlock = styled.div`
  display: flex;
  gap: 72px;
`

const DatePickerHour = styled(DatePicker)`
  width: 120px;
  margin-right: 16px;
`

const DatePickerDay = styled(DatePicker)`
  width: 268px;
`

const TimeBlock = styled.label`
  position: relative;

  .icon-calendar {
    position: absolute;
    z-index: 2;
    right: 8px;
    top: 14px;
  }

  .icon-clock {
    position: absolute;
    z-index: 2;
    right: 28px;
    top: 14px;
  }
`

const TimeBlockEnd = styled.label`
  position: relative;

  .icon-calendar {
    position: absolute;
    z-index: 2;
    right: 8px;
    top: 14px;
  }

  .icon-clock {
    position: absolute;
    z-index: 2;
    right: 28px;
    top: 14px;
  }

  input {
    padding: 12px 16px;
    border: ${({ isHourBefore, isAllow }) =>
      isAllow
        ? !isHourBefore
          ? '1px solid red'
          : '1px solid #ccddd9'
        : '1px solid #ccddd9'};
    border-radius: 4px;
    font-size: 16px;
    color: #6b7c78;
  }
`

export const Required = styled.div`
  color: #fd4a1e;
  font-size: 12px;
`

const Status = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #2c2c2c;
`
