import React from 'react'
import styled from 'styled-components'

export const RadioItem = ({ item }) => {
  return (
    <>
      <div>
        <Title>
          {item.firstname} {item.lastname} | {item.phone}
        </Title>
        <div>
          {`${item.address} ${item.ward.name} ${item.district.name} ${item.province.name} ${item.postcode.name}`}
        </div>
      </div>
    </>
  )
}

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #000000;
`
