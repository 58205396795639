import Box from '@mui/material/Box'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Panel from '../../../component/Panel'
import {
  ImageSearch,
  InputSearch,
  Wrapper
} from '../../ProductCatalog/ProductList/SearchFilter'
import {
  Contact,
  HeaderInfo,
  InfoUser,
  UserName
} from '../../WasteRequest/AddRequest/AddRequestInfo'
import { Title } from '../../ProductCatalog/AddNewProduct/ProductImages/ImageItem'
import searchIcon from '../../../asset/images/Vector2.svg'
import { ModalAddress } from '../ModalAddress'
import { searchUserTransRedeem, searchDetailUser } from '../../../api'
import { getProductRedeem } from '../../../api'
import { UserTransRedeem } from './UserTransRedeem'
import InfoUserComponent from './InfoUser'

export const AddUserInfo = ({ setDataAdd, dataAdd, error }) => {
  const [isChangeAddress, setIsChangeAddress] = useState(false)
  const [keySearchUser, setKeywordUser] = useState('')
  const [dataListUser, setDataListUser] = useState<any>([])
  const [showuserList, setShowUserList] = useState(false)
  const [userIdSelected, setUserIdSelected] = useState<any>(null)
  const [userDetail, setUserDetail] = useState({})
  const [addressSelected, setAddressSelected] = useState()

  const fetchUserTrans = async () => {
    const res = await searchUserTransRedeem({ keyword: keySearchUser })
    if (!res.error) {
      setDataListUser(res.data)
    }
  }

  const fetchDetailUser = async (id: any) => {
    const res = await searchDetailUser({ id })

    if (!res.error) {
      setUserDetail(res.data)
      setAddressSelected(res.data.addressId)
    }
  }

  useEffect(() => {
    fetchUserTrans()
  }, [keySearchUser])

  useEffect(() => {
    setDataAdd({
      ...dataAdd,
      addressId: addressSelected ? Number(addressSelected) : null
    })
  }, [addressSelected])

  useEffect(() => {
    setDataAdd({
      ...dataAdd,
      senderId: userIdSelected ? userIdSelected : null
    })
  }, [userIdSelected])

  return (
    <>
      <Panel title="หัวข้อ (1/2)  : ข้อมูลผู้ใช้   ">
        <Box>
          <Title>ข้อมูลผู้ใช้</Title>
          <Wrapper style={{ margin: '33px 0px 0px 24px' }}>
            <InputSearch
              placeholder="ค้นหาผู้ใช้"
              onClick={() => {
                setShowUserList(!showuserList), fetchUserTrans()
              }}
              onChange={(e) => setKeywordUser(e.target.value)}
              error={error && !dataAdd.senderId}
            />
            <ImageSearch src={searchIcon} />
            {showuserList && (
              <UserTransRedeem
                dataListUser={dataListUser}
                setUserIdSelected={setUserIdSelected}
                setShowUserList={setShowUserList}
                fetchDetailUser={fetchDetailUser}
                // setAddressSelected={setAddressSelected}
              />
            )}
          </Wrapper>
          <div
            style={{
              fontSize: '12px',
              marginLeft: '24px',
              color: error && !dataAdd.senderId ? 'red' : '#6B7C78'
            }}
          >
            {error && !dataAdd.senderId
              ? 'จำเป็นต้องเลือก'
              : ' ค้นหาด้วย ชื่อ อีเมล หรือ เบอร์โทรศัพท์ '}
          </div>
        </Box>

        {Object.keys(userDetail).length != 0 ? (
          <InfoUserComponent
            userDetail={userDetail}
            userIdSelected={userIdSelected}
            setUserDetail={setUserDetail}
            setIsChangeAddress={setIsChangeAddress}
            addressSelected={addressSelected}
            setUserIdSelected={setUserIdSelected}
          />
        ) : (
          ''
        )}
        <ModalAddress
          onDimiss={() => setIsChangeAddress(false)}
          open={isChangeAddress}
          userIdSelected={userIdSelected}
          addressSelected={addressSelected}
          setAddressSelected={setAddressSelected}
          userDetail={userDetail}
        />
      </Panel>
    </>
  )
}
