import { InputBase, InputBaseProps, Theme } from '@mui/material'
import React, { forwardRef } from 'react'

interface AppInputProps extends Omit<InputBaseProps, 'errors'> {
  errors?: string | boolean
  iconRight?: React.ReactElement
}

function AppInput(
  { sx, fullWidth, errors, iconRight, ...props }: AppInputProps,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  return (
    <InputBase
      ref={ref}
      fullWidth={fullWidth}
      sx={{
        border: (theme: Theme) =>
          `1px solid ` + (errors ? '#FD4A1E' : theme.color.gray3),
        borderRadius: '4px',
        fontWeight: 400,
        background: (theme: Theme) => theme.color.white,
        '& .MuiInputBase-input': {
          position: 'relative',

          padding: '12px 16px',
          fontSize: '16px !important',
          height: '24px',

          '&:focus': {},
          '&::placeholder': {
            color: (theme: Theme) => theme.color.gray7,
            opacity: 1,
            fontWeight: 400
          }
        },
        ...sx
      }}
      {...props}
    />
  )
}

export default forwardRef(AppInput)
