import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import React, { useState } from 'react'
import SelectSimple from '../../../component/SelectSimple'
import { useAppMaster } from '../../../store/_hook'
import { TimeBlock, Line } from '../../WasteRequest/FilterWaste'
import { getAllProvince } from '../../../api'
import { PAGE_SIZE } from '../../../page/ProductCatalog'
import { handleTime } from '../../ProductCatalog/AddNewProduct/ProductInfo'
import { handleTimeDate } from '../../WasteRequest/FilterWaste'
import moment from 'moment'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 8px;
  padding-top: 16px;
`

export const Label = styled.label`
  display: flex;
  .calender-icon {
    cursor: pointer;
  }
`

const AccountFillter = ({
  dataSearch,
  setDataSearch,
  provinceList,
  setProvinceList
}) => {
  const formatProviceList = provinceList.map((item) => {
    return {
      value: item.id,
      name: item.name,
      id: item.id
    }
  })

  const listAccountType = [
    {
      id: 1000086,
      name: 'บุคคลทั่วไป',
      value: 0
    },
    {
      id: 1000090,
      name: 'องค์กร',
      value: 4
    }
  ]

  const getNameAccountType = (id: any) => {
    const item = listAccountType.find((item) => item.value == id)
    return item ? item.name : ''
  }

  const handleGetNameProvince = (id: any) => {
    const item = formatProviceList.find((item) => item.id == id)
    return item ? item.name : ''
  }

  return (
    <>
      <Wrapper>
        <TimeBlock>
          <Label>
            <img
              src="/images/calendar.png"
              alt="calendar"
              className="calender-icon"
            />
            <DatePicker
              selected={
                dataSearch.startDate ? new Date(dataSearch.startDate) : null
              }
              onChange={(date) => {
                setDataSearch({
                  ...dataSearch,
                  startDate: handleTimeDate(date),
                  start: 1
                })
              }}
              maxDate={moment().toDate()}
              placeholderText="เลือกวันที่"
            />
          </Label>
          <Line />
          <Label>
            <img
              src="/images/calendar.png"
              alt="calendar"
              className="calender-icon"
            />
            <DatePicker
              selected={
                dataSearch.endDate ? new Date(dataSearch.endDate) : null
              }
              onChange={(date: Date) => {
                setDataSearch({
                  ...dataSearch,
                  endDate: handleTimeDate(date) + ' 23:59:59',
                  start: 1
                })
              }}
              maxDate={moment().toDate()}
              placeholderText="เลือกวันที่"
            />
          </Label>
        </TimeBlock>

        {/* Account Type */}
        <SelectSimple
          options={listAccountType}
          handleSelect={(value) => {
            setDataSearch({ ...dataSearch, groupId: value, start: 1 })
          }}
          type="value"
          placeholder={`ประเภทบัญชี`}
          value={getNameAccountType(dataSearch.groupId)}
        />

        {/* Location */}
        <SelectSimple
          options={formatProviceList}
          handleSelect={(value) => {
            setDataSearch({ ...dataSearch, province: value, start: 1 })
          }}
          type="value"
          placeholder={`จังหวัด`}
          value={handleGetNameProvince(dataSearch.province)}
        />

        {/* Status */}
        <SelectSimple
          options={[
            {
              value: 'active',
              id: 1000095,
              name: 'เปิดใช้งาน'
            },
            {
              value: 'inactive',
              id: 1000098,
              name: 'ปิดใช้งาน'
            }
          ]}
          handleSelect={(value) => {
            setDataSearch({ ...dataSearch, status: value, start: 1 })
          }}
          type="value"
          placeholder={`สถานะ`}
          value={dataSearch.status || ''}
        />
      </Wrapper>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          sx={{
            paddingTop: '8px',
            paddingBottom: '32px',
            fontWeight: 700,
            fontSize: '1.4rem',
            textDecoration: 'underline',
            cursor: 'pointer',
            width: '66px'
          }}
          style={{ color: '#6B7C78' }}
          onClick={() => setDataSearch({ start: 1, number: PAGE_SIZE })}
        >
          ล้างข้อมูล
        </Typography>
      </div>
    </>
  )
}

export default AccountFillter
