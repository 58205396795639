import Box from '@mui/material/Box'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Panel from '../../../component/Panel'
import { Title } from '../../ProductCatalog/AddNewProduct/ProductImages/ImageItem'
import {
  ImageSearch,
  InputSearch,
  Wrapper
} from '../../ProductCatalog/ProductList/SearchFilter'
import searchIcon from '../../../asset/images/Vector2.svg'
import AppInputLabel from '../../../component/common/AppInputLabel'
import SelectSimple from '../../../component/SelectSimple'
import AppInput from '../../../component/common/AppInput'
import { InputBlock } from '../../../page/Login'
import { UserTrans } from './UserTrans'
import {
  deliveryList,
  detailUserTransaction,
  provinceList,
  searchUserTrans
} from '../../../api'
import { handleType } from '../../../function/handleType'
import { Required } from '../../ProductCatalog/AddNewProduct/ProductInfo'

export const AddRequestInfo = ({ setDataAdd, dataAdd, error }) => {
  const [showuserList, setShowUserList] = useState(false)
  const [dataListUser, setDataListUser] = useState<any>([])
  const [keySearchUser, setKeywordUser] = useState('')
  const [userSelected, setUserSelected] = useState<any>()
  const [userIdSelected, setUserIdSelected] = useState<any>()
  const [dataDeliveryList, setDataDeliveryList] = useState<any>([])
  const [dataProvinceList, setDataProvinceList] = useState<any>([])
  const [isOther, setIsOther] = useState(false)

  const fetchUserTrans = async () => {
    const res = await searchUserTrans({ keyword: keySearchUser })
    if (!res.error) {
      setDataListUser(res.data)
    }
  }

  const fetchDelivery = async () => {
    const resDelivery = await deliveryList()
    const resProvince = await provinceList()
    if (!resDelivery.error && !resProvince.error) {
      setDataDeliveryList(resDelivery.data)
      setDataProvinceList(resProvince.data)
    }
  }

  const fetchDataUser = async () => {
    const res = await detailUserTransaction({ id: userIdSelected })
    if (!res.error) {
      setUserSelected(res.data)
    }
  }

  useEffect(() => {
    fetchUserTrans()
  }, [keySearchUser])

  useEffect(() => {
    fetchDataUser()
  }, [userIdSelected])

  useEffect(() => {
    fetchDelivery()
  }, [])

  useEffect(() => {
    setDataAdd({ ...dataAdd, senderId: userSelected ? userSelected.id : null })
  }, [userSelected])

  const handleDelivery = (delivery) => {
    const result = dataDeliveryList.filter(
      (item) => item.id === Number(delivery)
    )

    return result[0].name
  }

  const handleProvince = (province) => {
    const result = dataProvinceList.filter(
      (item) => item.id === Number(province)
    )

    return result[0].name
  }

  const handleCheckDelivery = (delivery) => {
    const result = dataDeliveryList.find((item) => item.id === Number(delivery))
    if (result.value === 'other') {
      setIsOther(true)
    } else {
      setIsOther(false)
    }
  }
  return (
    <>
      <Panel title="หัวข้อ (1/2)  : ข้อมูลการส่งขยะ   ">
        {/* Search user*/}
        <Box>
          <Title>ข้อมูลผู้ใช้</Title>
          <Wrapper style={{ margin: '33px 0px 0px 24px' }}>
            <InputSearch
              placeholder="ค้นหาผู้ใช้"
              onClick={() => {
                setShowUserList(!showuserList), fetchUserTrans()
              }}
              onChange={(e) => setKeywordUser(e.target.value)}
              error={error && !dataAdd.senderId}
              // disabled={userSelected}
            />
            <ImageSearch src={searchIcon} />
            {showuserList && (
              <UserTrans
                dataListUser={dataListUser}
                setUserIdSelected={setUserIdSelected}
                setShowUserList={setShowUserList}
              />
            )}
          </Wrapper>
          <div
            style={{
              fontSize: '12px',
              marginLeft: '24px',
              color: error && !dataAdd.senderId ? 'red' : '#6B7C78'
            }}
          >
            {error && !dataAdd.senderId
              ? 'จำเป็นต้องเลือก'
              : ' ค้นหาด้วย ชื่อ อีเมล หรือ เบอร์โทรศัพท์ '}
          </div>

          {userSelected && (
            <InfoUser>
              <HeaderInfo>
                <div>ข้อมูลผู้ใช้</div>
                <img
                  src="/images/icon-close.png"
                  onClick={() => {
                    setUserIdSelected(null), setUserSelected(null)
                  }}
                />
              </HeaderInfo>

              <UserName>
                <div className="name">
                  {userSelected.firstname + ' ' + userSelected.lastname}
                </div>
                <div className="pointer">
                  คะแนนที่มี {userSelected.balance || '0'}
                </div>
              </UserName>

              <Contact>
                <div>
                  <div>
                    <div>อีเมล</div>
                    <div className="text-thin">{userSelected.email || '-'}</div>
                  </div>

                  <Row>
                    <div>
                      <div>อายุ</div>
                      <div className="text-thin">
                        {userSelected.age || '-'} ปี
                      </div>
                    </div>
                    <div>
                      <div>เพศ</div>
                      <div className="text-thin">
                        {userSelected.gender.name || '-'}
                      </div>
                    </div>
                  </Row>
                </div>

                <div>
                  <div>เบอร์โทรศัพท์</div>
                  <div className="text-thin">{userSelected.phone}</div>

                  <div style={{ marginTop: '16px' }}>จังหวัด</div>
                  <div className="text-thin">
                    {userSelected.province !== 0
                      ? userSelected.province.name
                      : '-'}
                  </div>
                </div>
              </Contact>
            </InfoUser>
          )}
        </Box>

        {/* Delievery detail ( not required) */}
        <Box style={{ marginTop: '32px' }}>
          <Title>ข้อมูลพัสดุ (ไม่จำเป็นต้องระบุ)</Title>
          <Wrapper style={{ margin: '0px 24px' }}>
            {/* Delivery company */}
            <InfoInput>
              <Box
                sx={{
                  marginTop: '24px',
                  color: '#2C2C2C'
                }}
              >
                <AppInputLabel>บริษัทขนส่ง</AppInputLabel>

                <SelectSimple
                  width={428}
                  options={dataDeliveryList}
                  handleSelect={(value) => {
                    setDataAdd({ ...dataAdd, deliveryId: Number(value) }),
                      handleCheckDelivery(value)
                  }}
                  type="name"
                  placeholder="เลือกบริษัทขนส่ง"
                  value={
                    dataAdd.deliveryId ? handleDelivery(dataAdd.deliveryId) : ''
                  }
                  waste={true}
                />
              </Box>
              {/* Tracking no. */}
              <Box
                sx={{
                  marginTop: '24px',
                  color: '#2C2C2C'
                }}
              >
                <AppInputLabel>Tracking no.</AppInputLabel>
                <AppInput
                  type="text"
                  placeholder="ใส่ Tracking no."
                  onChange={(e) =>
                    setDataAdd({ ...dataAdd, trackingno: e.target.value })
                  }
                  sx={{
                    border: '1px solid #ccddd9',
                    background: 'transparent',
                    width: '428px',
                    fontWeight: '700'
                  }}
                />
              </Box>
            </InfoInput>

            {isOther && (
              <InfoInput>
                <Box
                  sx={{
                    width: '428px',
                    marginTop: '30px',
                    color: '#2C2C2C'
                  }}
                >
                  <AppInputLabel>จัดส่งอื่นๆ</AppInputLabel>
                  <AppInput
                    fullWidth
                    placeholder="จัดส่งอื่นๆ"
                    onChange={(e) =>
                      setDataAdd({
                        ...dataAdd,
                        deliveryDescription: e.target.value
                      })
                    }
                  />
                </Box>
              </InfoInput>
            )}

            {/* location */}
            <InfoInput>
              <Box
                sx={{
                  marginTop: '30px',
                  color: '#2C2C2C'
                }}
              >
                <AppInputLabel>จังหวัด</AppInputLabel>

                <SelectSimple
                  width={428}
                  options={dataProvinceList}
                  handleSelect={(value) => {
                    setDataAdd({ ...dataAdd, provinceId: Number(value) })
                  }}
                  type="country"
                  placeholder="เลือกจังหวัด"
                  value={
                    dataAdd.provinceId ? handleProvince(dataAdd.provinceId) : ''
                  }
                  waste={true}
                />
              </Box>

              <Box
                sx={{
                  marginTop: '30px',
                  color: '#2C2C2C'
                }}
              >
                <AppInputLabel>น้ำหนักพัสดุ </AppInputLabel>
                <InputBlock
                  style={{
                    width: '428px',
                    height: '48px'
                  }}
                >
                  <AppInput
                    fullWidth
                    type="number"
                    placeholder="ระบุน้ำหนัก"
                    onChange={(e) =>
                      setDataAdd({
                        ...dataAdd,
                        parcelweight: Number(e.target.value)
                      })
                    }
                    onKeyDown={(e) => handleType(e)}
                    sx={{
                      border: 'none',
                      background: 'transparent',
                      fontWeight: '700'
                    }}
                  />
                  <div className="type">กก.</div>
                </InputBlock>
              </Box>
            </InfoInput>
          </Wrapper>
        </Box>
      </Panel>
    </>
  )
}

const InfoInput = styled.div`
  display: flex;
  gap: 32px;
`

export const InfoUser = styled.div`
  background: #eff1f4;
  border-radius: 4px;
  padding: 16px 32px;
  margin-left: 24px;
  margin-top: 18px;
`

export const HeaderInfo = styled.div`
  font-weight: 700;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    cursor: pointer;
  }
`

export const UserName = styled.div`
  display: flex;
  gap: 16px;
  margin: 16px 0px;

  .name {
    font-weight: 700;
    font-size: 14px;
    color: #2c2c2c;
  }

  .pointer {
    background: #00a796;
    border-radius: 16px;
    color: #ffffff;
    padding: 0px 8px;
  }
`

export const Contact = styled.div`
  font-weight: 400;
  font-size: 12px;
  display: flex;
  gap: 88px;
  margin-left: 24px;

  .text-thin {
    color: #6b7c78;
    font-size: 14px;
  }

  strong {
    color: #00a796;
    text-decoration-line: underline;
    cursor: pointer;
  }
`

export const Row = styled.div`
  display: flex;
  gap: 36px;
  margin-top: 16px;
`
