import styled from '@emotion/styled'

import { Typography } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getSystemAdminPath } from '../../../../route/RouteConfig/PrivateRoute'
import { SYSTEM_ADMIN_ROUTE } from '../../../../route/RouteDefinition'
import addIcon from '../../../../asset/images/Vector1.svg'
import { AppButtonStyle } from '../../ProductDetail'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Title = styled.span`
  color: #fff;
  font-size: 1.6rem;
  padding-left: 5px;
`

const ProductTopInfo = () => {
  const history = useHistory()
  const handleClick = () => {
    history.push(getSystemAdminPath(SYSTEM_ADMIN_ROUTE.ADD_NEW_PRODUCT.path))
  }
  return (
    <Wrapper>
      <Typography
        sx={{
          fontSize: '3.2rem',
          fontWeight: 'bold'
        }}
      >
        รายการสินค้า
      </Typography>
      <AppButtonStyle sx={{ width: '200px', mt: '32px' }} onClick={handleClick}>
        <img src={addIcon} />
        <Title>เพิ่มสินค้าใหม่</Title>
      </AppButtonStyle>
    </Wrapper>
  )
}

export default ProductTopInfo
