import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import React, { useState } from 'react'
import SelectSimple from '../../component/SelectSimple'
import { handleTimeDate, Line, TimeBlock } from '../WasteRequest/FilterWaste'
import { useAppMaster } from '../../store/_hook'
import { handleTime } from '../ProductCatalog/AddNewProduct/ProductInfo'
import { RootState } from '../../store/_store'
import { useSelector } from 'react-redux'
import { PAGE_SIZE } from '../../page/ProductCatalog'
import moment from 'moment'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0 8px;
  padding-top: 16px;
`

export const Label = styled.label`
  display: flex;
  .calender-icon {
    cursor: pointer;
  }
`

const RedeemFillter = ({ dataSearch, setDataSearch }) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const {
    state: { configMaster }
  } = useAppMaster()

  const { dataCatagory, dataBrand } = useSelector(
    (state: RootState) => state.selectSlice
  )

  return (
    <>
      <Wrapper>
        <TimeBlock>
          <Label>
            <img
              src="/images/calendar.png"
              alt="calendar"
              className="calender-icon"
            />
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date)
                setDataSearch({
                  ...dataSearch,
                  start: 1,
                  startDate: handleTimeDate(date)
                })
              }}
              placeholderText="เริ่มวันที่"
              maxDate={moment().toDate()}
            />
          </Label>
          <Line />
          <Label>
            <img
              src="/images/calendar.png"
              alt="calendar"
              className="calender-icon"
            />
            <DatePicker
              selected={endDate}
              onChange={(date: Date) => {
                setEndDate(date)
                setDataSearch({
                  ...dataSearch,
                  start: 1,
                  endDate: handleTimeDate(date) + ' 23:59:59'
                })
              }}
              placeholderText="ถึงวันที่"
              maxDate={moment().toDate()}
            />
          </Label>
        </TimeBlock>

        {/* Product Type */}
        <SelectSimple
          options={Object.values(configMaster.productType)}
          handleSelect={(value) =>
            setDataSearch({ ...dataSearch, productType: value, start: 1 })
          }
          type="value"
          placeholder={`ชนิดสินค้า`}
          value={dataSearch.productType || ''}
        />

        {/* Brand */}
        <SelectSimple
          options={Object.values(dataBrand)}
          handleSelect={(value) =>
            setDataSearch({ ...dataSearch, brandId: value, start: 1 })
          }
          type="id"
          placeholder="แบรนด์"
          value={dataSearch.brandId || ''}
          fontSize="1.6rem"
        />

        {/* Catagory */}
        <SelectSimple
          options={Object.values(dataCatagory)}
          handleSelect={(value) =>
            setDataSearch({ ...dataSearch, categoryId: value, start: 1 })
          }
          type="id"
          placeholder="หมวดหมู่"
          value={dataSearch.categoryId || ''}
          fontSize="1.6rem"
        />

        {/* Status */}
        <SelectSimple
          options={Object.values(configMaster.statusRedeem)}
          handleSelect={(value) => {
            setDataSearch({ ...dataSearch, status: value, start: 1 })
          }}
          type="value"
          placeholder={`สถานะ`}
          value={dataSearch.status || ''}
        />
      </Wrapper>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          sx={{
            paddingTop: '8px',
            paddingBottom: '32px',
            fontWeight: 700,
            fontSize: '1.4rem',
            textDecoration: 'underline',
            cursor: 'pointer',
            width: '66px'
          }}
          style={{ color: '#6B7C78' }}
          onClick={() => {
            setDataSearch({ start: 1, number: PAGE_SIZE }),
              setStartDate(null),
              setEndDate(null)
          }}
        >
          ล้างข้อมูล
        </Typography>
      </div>
    </>
  )
}

export default RedeemFillter
