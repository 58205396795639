import { TableCell, TableRow, Theme } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {
  wasteDetailRequestLink,
  redeemDetailLink
} from '../../../../route/RouteDefinition'
import { useHistory } from 'react-router-dom'
import { convertToThai } from '../../../../utils/func'
import { TYPE_OF_DATE } from '../../../../constants/date'

const Text = styled.span`
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: #2c2c2c;
`

const TextDelivery = styled.span`
  display: block;
  font-weight: 700;
  font-size: 16px;
  color: #2c2c2c;
  display: flex;
  gap: 4px;
`

const ActiveStatus = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
`

const Status = styled.span`
  font-size: 1.4rem;
  color: #000000;
  font-weight: 700;
`

const TableCellStyle = styled(TableCell)`
  font-size: 1.4rem !important;
  color: #727272;
`

const Img = styled.div`
  height: 16px;
  width: 16px;
  img {
    border-radius: 4px;
    height: 100%;
    width: 100%;
  }
`

const AccountDetailItem = ({ item }) => {
  const route = useHistory()

  return (
    <>
      <TableRow
        sx={{
          verticalAlign: 'top',
          background: (theme: Theme) => theme.color.white,
          borderBottom: '1px solid #CCDDD9',
          '&': {
            transition: '.3s ease',
            cursor: 'pointer'
          },
          '&:hover': {
            background: '#CCDDD9'
          },
          '&:last-child': {
            borderBottom: 'none'
          }
        }}
        onClick={() => {
          route.push({
            pathname:
              item.serviceId == 1
                ? wasteDetailRequestLink(item.id)
                : redeemDetailLink(item.id)
          })
        }}
      >
        <TableCellStyle>
          <Text>{item.id}</Text>
        </TableCellStyle>

        <TableCellStyle>
          <Text>
            {convertToThai(item.createdAt, TYPE_OF_DATE.DATE_HOUR_FULL)}
          </Text>
        </TableCellStyle>

        <TableCellStyle>
          <TextDelivery>
            {item.deliveryImage ? (
              <Img>
                <img src={item.deliveryImage} alt="" />
              </Img>
            ) : null}
            {item.deliveryName ? item.deliveryName : '-'}
          </TextDelivery>
        </TableCellStyle>

        <TableCellStyle>
          <Text>{item.trackingno ? item.trackingno : '-'}</Text>
        </TableCellStyle>

        <TableCellStyle>
          <Text>{item.parcelweight ? item.parcelweight : '-'}</Text>
        </TableCellStyle>

        <TableCellStyle>
          <Text>{item.point ? item.point : '-'} คะแนน</Text>
        </TableCellStyle>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <ActiveStatus
            className={`${item.status ? item.status.value : '-'}`}
          />
          <Status>{item.status ? item.status.name : '-'}</Status>
        </TableCell>
      </TableRow>
    </>
  )
}

export default AccountDetailItem
