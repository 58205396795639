export const handleType = (e) => {
  // if (
  //   (e.which != 8 && e.which != 0 && e.which < 48) ||
  //   (e.which > 57 && e.which !== 190)
  // ) {
  //   e.preventDefault()
  // }

  if (
    (e.which >= 96 && e.which <= 105) ||
    (e.which >= 48 && e.which <= 57) ||
    e.which == 8 ||
    e.which == 0 ||
    e.which == 190 ||
    e.which == 110
  ) {
  } else {
    e.preventDefault()
  }
}
