import React from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import { AppButton } from '../common/AppButton'

const ModalBasic = ({ title, open, onDimiss, children }) => (
  <Modal title={title} visible={open} onCancel={onDimiss}>
    {children}

    <Divider />
    <AppButton sx={{ width: '157px', mt: '16px' }} onClick={onDimiss}>
      กลับ
    </AppButton>
  </Modal>
)
export default ModalBasic

const Divider = styled.div`
  border-top: 1px solid #ccddd9;
  width: 100%;
`
