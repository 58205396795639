import Box from '@mui/material/Box'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { updateRequestSendWaste, verifySendWaste } from '../../api'
import { AppButton } from '../../component/common/AppButton'
import AppInput from '../../component/common/AppInput'
import AppInputLabel from '../../component/common/AppInputLabel'
import { Button } from '../../component/ModalDouleButton'
import ModalWaste from '../../component/ModalWaste'
import { handleType } from '../../function/handleType'
import { InputBlock } from '../../page/Login'
import {
  Column,
  ContentBottom,
  ContentTop,
  LineThin
} from './DetailRequest/DetailStyle'

type Props = {
  open: boolean
  onDimiss: () => void
  id: any
  fetchDetail: any
  isEditPoint?: any
  trackingno: any
  name: any
}

export const ModalPendingToPoint = ({
  open,
  onDimiss,
  id,
  fetchDetail,
  isEditPoint,
  trackingno,
  name
}: Props) => {
  const [quantity, setQuantity] = useState('')
  const [parcelweight, setParcelweight] = useState('')

  const handleChange = async () => {
    const res = await verifySendWaste({
      transRefId: Number(id),
      quantity: Number(quantity),
      parcelweight: Number(parcelweight)
    })
    if (!res.error) {
      setQuantity('')
      fetchDetail()
      onDimiss()
    }
  }

  const handleChangePoint = async () => {
    const res = await updateRequestSendWaste({
      id: Number(id),
      quantity: Number(quantity),
      parcelweight: Number(parcelweight)
    })
    if (!res.error) {
      setQuantity('')
      fetchDetail()
      onDimiss()
    }
  }
  return (
    <>
      <Modal title="กรุณาใส่น้ำหนักขยะ" visible={open} onCancel={onDimiss}>
        <ContentTop>
          <div>{name}</div>
          <div>{trackingno}</div>

          <Box
            sx={{
              marginTop: '32px',
              color: '#2C2C2C'
            }}
          >
            <AppInputLabel>น้ำหนักพัสดุที่ระบุบนกล่อง</AppInputLabel>
            <InputBlock
              style={{
                width: '342px',
                height: '48px',
                border: `1px solid #CCDDD9`
              }}
            >
              <AppInput
                fullWidth
                type="number"
                placeholder="0"
                onChange={(e) =>
                  e.target.value.length < 15 && setParcelweight(e.target.value)
                }
                onKeyDown={(e) => handleType(e)}
                sx={{
                  border: 'none',
                  background: 'transparent'
                }}
                value={parcelweight}
              />
              <div className="type">กก.</div>
            </InputBlock>
          </Box>
        </ContentTop>
        <LineThin style={{ margin: '24px 32px' }} />

        <ContentBottom>
          <Box
            sx={{
              marginTop: '8px',
              color: '#2C2C2C'
            }}
          >
            <AppInputLabel>น้ำหนักขยะผ่านเกณฑ์</AppInputLabel>
            <InputBlock
              style={{
                width: '160px',
                height: '48px',
                border: `1px solid #CCDDD9`
              }}
            >
              <AppInput
                fullWidth
                type="number"
                placeholder="0"
                onChange={(e) =>
                  e.target.value.length < 6 && setQuantity(e.target.value)
                }
                onKeyDown={(e) => handleType(e)}
                sx={{
                  border: 'none',
                  background: 'transparent'
                }}
                value={quantity}
              />
              <div className="type">กก.</div>
            </InputBlock>
          </Box>
          <Column />

          <div className="earn-point">ได้รับคะแนน</div>
          <div className="number-point">{Number(quantity).toFixed(1)}</div>
          <div className="unit-point">คะแนน</div>
        </ContentBottom>

        <div style={{ display: 'flex', gap: '8px', marginLeft: '64px' }}>
          <AppButton
            sx={{ width: '157px', mt: '32px', height: '32px' }}
            onClick={isEditPoint ? handleChangePoint : handleChange}
          >
            ยืนยัน
          </AppButton>

          <Button onClick={onDimiss}>ยกเลิก</Button>
        </div>
      </Modal>
    </>
  )
}
