import DeviceDetector from 'device-detector-js'
import { DEVICE_KEY } from './deviceKey'
import { detect } from 'detect-browser'
import { deviceId } from './deviceUUID'

const browser = detect()
const deviceDetector = new DeviceDetector()
const userAgent =
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36'
const device = deviceDetector.parse(userAgent)
export const deviceDetec = { deviceId: 'fakedeviceId', device: 'device' }
