import { TableCell, TableRow, Theme } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import SelectSimple from '../../component/SelectSimple'
import { Image } from '../ProductCatalog/ProductList/ProductItem'
import moment from 'moment'
import { ModalDelivery } from './ModalDelivery'
import ModalDoubleButton from '../../component/ModalDouleButton'
import { cancleRequestRedeem, updateStatusRedeemRequest } from '../../api'
import { Content } from '../../page/ForgotPassword'
import { shortenString } from '../../utils/theme'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { ModalDigital } from './ModalDigital'
import SelectObject from '../../component/SelectObject'
import { convertToThai } from '../../utils/func'
import { TYPE_OF_DATE } from '../../constants/date'
// import starImage from '../../asset/images/star.svg'

const TextBold = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  color: #2c2c2c;
  display: block;
  margin-bottom: 8px;
`
const TextThin = styled.span`
  font-weight: 400;
  font-size: 1.4rem;
  color: #2c2c2c;
  display: block;
`

const Status = styled.div`
  display: flex;
  align-items: center;

  .pending-option {
    background: #a15b00;
  }

  .preparing-option {
    background: #0059b3;
  }

  .sent-option {
    background: #e5141f;
  }

  .completed-option {
    background: #167335;
  }

  .cancelled-option {
    background: #dedede;
  }
`
const ActiveStatus = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`

const ActiveName = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  color: #2c2c2c;
  display: block;
  margin-left: 8px;
`

const TableCellStyle = styled(TableCell)`
  display: flex !important;
  justify-content: flex-end !important;
  .ant-select {
    width: 280px !important;
  }
`

const TimeNote = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #2c2c2c;
  text-align: right;
  margin-top: 20px;
`

const Line = styled.div`
  border: 1px solid #ccddd9;
  margin-bottom: 24px;
  width: 100%;
`

type Props = {
  item: any
  handleSelect: any
  getRedeemOrderList: any
}

const RedeemItemList = ({
  item,
  handleSelect,
  getRedeemOrderList
}: Props): JSX.Element => {
  const [isDelivery, setIsDelivery] = useState(false)
  const [isCancel, setIsCancel] = useState(false)
  const [statusChange, setStatusChange] = useState('')
  const [open, setOpen] = useState(false)

  const handleChangeStatus = async (value: any) => {
    if (value === 'cancelled') {
      setIsCancel(true)
    } else {
      const res = await updateStatusRedeemRequest({
        id: item.id,
        status: value
      })
      if (!res.error) {
        getRedeemOrderList()
      }
    }
  }

  const onDimiss = () => setOpen(false)

  const handleCancel = async () => {
    const res = await cancleRequestRedeem({ id: item.id })
    if (!res.error) {
      getRedeemOrderList()
    }
  }

  return (
    <>
      <TableRow
        sx={{
          verticalAlign: 'top',
          background: (theme: Theme) => theme.color.white,
          cursor: 'pointer',
          transition: '.3s ease',
          '&:hover': {
            background: (theme: Theme) => theme.color.gray3
          },
          borderBottom: '1px solid #CCDDD9'
        }}
        onClick={(e) => {
          e.stopPropagation(),
            !isDelivery && !isCancel && !open && handleSelect()
        }}
      >
        {/* OrderId */}
        <TableCell>
          <TextThin>{item.id ? item.id : '-'}</TextThin>
        </TableCell>

        {/* Product image */}
        <TableCell>
          <Image
            src={
              item.productId
                ? item.productId.logo
                  ? item.productId.logo
                  : `/images/image-default.png`
                : `/images/image-default.png`
            }
            style={{ background: '#DADADA' }}
          />
        </TableCell>

        {/* Product detail */}
        <TableCell>
          {/* Product name */}
          <TextThin>
            {shortenString(item.productId ? item.productId.name : '-', 30)}{' '}
            {item.productId ? (
              Number(item.productId.inventory) == 1 ? (
                <img src="/icons/star.svg" alt="" />
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </TextThin>
          {/* Product type */}
          <TextThin>
            {item.productId
              ? item.productId.productType
                ? item.productId.productType.name
                : '-'
              : '-'}
          </TextThin>
        </TableCell>

        {/* Created date */}
        <TableCell>
          <TextThin>
            {item.createdAt
              ? convertToThai(item.createdAt, TYPE_OF_DATE.DATE_ONLY)
              : '-'}
          </TextThin>
          <TextThin>
            {item.createdAt
              ? convertToThai(item.createdAt, TYPE_OF_DATE.HOUR_ONLY)
              : '-'}
          </TextThin>
        </TableCell>

        {/* User detail */}
        <TableCell>
          {/* name */}
          <TextBold>{`${item.senderId ? item.senderId.firstname : '-'} ${
            item.senderId ? item.senderId.lastname : '-'
          }`}</TextBold>
          {/* name */}
          <TextThin>
            {item.senderId
              ? item.senderId.province
                ? item.senderId.province.name
                : '-'
              : '-'}
          </TextThin>
          {/* phone */}
          <TextThin>{`${
            item.senderId
              ? item.senderId.phone === null
                ? '-'
                : item.senderId.phone
              : '-'
          }`}</TextThin>
        </TableCell>

        <TableCell>
          <div className=""></div>

          <Status>
            <ActiveStatus className={`redeem-${item.status.value}`} />
            <ActiveName>{item.status ? item.status.name : '-'}</ActiveName>
          </Status>
        </TableCell>

        <TableCellStyle>
          <div>
            {item.status.value === 'completed' ||
            item.status.value === 'cancelled' ||
            item.status.value === 'cancelledbyuser' ? (
              ''
            ) : (
              <SelectObject
                options={Object.values(item.listStatus)}
                handleSelect={(value) => {
                  //digital
                  if (
                    JSON.parse(value).value === 'sent' &&
                    JSON.parse(value).redeemValue === true
                  ) {
                    setOpen(true)
                    setStatusChange(JSON.parse(value).value)
                  }
                  // product
                  if (
                    JSON.parse(value).value === 'sent' &&
                    JSON.parse(value).deliveryRequired === true
                  ) {
                    setIsDelivery(true)
                    setStatusChange(JSON.parse(value).value)
                  }

                  handleChangeStatus(JSON.parse(value).value)
                }}
                type="full"
                placeholder="เปลี่ยนสถานะ"
                isStatus={true}
              />
            )}
            <TimeNote status={item.status.value}>
              อัปเดตล่าสุด{' '}
              <span>
                {convertToThai(item.updatedAt, TYPE_OF_DATE.DATE_HOUR_FULL)}
              </span>
            </TimeNote>
          </div>
        </TableCellStyle>

        <ModalDelivery
          open={isDelivery}
          onDimiss={() => setIsDelivery(false)}
          item={item}
          statusChange={statusChange}
          getRedeemOrderList={getRedeemOrderList}
        />
        <ModalDoubleButton
          title="ยกเลิกออเดอร์"
          open={isCancel}
          onDimiss={() => setIsCancel(false)}
          handleButtonGreen={handleCancel}
          handleButtonWhite={() => setIsCancel(false)}
          textButtonGreen="ยกเลิก"
          textButtonWhite="ออก"
        >
          <Content>คุณแน่ใจว่าจะยกเลิกออเดอร์นี้ไหม</Content>
        </ModalDoubleButton>
        <ModalDigital
          onDimiss={onDimiss}
          open={open}
          nameProduct={item.productId ? item.productId.name : '-'}
          id={item.id}
          senderId={item.senderId ? item.senderId : '-'}
          getRedeemOrderList={getRedeemOrderList}
        />
      </TableRow>
    </>
  )
}

export default RedeemItemList
