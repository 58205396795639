import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Header from '../../../component/Header'
import Layout from '../../../layouts/DefaultLayout'
import { SYSTEM_ADMIN_ROUTE } from '../../../route/RouteDefinition'
import { Title } from '../../ProductCatalog/AddNewProduct'
import { AppButtonStyle } from '../../ProductCatalog/ProductDetail'
import { AppButton } from '../../../component/common/AppButton'
import { AddProductInfo } from './AddProductInfo'
import { AddUserInfo } from './AddUserInfo'
import { requestSendRedeem, verifySendRedeem } from '../../../api'
import ModalBasic from '../../../component/ModalBasic'
import { Content } from '../../../page/ForgotPassword'
import customToast from '../../../component/Toast'
import ModalError from '../../../component/ModalError'
import ModalDoubleButton from '../../../component/ModalDouleButton'

export const AddRedeemRequest = () => {
  const route = useHistory()
  const [dataAdd, setDataAdd] = useState({
    senderId: null,
    productId: null,
    addressId: null,
    quantity: 1
  })
  const [error, setError] = useState(false)
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [isNotAddress, setIsNotAddress] = useState(false)
  const [productType, setProductType] = useState('')
  const [isErrorTx, setIsErrorTx] = useState(false)
  const [isBack, setIsBack] = useState(false)

  const handleAddRedeem = async () => {
    if (!dataAdd.senderId || !dataAdd.productId) {
      setIsWarningModal(true)
      setError(true)
    } else if (
      dataAdd.addressId === null &&
      productType === 'productphysical'
    ) {
      setIsNotAddress(true)
    } else {
      const res = await requestSendRedeem(dataAdd)
      if (res.error == 0) {
        route.push(SYSTEM_ADMIN_ROUTE.REDEEM_ORDER_LIST.path)
      } else {
        setIsErrorTx(true)
      }
    }
  }

  return (
    <>
      <Layout>
        <Header>
          <div className="title-root" onClick={() => setIsBack(true)}>
            รายการออเดอร์แลกของ{' '}
          </div>
          <div className="title-sub"> / สร้างออเดอร์แลกของใหม่ </div>
        </Header>

        <Title>สร้างออเดอร์แลกของใหม่</Title>

        <AddUserInfo setDataAdd={setDataAdd} dataAdd={dataAdd} error={error} />

        <AddProductInfo
          setDataAdd={setDataAdd}
          dataAdd={dataAdd}
          error={error}
          setProductType={setProductType}
        />

        <div>
          <AppButtonStyle
            sx={{ width: '200px', mt: '32px' }}
            onClick={() => handleAddRedeem()}
          >
            <img src="/images/save.png" />
            บันทึก
          </AppButtonStyle>

          <AppButton
            sx={{ width: '134px', mt: '32px', marginLeft: '10px' }}
            color="secondary"
            onClick={() => setIsBack(true)}
          >
            ยกเลิก
          </AppButton>
        </div>

        <ModalBasic
          title=""
          open={isWarningModal}
          onDimiss={() => setIsWarningModal(false)}
        >
          <Content>กรุณาใส่ข้อมูลที่จำเป็นทั้งหมด</Content>
        </ModalBasic>

        <ModalBasic
          title="ไม่มีข้อมูลที่อยู่"
          open={isNotAddress}
          onDimiss={() => setIsNotAddress(false)}
        >
          <Content>กรุณาเพิ่มข้อมูลที่อยู่ ก่อนดำเนินการต่อ</Content>
        </ModalBasic>
        <ModalError
          content="เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้งภายหลัง"
          open={isErrorTx}
          onDimiss={() => setIsErrorTx(false)}
        />
        <ModalDoubleButton
          title="ยังไม่ได้บันทึกข้อมูล"
          open={isBack}
          onDimiss={() => setIsBack(false)}
          handleButtonGreen={() => setIsBack(false)}
          handleButtonWhite={() =>
            route.push(SYSTEM_ADMIN_ROUTE.REDEEM_ORDER_LIST.path)
          }
          textButtonGreen="อยู่ต่อหน้านี้"
          textButtonWhite="ออก"
        >
          <Content>คุณแน่ใจว่าจะออกจากหน้านี้ ข้อมูลทั้งหมดจะถูกลบ</Content>
        </ModalDoubleButton>
      </Layout>
    </>
  )
}

export const TitleField = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #00a796;
`
