import { TableCell, TableHead, TableRow } from '@mui/material'
import styled from 'styled-components'
import React from 'react'

const THead = ({ children }) => (
  <TableHead sx={{ background: '#2C2C2C' }}>
    <TableRow>{children}</TableRow>
  </TableHead>
)
export default THead
