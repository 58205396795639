import { Box, Grid, Theme } from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { recoverPassword, checkExpiration } from '../../api'
import { AppButton } from '../../component/common/AppButton'
import AppInput from '../../component/common/AppInput'
import AppInputLabel from '../../component/common/AppInputLabel'
import { encryptAuth } from '../../function/ecrypt'
import { PUBLIC_ROUTE } from '../../route/RouteDefinition'
import { useAppMaster } from '../../store/_hook'
import { InputBlock } from '../Login'
import image from '../../asset/images/logo.png'
import './style.css'
import customToast from '../../component/Toast'
import { remove } from '../../utils/theme'

const Tit = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #2c2c2c;
`
const Mes = styled.div`
  margin-left: 15px;
`

const RecoverPassword = () => {
  const [pass, setPass] = useState('')
  const [error, setError] = useState(false)
  const [errorPass, setErrorPass] = useState(false)
  const [passConfirm, setPassConfirm] = useState('')
  const [errorCharacter, setErrorCharacter] = useState(false)
  const [errorNumber, setErrorNumber] = useState(false)
  const [errorUppercase, setErrorUppercase] = useState(false)
  const [errorLowercase, setErrorLowercase] = useState(false)
  const [errorTextUl, setErrorTextUl] = useState(false)
  const [errorSpecialCharacter, setErrorSpecialCharacter] = useState(false)
  const route = useHistory()
  const [errPassConfirm, setErrPassConfirm] = useState(false)
  const url = window.location.search
  const params = new URLSearchParams(url)
  const [email, setEmail] = useState('')

  const {
    state: { configMaster }
  } = useAppMaster()

  const mesSuccess = (
    <Mes>
      <Tit>ตั้งรหัสผ่านใหม่สำเร็จ</Tit>
      <div>กรุณาเข้าสู่ระบบด้วยรหัสผ่านใหม่</div>
    </Mes>
  )

  const mesError = (
    <Mes>
      <Tit>ตั้งรหัสผ่านใหม่ไม่สำเร็จ</Tit>
      <div>กรุณาลองใหม่อีกครั้ง</div>
    </Mes>
  )

  const handlePassword = (value) => {
    setPass(value)
    if (value.length < 8) {
      setErrorCharacter(true)
      setErrorPass(true)
    } else {
      setErrorCharacter(false)
      setErrorPass(false)
    }
    const rgxNumber = /\d/
    if (rgxNumber.test(value)) setErrorNumber(false)
    else setErrorNumber(true)
    if (value !== value.toLowerCase()) setErrorUppercase(false)
    else setErrorUppercase(true)
    if (value !== value.toUpperCase()) setErrorLowercase(false)
    else setErrorLowercase(true)
    const rgxSpecial = /[ `!@#$%&]/
    if (rgxSpecial.test(value)) setErrorSpecialCharacter(false)
    else setErrorSpecialCharacter(true)
    const regex =
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])^[\s\S]{8,32}$/
    if (value.match(regex)) {
      setErrorTextUl(false)
    } else {
      setErrorTextUl(true)
    }
  }

  const handlePasswordConfirm = (value) => {
    setPassConfirm(value)
    if (value.length === 0) setErrPassConfirm(true)
    else setErrPassConfirm(false)
    if (pass === value) {
      setErrPassConfirm(false)
      setError(false)
    } else {
      setErrPassConfirm(true)
      setError(true)
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (pass.length === 0) {
      setErrorPass(true)
      setError(true)
      setErrorCharacter(true)
      setErrorNumber(true)
      setErrorUppercase(true)
      setErrorSpecialCharacter(true)
      setErrorTextUl(true)
    } else {
      setErrorPass(false)
      setError(false)
      setErrorCharacter(false)
      setErrorNumber(false)
      setErrorUppercase(false)
      setErrorSpecialCharacter(false)
      setErrorTextUl(false)
    }
    if (passConfirm.length === 0) {
      setError(true)
    } else setError(false)
    const password = encryptAuth(
      pass,
      configMaster.encryptPassNew,
      configMaster.characterSeparate
    )
    const passwordConfirm = encryptAuth(
      passConfirm,
      configMaster.encryptPassConfirm,
      configMaster.characterSeparate
    )

    try {
      const res = await recoverPassword({
        token: params.get('token'),
        password: password,
        passwordConfirm: passwordConfirm
      })
      if (res.error == 0) {
        route.push(PUBLIC_ROUTE.LOGIN.path)
        customToast.success(mesSuccess)
      } else {
        customToast.error(mesError)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleClick = () => {
    setPass('')
  }

  const checkExpirationToken = async () => {
    remove()
    const res = await checkExpiration({
      token: params.get('token'),
      status: params.get('status')
    })
    if (res.error) {
      route.push(PUBLIC_ROUTE.NOT_FOUND.path)
    } else {
      setEmail(res.data.email)
    }
  }

  useEffect(() => {
    checkExpirationToken()
  }, [])

  return (
    <>
      <Route exact path={PUBLIC_ROUTE.RECOVER_PASSWORD.path}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
          sx={{
            backgroundColor: (theme: Theme) => theme.color.gray1
          }}
        >
          <Logo>
            <img src={image} />
          </Logo>
          <Content>
            <Typography
              sx={{
                color: '#2C2C2C',
                fontSize: '32px',
                fontWeight: 700
              }}
            >
              ตั้งรหัสผ่านใหม่
            </Typography>
            <Typography
              sx={{
                color: '#2C2C2C',
                fontSize: '16px',
                fontWeight: 400
              }}
            >
              กรุณาตั้งรหัสผ่านใหม่บัญชี <strong>{email}</strong>
              <br />
              และยืนยันรหัสผ่านใหม่
            </Typography>

            <Box sx={{ width: '400px' }}>
              <form>
                <Box sx={{ mt: '22px' }}>
                  <AppInputLabel htmlFor="password">รหัสผ่านใหม่</AppInputLabel>
                  <InputBlock errors={errorPass}>
                    <AppInput
                      fullWidth
                      type="password"
                      placeholder="ใส่รหัสผ่านใหม่"
                      required
                      id="password"
                      name="password"
                      autoComplete="off"
                      value={pass}
                      onChange={(e) => handlePassword(e.target.value)}
                      sx={{
                        border: 'none',
                        background: 'transparent'
                      }}
                    />
                    {pass !== '' ? (
                      <img
                        src="/icons/iconX.png"
                        alt="clear"
                        onClick={handleClick}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <img src="/icons/password.png" alt="password" />
                    )}
                  </InputBlock>
                </Box>
                <ErrorPass>
                  <ul className={errorTextUl ? 'errorUl' : ''}>
                    รหัสผ่านจำเป็นต้องมี{' '}
                  </ul>
                  <Box>
                    <li className={errorCharacter ? 'errCharacter' : ''}>
                      มีอย่างน้อย 8 ตัวอักษร{' '}
                    </li>
                    <li className={errorNumber ? 'errNumber' : ''}>
                      มีตัวเลข 0-9 อย่างน้อย 1 ตัว
                    </li>
                    <li className={errorUppercase ? 'errUpperCase' : ''}>
                      มีตัวอักษรพิมใหญ่(A-Z) อย่างน้อย 1 ตัว{' '}
                    </li>
                    <li className={errorUppercase ? 'errorLowercase' : ''}>
                      มีตัวอักษรพิมเล็ก(a-z) อย่างน้อย 1 ตัว
                    </li>
                    <li
                      className={
                        errorSpecialCharacter ? 'errSpecialCharacter' : ''
                      }
                    >
                      มีสัญลักษณ์ (!@#$%&) อย่างน้อย 1 ตัว
                    </li>
                  </Box>
                </ErrorPass>

                <Box sx={{ mt: '22px' }}>
                  <AppInputLabel htmlFor="password-confirm">
                    ยืนยันรหัสผ่านใหม่
                  </AppInputLabel>

                  <InputBlock errors={error}>
                    <AppInput
                      fullWidth
                      type="password"
                      placeholder="ใส่รหัสผ่านใหม่อีกครั้ง"
                      required
                      id="password-confirm"
                      name="password-confirm"
                      autoComplete="off"
                      value={passConfirm}
                      onChange={(e) => handlePasswordConfirm(e.target.value)}
                      sx={{
                        border: 'none',
                        background: 'transparent'
                      }}
                    />
                    {passConfirm !== '' ? (
                      <img
                        src="/icons/iconX.png"
                        alt="clear"
                        onClick={() => setPassConfirm('')}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <img src="/icons/password.png" alt="password" />
                    )}
                  </InputBlock>
                  <Typography
                    className={`${errPassConfirm && 'errPassConfirm'} 'error'`}
                  >
                    {errPassConfirm && 'รหัสผ่านไม่เหมือนกัน กรุณาพิมอีกครั้ง'}
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', gap: '16px' }}>
                  <AppButton
                    sx={{ width: '182px', mt: '38px' }}
                    onClick={handleSubmit}
                  >
                    ยืนยัน
                  </AppButton>

                  <ButtonBack
                    onClick={() => route.push(PUBLIC_ROUTE.LOGIN.path)}
                  >
                    กลับ
                  </ButtonBack>
                </Box>
              </form>
            </Box>
          </Content>
        </Grid>
      </Route>
    </>
  )
}

export default RecoverPassword

const Content = styled.div`
  background: #ffffff;
  border-radius: 4px;
  width: 1000px;
  min-height: 456px;
  padding: 32px 48px;
`
const Logo = styled.div`
  width: 1000px;
  margin-bottom: 38px;
  img {
    width: 216px;
    height: 65px;
  }
`

export const ErrorPass = styled.div`
  color: #6b7c78;
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;
`
const ButtonBack = styled.button`
  width: 100px;
  height: 48px;
  margin-top: 38px;
  font-weight: 700;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #ccddd9;
  border-radius: 4px;
`
