import React from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import { AppButton } from '../common/AppButton'

const ModalDoubleButton = ({
  title,
  open,
  onDimiss,
  children,
  handleButtonGreen,
  handleButtonWhite,
  textButtonGreen,
  textButtonWhite
}) => (
  <Modal title={title} visible={open} onCancel={onDimiss}>
    {children}

    <Divider />
    <div style={{ display: 'flex', gap: '8px' }}>
      <AppButton
        sx={{ width: '157px', mt: '32px', height: '32px' }}
        onClick={() => handleButtonGreen()}
      >
        {textButtonGreen}
      </AppButton>

      <Button onClick={() => handleButtonWhite()}>{textButtonWhite}</Button>
    </div>
  </Modal>
)
export default ModalDoubleButton

const Divider = styled.div`
  border-top: 1px solid #ccddd9;
  width: 100%;
`

export const Button = styled.button`
  width: 92px;
  height: 32px;
  font-weight: 700;
  font-size: 16px;
  background: #ffffff;
  margin-top: 32px;
  border: 1px solid #ccddd9;
  border-radius: 4px;
`
