import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { createRequestSendWaste, uploadImg } from '../../../api'
import { AppButton } from '../../../component/common/AppButton'
import Header from '../../../component/Header'
import ModalBasic from '../../../component/ModalBasic'
import ModalError from '../../../component/ModalError'
import Layout from '../../../layouts/DefaultLayout'
import { Content } from '../../../page/ForgotPassword'
import { SYSTEM_ADMIN_ROUTE } from '../../../route/RouteDefinition'
import { Title } from '../../ProductCatalog/AddNewProduct'
import { AppButtonStyle } from '../../ProductCatalog/ProductDetail'
import AddImage from './AddImage'
import { AddRequestInfo } from './AddRequestInfo'
import ModalDoubleButton from '../../../component/ModalDouleButton'

export const AddRequest = () => {
  const route = useHistory()
  const [dataAdd, setDataAdd] = useState({
    senderId: null,
    listImage: [],
    productId: 1
  })
  const [error, setError] = useState(false)
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [isModalError, setModalError] = useState(false)
  const [isBack, setIsBack] = useState(false)

  const handleAddWaste = async () => {
    if (!dataAdd.senderId) {
      setIsWarningModal(true)
      setError(true)
    } else {
      const resCalls =
        (dataAdd.listImage.length > 0 &&
          dataAdd.listImage.map(async (img) => {
            return await uploadImg(img.originFileObj)
          })) ||
        []
      const resImg = await Promise.all(resCalls)

      let listLinkImg = []
      resImg.length > 0 ? resImg.map((res) => listLinkImg.push(res.data)) : []
      if (listLinkImg.length === dataAdd.listImage.length) {
        const res = await createRequestSendWaste({
          ...dataAdd,
          listImage: listLinkImg
        })
        if (!res.error) {
          route.push(SYSTEM_ADMIN_ROUTE.WASTE_REQUEST.path)
        } else setModalError(true)
      }
    }
  }

  return (
    <>
      <Layout>
        <Header>
          <div className="title-root" onClick={() => setIsBack(true)}>
            รายการออเดอร์ส่งขยะ{' '}
          </div>
          <div className="title-sub"> / สร้างออเดอร์ส่งขยะใหม่ </div>
        </Header>

        <div>
          <Title>สร้างออเดอร์ส่งขยะใหม่</Title>

          <AddRequestInfo
            setDataAdd={setDataAdd}
            dataAdd={dataAdd}
            error={error}
          />

          <AddImage setDataAdd={setDataAdd} dataAdd={dataAdd} />
        </div>

        <div>
          <AppButtonStyle
            sx={{ width: '200px', mt: '32px' }}
            onClick={handleAddWaste}
          >
            <img src="/images/save.png" />
            บันทึก
          </AppButtonStyle>

          <AppButton
            sx={{ width: '134px', mt: '32px', marginLeft: '10px' }}
            color="secondary"
            onClick={() => setIsBack(true)}
          >
            ยกเลิก
          </AppButton>
        </div>

        <ModalBasic
          title=""
          open={isWarningModal}
          onDimiss={() => setIsWarningModal(false)}
        >
          <Content>กรุณาใส่ข้อมูลที่จำเป็นทั้งหมด</Content>
        </ModalBasic>

        <ModalError
          content="เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้งภายหลัง"
          open={isModalError}
          onDimiss={() => setModalError(false)}
        />
        <ModalDoubleButton
          title="ยังไม่ได้บันทึกข้อมูล"
          open={isBack}
          onDimiss={() => setIsBack(false)}
          handleButtonGreen={() => setIsBack(false)}
          handleButtonWhite={() =>
            route.push(SYSTEM_ADMIN_ROUTE.WASTE_REQUEST.path)
          }
          textButtonGreen="อยู่ต่อหน้านี้"
          textButtonWhite="ออก"
        >
          <Content>คุณแน่ใจว่าจะออกจากหน้านี้ ข้อมูลทั้งหมดจะถูกลบ</Content>
        </ModalDoubleButton>
      </Layout>
    </>
  )
}
