import { TableCell, TableRow, Theme } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import starImage from '../../../../asset/images/star.svg'
import { shortenString } from '../../../../utils/theme'

export const Image = styled.img`
  border-radius: 4px;
  cursor: pointer;
  border-radius: 4px;
  cursor: pointer;
  width: 107px;
  height: 72px;
  object-fit: scale-down;
  border-radius: 8px;
`

const Title = styled.h4`
  font-weight: 700;
  font-size: 1.4rem;
  color: #484848;
`
const Paragraph = styled.p`
  font-size: 1.2rem;
  color: #727272;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-all;
`
const Value = styled.span`
  display: block;
  font-weight: 700;
  font-size: 1.4rem;
  color: #727272;
`
const Text = styled.span`
  font-size: 1.2rem;
  color: #969494;
`

const ActiveStatus = styled.div`
  width: 16px;
  height: 16px;
  background: ${({ active }) => (active ? '#167335' : '#E5141F')};
  border-radius: 50%;
  margin-right: 8px;
`

const Status = styled.span`
  font-size: 1.4rem;
  color: #000000;
  font-weight: 700;
`

const ProductItem = ({ dataItem, handleSelect }) => {
  return (
    <TableRow
      sx={{
        verticalAlign: 'top',
        background: (theme: Theme) => theme.color.white,
        borderBottom: '1px solid #CCDDD9',
        '&': {
          transition: '.3s ease',
          cursor: 'pointer'
        },
        '&:hover': {
          background: '#CCDDD9'
        },
        '&:last-child': {
          borderBottom: 'none'
        }
      }}
      onClick={handleSelect}
    >
      <TableCell>
        {dataItem.listImage.length > 0 ? (
          <Image src={dataItem.listImage[0]} />
        ) : (
          <Image
            src="/images/image-default.png"
            style={{ background: '#DADADA' }}
          />
        )}
      </TableCell>
      <TableCellStyle>
        <Title>
          {shortenString(dataItem.name, 30)}
          {dataItem.inventory.value === 1 && (
            <img src={starImage} style={{ paddingLeft: '4px' }} />
          )}
        </Title>
        <Paragraph>{shortenString(dataItem.description, 50)}</Paragraph>
      </TableCellStyle>

      <TableCellStyle>{dataItem.productType.name}</TableCellStyle>

      <TableCellStyle>
        <Value>{dataItem.value || 0}</Value>
        <Text>points</Text>
      </TableCellStyle>

      <TableCellStyle>{dataItem.quantity || 0}</TableCellStyle>
      <TableCellStyle>{dataItem.brandId.name || 0}</TableCellStyle>

      <TableCellStyle>
        {dataItem.categories &&
          dataItem.categories.map((item, index) => (
            <div>
              {item.name} {index + 1 < dataItem.categories.length && ','}
            </div>
          ))}
      </TableCellStyle>

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <ActiveStatus active={dataItem.status === 'active'} />
        <Status>
          {dataItem.status === 'active' ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
        </Status>
      </TableCell>
    </TableRow>
  )
}

export default ProductItem

const TableCellStyle = styled(TableCell)`
  font-size: 1.4rem !important;
  color: #727272;
`
