import { Theme, Typography } from '@mui/material'
import React from 'react'

const TextTitle = () => {
  return (
    <>
      <Typography
        sx={{ color: (theme: Theme) => theme.color.grey, fontSize: '1.6rem' }}
      >
        แปลว่าแบรนด์จะเป็นคนส่งของเอง กรุณาติดต่อเเบรนด์
      </Typography>
    </>
  )
}

export default TextTitle
