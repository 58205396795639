import { TableCell, TableRow, Theme } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { convertToThai } from '../../../utils/func'
import { TYPE_OF_DATE } from '../../../constants/date'

const Text = styled.span`
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: #2c2c2c;
`
const TextName = styled.span`
  display: block;
  font-weight: 700;
  font-size: 16px;
  color: #2c2c2c;
`

const ActiveStatus = styled.div`
  width: 16px;
  height: 16px;
  background: ${({ active }) => (active ? '#167335' : '#E5141F')};
  border-radius: 50%;
  margin-right: 8px;
`

const Status = styled.span`
  font-size: 14px;
  color: #2c2c2c;
  font-weight: 700;
`

const TableCellStyle = styled(TableCell)`
  font-size: 1.4rem !important;
  color: #727272;
`

const ManagerItem = ({ dataItem, handleSelect }) => {
  return (
    <>
      <TableRow
        sx={{
          verticalAlign: 'top',
          background: (theme: Theme) => theme.color.white,
          borderBottom: '1px solid #CCDDD9',
          '&': {
            transition: '.3s ease',
            cursor: 'pointer'
          },
          '&:hover': {
            background: '#CCDDD9'
          },
          '&:last-child': {
            borderBottom: 'none'
          }
        }}
        onClick={handleSelect}
      >
        <TableCellStyle>
          <TextName>{`${dataItem.firstname} ${dataItem.lastname}`}</TextName>
        </TableCellStyle>

        <TableCellStyle>
          <Text>
            {dataItem.email}{' '}
            {dataItem.isWattingVerifyEmail && (
              <img
                src="/icons/warning-icon.svg"
                alt=""
                width={13}
                height={12}
              />
            )}
          </Text>
          <Text>{dataItem.phone}</Text>
        </TableCellStyle>

        <TableCellStyle>
          <Text>{dataItem.groupId ? dataItem.groupId.name : '-'}</Text>
        </TableCellStyle>

        <TableCellStyle>
          <Text>{dataItem.province ? dataItem.province.name : '-'}</Text>
        </TableCellStyle>

        <TableCellStyle>
          <Text>
            {convertToThai(dataItem.createdAt, TYPE_OF_DATE.DATE_ONLY)}
          </Text>
          <Text>
            {convertToThai(dataItem.createdAt, TYPE_OF_DATE.HOUR_ONLY)}
          </Text>
        </TableCellStyle>

        <TableCellStyle>
          <Text>{dataItem.balance || 0}</Text>
        </TableCellStyle>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <ActiveStatus active={dataItem.status === 'active'} />
          <Status>
            {dataItem.status === 'active' ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
          </Status>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ManagerItem
