import styled from 'styled-components'
import React from 'react'
import searchIcon from '../../../../asset/images/Vector2.svg'

export const Wrapper = styled.div`
  margin-top: 40px;
  position: relative;
`

export const InputSearch = styled.input`
  width: 100%;
  border: 1px solid ${({ error }) => (error ? 'red' : '#ccddd9')};
  padding: 13px 0 12px 56px;
  font-size: 1.6rem;
  color: #6b7c78;
  font-weight: 400;
  border-radius: 4px;
  background: ${({ disabled }) => (disabled ? '#DADADA' : 'white')};
`

export const ImageSearch = styled.img`
  color: #2c2c2c;
  position: absolute;
  top: 52%;
  left: 28px;
  transform: translateY(-50%);
`

const SearchFilter = ({ dataSearch, setDataSearch }) => {
  return (
    <Wrapper>
      <InputSearch
        placeholder="ค้นหาด้วยชื่อสินค้า"
        onChange={(e) =>
          setDataSearch({
            ...dataSearch,
            name: e.target.value,
            start: 1
          })
        }
        value={dataSearch.name || ''}
      />
      <ImageSearch src={searchIcon} />
    </Wrapper>
  )
}

export default SearchFilter
