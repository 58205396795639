import moment from 'moment'
import React from 'react'
import { TYPE_OF_DATE } from '../../../constants/date'
import { convertToThai } from '../../../utils/func'
import { ContentField, InfoMessage, LineThin } from './DetailStyle'

export const MessageCancelItem = ({ data }) => {
  return (
    <>
      <div>
        <ContentField
          style={{
            wordBreak: 'break-word',
            width: '400px'
          }}
        >
          {data.value}
        </ContentField>
        <InfoMessage>
          จาก {data.email} |{' '}
          {convertToThai(data.createdAt, TYPE_OF_DATE.DATE_HOUR_FULL)}
        </InfoMessage>
        <LineThin style={{ margin: '12px 0px' }} />
      </div>
    </>
  )
}
