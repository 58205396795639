import React from 'react'
import styled from 'styled-components'
import {
  HeaderInfo,
  Contact
} from '../../WasteRequest/AddRequest/AddRequestInfo'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/_store'
import { ImgProduct, DetailProduct } from './AddProductInfo'
import { shortenString } from '../../../utils/theme'

export const ProductList = ({
  dataListProduct,
  setShowProductList,
  fetchProductDetail,
  setProductId
}) => {
  const { dataBrand } = useSelector((state: RootState) => state.selectSlice)
  const handleGetNameBrand = (id: any) => {
    let obj = Object.values(dataBrand).find((item) => item.id == id)
    return obj ? obj.name : ''
  }

  return (
    <>
      <Wrapper len={dataListProduct.length}>
        {dataListProduct.length > 0 ? (
          dataListProduct.map((itemProduct, index) => (
            <Product
              key={index}
              onClick={() => {
                setProductId(itemProduct.id)
                setShowProductList(false), fetchProductDetail(itemProduct.id)
              }}
            >
              <Contact
                style={{
                  gap: '20px',
                  marginLeft: '0px',
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              >
                <ImgProduct>
                  <img
                    src={
                      itemProduct
                        ? itemProduct.logo
                          ? itemProduct.logo
                          : '/images/product-example.png'
                        : '/images/product-example.png'
                    }
                  />
                </ImgProduct>

                <DetailProduct>
                  <div className="bold-text">
                    {shortenString(itemProduct.name || '', 30)}
                  </div>
                  {/* <div className="primary-text">8 คะแนน</div> */}
                  <div className="thin-text">
                    {handleGetNameBrand(itemProduct.brandId)} <span>|</span>
                    {itemProduct.productType
                      ? itemProduct.productType.name
                      : ''}
                  </div>
                </DetailProduct>
              </Contact>
            </Product>
          ))
        ) : (
          <Product />
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  border: 1px solid #ccddd9;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  margin-top: 6px;
  ${(props) => (props.len <= 2 ? ' height:fit-content' : ' height:270px')};
  overflow-y: scroll;
  z-index: 2;
  &::-webkit-scrollbar {
    width: 6.6px;
    height: 6.6px;
    background-color: #ccddd9;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #00a796;
  }
`

const Product = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 24px;

  .text-thin {
    color: #6b7c78;
  }
  :hover {
    background: #ccddd9;
  }
`
