import React from 'react'
import { Box, Button, Theme, useTheme } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'
import { SxProps } from '@mui/system'

interface AppButtonProps extends Omit<ButtonProps, 'color' | 'size'> {
  boxShadow?: boolean
  loading?: boolean
  size?: 's' | 'm' | 'l' | 'xl'
  color?: 'primary' | 'secondary'
  needSign?: boolean
  isTransaction?: boolean
}

export const AppButton = React.forwardRef(function AppButton(
  {
    sx,
    variant = 'contained',
    color = 'primary',
    size = 'm',
    children,
    boxShadow: hasBoxShadow,
    loading = false,
    disabled = false,
    ...props
  }: AppButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const theme = useTheme<Theme>()

  const funcStyle = ({
    bg,
    color,
    colorOutlined,
    border,
    boxShadow
  }: {
    bg: string
    color: string
    colorOutlined: string
    border: string
    boxShadow: string
  }): SxProps => {
    boxShadow = hasBoxShadow ? `0px 5px 15px ${boxShadow}66` : 'none'

    return {
      // ------ Style 'contained'
      contained: {
        backgroundColor: bg,
        color,
        border: 1,
        borderColor: 'transparent',
        boxShadow,
        '&&&:hover': {
          backgroundColor: bg
          // boxShadow: (theme: Theme) => theme.color.boxShadow2,
        },
        '&:active': {
          boxShadow: 'none',
          borderColor: border
        },
        // '&:focus': {
        //   boxShadow: (theme: Theme) => theme.color.boxShadow3,
        // },
        '&&&:disabled': loading
          ? {
              color
            }
          : {
              // backgroundColor: theme.color.gray4,
              borderColor: theme.color.gray3,
              // color: theme.color.textLight,
              boxShadow: 'none'
            }
      },
      // ------ Style 'outlined'
      outlined: {
        backgroundColor: '#fff',
        border: 1,
        borderColor: border,
        color: colorOutlined,
        boxShadow,
        '&:disabled': loading
          ? {
              // backgroundColor: theme.color.bg,
              // borderColor: theme.color.bg,
            }
          : {
              backgroundColor: '#fff',
              borderColor: theme.color.gray3,
              // color: theme.color.gray4,
              boxShadow: 'none'
            }
      },
      // ------ Style 'text'
      text: {}
    }
  }

  // Override style
  const style: { [Key in typeof color]: SxProps } = {
    primary: funcStyle({
      bg: theme.color.teal,
      color: theme.color.white,
      colorOutlined: theme.color.white,
      border: theme.color.teal,
      boxShadow: 'unset'
    }),
    secondary: funcStyle({
      bg: theme.color.white,
      color: theme.color.blackStone,
      colorOutlined: theme.color.blackStone,
      border: theme.color.gray3,
      boxShadow: 'unset'
    })
  }

  // Style by size
  const stylesSize: { [Key in typeof size]: SxProps } = {
    s: {},
    m: {
      fontSize: 16,
      padding: '8px 16px'
    },
    l: {
      fontSize: 18,
      padding: '9px 20px'
    },
    xl: {
      fontSize: 20,
      padding: '15px 28px'
    }
  }

  // Style for loading
  const styleLoading: SxProps = loading
    ? {
        opacity: 0.65
      }
    : {}

  const iconLoading = loading && (
    <Box
      sx={{
        animation: 'loading 2s infinite linear',
        width: '20px',
        height: '20px',
        mr: '10px',

        '@keyframes loading': {
          from: {
            transform: 'rotate(359deg)'
          },
          to: {
            transform: 'rotate(0deg)'
          }
        }
      }}
    >
      <img
        src={
          variant === 'outlined'
            ? '/icons/loading-dark.svg'
            : '/icons/loading.svg'
        }
        alt=""
      />
    </Box>
  )

  return (
    <Button
      ref={ref}
      sx={{
        // ------ Style common
        textTransform: 'none',
        fontWeight: 'bold',
        border: '0px solid',
        lineHeight: '24px',
        borderRadius: '4px',
        // ------ Style by `variant`
        ...style[color][variant],
        // ------ Style by `size`
        ...stylesSize[size],
        // ------ Style by `loading`
        ...styleLoading,
        // ------ Style by `props`
        ...sx
      }}
      disabled={disabled || loading}
      {...props}
    >
      {iconLoading}
      {children}
    </Button>
  )
})
