import React, { useState } from 'react'
import { AppButton } from '../../../component/common/AppButton'
import SelectSimple from '../../../component/SelectSimple'
import { AreaFieldAdd } from '../../ProductCatalog/AddNewProduct/ProductDescription/AreaFieldAdd'
import { Title } from '../../ProductCatalog/AddNewProduct/ProductImages/ImageItem'
import { Contact, InfoUser } from '../../WasteRequest/AddRequest/AddRequestInfo'
import {
  CancelOrder,
  ContentField,
  TextNormal,
  HeaderDetail,
  LineBold,
  LineThin,
  StatusRedeem,
  TitleField,
  WrapperDetail
} from '../../WasteRequest/DetailRequest/DetailStyle'
import { ModalEditPoint } from '../../WasteRequest/DetailRequest/ModalEditPoint'
import { DetailProduct, ImgProduct } from '../AddRedeemRequest/AddProductInfo'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/_store'
import { updateStatusRedeemRequest } from '../../../api'
import { ModalDelivery } from '../ModalDelivery'
import { MessageBlock } from '../../WasteRequest/DetailRequest/DetailStyle'
import { MessageCancelItem } from '../../WasteRequest/DetailRequest/MessageCancelItem'
import styled from 'styled-components'
import SelectObject from '../../../component/SelectObject'
import { ModalDigital } from '../ModalDigital'
import { convertToThai } from '../../../utils/func'
import { TYPE_OF_DATE } from '../../../constants/date'

export const OrderInfo = ({
  item,
  setIsCancel,
  handleEditDescription,
  fetchOrderDetail
}) => {
  const [valueDescription, setValueDescription] = useState('')
  const { dataBrand } = useSelector((state: RootState) => state.selectSlice)
  const [isDelivery, setIsDelivery] = useState(false)
  const [statusChange, setStatusChange] = useState('')
  const [open, setOpen] = useState(false)

  const handleGetNameBrand = (id: any) => {
    let obj = Object.values(dataBrand).find((item) => item.id == id)
    return obj ? obj.name : ''
  }
  const onDimiss = () => setOpen(false)

  const handleChangeStatus = async (value: any) => {
    if (value === 'cancelled') {
      setIsCancel(true)
    } else {
      const res = await updateStatusRedeemRequest({
        id: item.id,
        status: value
      })
      if (!res.error) {
        fetchOrderDetail()
      }
    }
  }

  const Img = styled.div`
    height: 16px;
    width: 16px;
    img {
      border-radius: 4px;
      height: 100%;
      width: 100%;
    }
  `
  const TextDelivery = styled.span`
    display: block;
    font-weight: 700;
    font-size: 16px;
    color: #2c2c2c;
    display: flex;
    gap: 4px;
  `
  const renderDetailsAddress = (item) => {
    const address = item.addressId
    if (address !== null && address !== undefined) {
      const { province, district, ward } = address
      if (province && district && ward) {
        return (
          <ContentField>
            {district.name + ', ' + ward.name + ', ' + province.name}
          </ContentField>
        )
      }
    }
    return <div />
  }

  const { userId } = item

  return (
    <>
      <WrapperDetail>
        <HeaderDetail>
          <div className="order-id">ออเดอร์เลขที่</div>
          <div className="id">{item.id || '-'}</div>
          <div
            className={`status-redeem status-redeem-${
              item.status ? item.status.value : '-'
            }`}
          >
            {item.status ? item.status.name : '-'}
          </div>
          <div className="date-time">
            อัปเดตล่าสุด{' '}
            {convertToThai(item.updatedAt, TYPE_OF_DATE.DATE_HOUR_FULL)}
          </div>
        </HeaderDetail>

        <LineBold />

        {/* ====delivery detail====== */}

        <Title>ข้อมูลออเดอร์</Title>

        <TitleField>คนสร้างออเดอร์</TitleField>
        <ContentField>
          {item.createdByRef === 'officer' ? (
            <span>Admin</span>
          ) : (
            <span>User</span>
          )}{' '}
          : {item.userId ? item.userId.email : '-'}
        </ContentField>
        <div style={{ display: 'flex', gap: '100px' }}>
          <div>
            {/* Delievery company */}
            <TitleField> บริษัทขนส่ง</TitleField>
            {!item.deliveryId ? (
              <TextNormal>ไม่มีข้อมูล</TextNormal>
            ) : item.deliveryId ? (
              <TextDelivery>
                <Img>
                  <img src={item.deliveryId.image} alt="imgDelivery" />
                </Img>
                {item.deliveryId.value === 'other'
                  ? item.deliveryDescription
                  : item.deliveryId.name}
              </TextDelivery>
            ) : (
              '-'
            )}

            {/* delivery address */}

            <TitleField>ที่อยู่จัดส่ง</TitleField>
            <ContentField>
              {item.deliveryAddress ? item.deliveryAddress : '-'}
            </ContentField>
            {/* {renderDetailsAddress(item)} */}

            {/* Created date */}

            <TitleField>วันที่สร้าง</TitleField>
            <ContentField>
              {convertToThai(item.createdAt, TYPE_OF_DATE.DATE_HOUR_FULL)}
            </ContentField>
          </div>

          <div>
            {/* Tracking no. */}
            <TitleField>Tracking no.</TitleField>
            {!item.trackingno ? (
              <TextNormal>ไม่มีข้อมูล</TextNormal>
            ) : (
              <TextDelivery>{item.trackingno}</TextDelivery>
            )}
          </div>
        </div>

        <LineThin />
        {/* ====Image====== */}

        <Title>ข้อมูลสินค้า</Title>

        {/* <NoImg>ไม่มีรูปภาพ</NoImg> */}
        <InfoUser>
          <Contact
            style={{ gap: '20px', marginLeft: '0px', marginTop: '10px' }}
          >
            <ImgProduct>
              <img
                src={
                  item.productId
                    ? item.productId.listImage
                      ? item.productId.listImage[0]
                      : '/images/product-example.png'
                    : '/images/product-example.png'
                }
              />
            </ImgProduct>

            <DetailProduct>
              <div className="bold-text">
                {item.productId ? item.productId.name : '-'}
              </div>
              <div className="primary-text">
                {item.productId ? item.productId.value : '-'} คะแนน
              </div>
              <div className="thin-text">
                {item.productId
                  ? handleGetNameBrand(item.productId.brandId)
                  : '-'}
                <span>|</span>{' '}
                {item.productId ? item.productId.productType.name : '-'}
              </div>
            </DetailProduct>
          </Contact>
        </InfoUser>

        <div style={{ marginLeft: '24px' }}>
          <TitleField>คะแนนที่ใช้</TitleField>
          <ContentField>
            <strong>{item.value || '-'}</strong> คะแนน
          </ContentField>
        </div>

        <LineThin />

        {/* ====Status====== */}

        {item.status
          ? !['cancelled', 'completed', 'cancelledbyuser'].includes(
              item.status.value
            ) && (
              <CancelOrder onClick={() => setIsCancel(true)}>
                ยกเลิกออเดอร์นี้
              </CancelOrder>
            )
          : ''}
        <Title>สถานะ</Title>

        <StatusRedeem>
          <div
            className={`status redeem-${item.status ? item.status.value : '-'}`}
          ></div>
          <ContentField>{item.status ? item.status.name : '-'}</ContentField>
        </StatusRedeem>
        {item.listStatus
          ? item.listStatus.length > 0 && (
              <SelectObject
                width={200}
                options={Object.values(item.listStatus)}
                handleSelect={(value) => {
                  //digital
                  if (
                    JSON.parse(value).value === 'sent' &&
                    JSON.parse(value).redeemValue === true
                  ) {
                    setOpen(true)
                    setStatusChange(JSON.parse(value).value)
                  }
                  // product
                  if (
                    JSON.parse(value).value === 'sent' &&
                    JSON.parse(value).deliveryRequired === true
                  ) {
                    setIsDelivery(true)
                    setStatusChange(JSON.parse(value).value)
                  }

                  handleChangeStatus(JSON.parse(value).value)
                }}
                type="full"
                placeholder="เปลี่ยนสถานะ"
                isStatus={true}
              />
            )
          : ''}

        {/* For status point added */}

        <div style={{ display: 'flex', gap: '16px', marginTop: '32px' }}>
          <AreaFieldAdd
            title="ข้อความ"
            placeholder="ใส่ข้อความ"
            maxLength={100}
            minRow={4}
            handleChange={(e) => setValueDescription(e.target.value)}
            value={valueDescription}
            waste={true}
          />

          {item.descriptionExtend ? (
            item.descriptionExtend.length > 0 ? (
              <MessageBlock>
                <div style={{ fontSize: '12px', color: '#00A796' }}>
                  ข้อความที่ฝากไว้
                </div>
                {item.descriptionExtend.map((item) => (
                  <MessageCancelItem data={item} />
                ))}
              </MessageBlock>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </div>

        <AppButton
          sx={{ width: '128px', height: '32px' }}
          onClick={() => {
            handleEditDescription(valueDescription), setValueDescription('')
          }}
        >
          เพิ่มข้อความ
        </AppButton>
        <ModalDelivery
          open={isDelivery}
          onDimiss={() => setIsDelivery(false)}
          item={{ ...item, senderId: userId }}
          statusChange={statusChange}
          getRedeemOrderList={fetchOrderDetail}
        />
        <ModalDigital
          onDimiss={onDimiss}
          open={open}
          nameProduct={item.productId ? item.productId.name : '-'}
          id={item.id}
          senderId={item.userId ? item.userId : '-'}
          getRedeemOrderList={fetchOrderDetail}
        />

        {/* Modal Edit Point */}
      </WrapperDetail>
    </>
  )
}
