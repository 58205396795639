import React, { useState } from 'react'
import Panel from '../../../component/Panel'
import ImageItem from '../../ProductCatalog/AddNewProduct/ProductImages/ImageItem'

type Props = {
  setDataAdd: any
  dataAdd: any
}

const AddImage = ({ setDataAdd, dataAdd }: Props): JSX.Element => {
  return (
    <>
      <Panel title="หัวข้อ (2/2)  : รูปภาพขยะ ">
        <ImageItem
          images={dataAdd.listImage}
          setSelectedImage={(arrImg) =>
            setDataAdd({ ...dataAdd, listImage: arrImg })
          }
          type="waste"
        />
      </Panel>
    </>
  )
}

export default AddImage
