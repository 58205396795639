import * as React from 'react'
import styled from 'styled-components'

const BlockNotFound = styled.div`
  position: fixed;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const NotFound = (): JSX.Element => {
  return <BlockNotFound>404 Not Found</BlockNotFound>
}

export default NotFound
