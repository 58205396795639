import React, { useEffect, useState } from 'react'
import Layout from '../../layouts/DefaultLayout'
import Typography from '@mui/material/Typography'
import { AppButtonStyle } from '../../features/ProductCatalog/ProductDetail'
import {
  Title,
  Wrapper
} from '../../features/ProductCatalog/ProductList/ProductInfo'
import addIcon from '../../asset/images/Vector1.svg'
import THead from '../../component/THead'
import { SearchBlock } from '../WasteRequest'
import searchIcon from '../../asset/images/Vector2.svg'
import TableBlock from '../../component/TableBlock'
import { PAGE_SIZE, TableCellStyle } from '../ProductCatalog'
import DisplayResult from '../../features/ProductCatalog/ProductList/DisplayResult'
import PaginationSquare from '../../component/PaginationSquare'
import {
  InputSearch,
  ImageSearch
} from '../../features/ProductCatalog/ProductList/SearchFilter'
import { TableBody } from '@mui/material'
import { getAllProvince, getListCustomerAccount } from '../../api'
import ManagerItem from '../../features/AccountManager/AccountManagerItem'
import AccountFillter from '../../features/AccountManager/AccountFillter'
import ProductEmpty from '../../features/ProductCatalog/ProductList/ProductEmpty'
import { useHistory } from 'react-router-dom'
import { SYSTEM_ADMIN_ROUTE } from '../../route/RouteDefinition'
import { customerAccountDetail } from '../../route/RouteDefinition'
import { Box } from '@material-ui/core'
import useDebounce from '../../hook/useDebounce'
import customToast from '../../component/Toast'
import styled from 'styled-components'

const AccountManagement = () => {
  const [total, setTotal] = useState(0)
  const [dataSearch, setDataSearch] = useState<any>({
    start: 1,
    number: PAGE_SIZE
  })
  const [dataList, setDataList] = useState<any>()
  const debouncedSearch = useDebounce(dataSearch, 300)
  const history = useHistory()
  const [provinceList, setProvinceList] = useState([])

  const fetchData = async () => {
    try {
      const customerList = await getListCustomerAccount(debouncedSearch)
      if (!customerList.error) {
        setDataList(customerList.data)
        setTotal(customerList.total)
      }
    } catch (error) {}
  }

  const fetchDataProvince = async () => {
    try {
      const provinceList = await getAllProvince()
      if (!provinceList.error) {
        setProvinceList(provinceList.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchDataProvince()
  }, [])

  useEffect(() => {
    fetchData()
  }, [debouncedSearch])

  return (
    <>
      <Layout>
        <Wrapper>
          <Typography
            sx={{
              fontSize: '3.2rem',
              fontWeight: 'bold'
            }}
          >
            จัดการบัญชีผู้ใช้
          </Typography>

          <AppButtonStyle
            sx={{ width: '269px', mt: '32px' }}
            onClick={() =>
              history.push(SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_CREATE.path)
            }
          >
            <img src={addIcon} />
            <Title>สร้างบัญชีผู้ใช้ใหม่</Title>
          </AppButtonStyle>
        </Wrapper>
        <SearchBlock>
          <InputSearch
            placeholder="ค้นหาด้วย ชื่อ, อีเมล, เบอร์โทรศัพท์"
            value={dataSearch.keyword || ''}
            onChange={(e) =>
              setDataSearch({
                ...dataSearch,
                keyword: e.target.value,
                start: 1
              })
            }
          />
          <ImageSearch src={searchIcon} />
        </SearchBlock>

        <AccountFillter
          dataSearch={dataSearch}
          setDataSearch={setDataSearch}
          provinceList={provinceList}
          setProvinceList={setProvinceList}
        />

        {dataList && dataList.length > 0 ? (
          <>
            <Wrapper>
              <DisplayResult total={total} />
              <PaginationSquare
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
                totalPage={Math.ceil(total / PAGE_SIZE)}
              />
            </Wrapper>

            <TableBlock>
              <THead>
                <TableCellStyle
                  onClick={() =>
                    setDataSearch({
                      ...dataSearch,
                      optionSort: 'lastname',
                      typeSort: !dataSearch.typeSort
                        ? 'ASC'
                        : dataSearch.typeSort === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                    })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  ชื่อ{' '}
                  <span>
                    <img src="./images/resort.svg" />
                  </span>
                </TableCellStyle>
                <TableCellStyle>ข้อมูล</TableCellStyle>
                <TableCellStyle>ประเภทบัญชี</TableCellStyle>
                <TableCellStyle>จังหวัด</TableCellStyle>
                <TableCellStyle
                  onClick={() =>
                    setDataSearch({
                      ...dataSearch,
                      optionSort: 'createdAt',
                      typeSort: !dataSearch.typeSort
                        ? 'ASC'
                        : dataSearch.typeSort === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                    })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  วันที่สร้าง {''}
                  <span>
                    <img src="./images/resort.svg" />
                  </span>
                </TableCellStyle>
                <TableCellStyle>คะแนน</TableCellStyle>
                <TableCellStyle>สถานะ</TableCellStyle>
              </THead>
              <TableBody>
                {dataList.map((item, index) => (
                  <ManagerItem
                    dataItem={item}
                    key={item.id}
                    handleSelect={() => {
                      history.push({
                        pathname: customerAccountDetail(item.id),
                        state: {
                          name: item.firstname.toLowerCase(),
                          dataDetail: item,
                          id: item.id
                        }
                      })
                    }}
                  />
                ))}
              </TableBody>
            </TableBlock>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '8px'
              }}
            >
              <PaginationSquare
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
                totalPage={Math.ceil(total / PAGE_SIZE)}
              />
            </Box>
          </>
        ) : (
          <>
            <DisplayResult total={total} />
            <ProductEmpty type="customer-account" />
          </>
        )}
      </Layout>
    </>
  )
}

export default AccountManagement
