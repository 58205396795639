import Box from '@mui/material/Box'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { verifySendWaste } from '../../api'
import { AppButton } from '../../component/common/AppButton'
import AppInput from '../../component/common/AppInput'
import AppInputLabel from '../../component/common/AppInputLabel'
import { Button } from '../../component/ModalDouleButton'
import { handleType } from '../../function/handleType'
import { InputBlock } from '../../page/Login'
import { Column, ContentBottom, ContentTop } from './DetailRequest/DetailStyle'

export const ModalCheckingToPoint = ({
  open,
  onDimiss,
  id,
  fetchDetail,
  trackingno,
  name
}) => {
  const [quantity, setQuantity] = useState('')

  const handleChange = async () => {
    const res = await verifySendWaste({
      transRefId: Number(id),
      quantity: Number(quantity)
    })
    if (!res.error) {
      setQuantity('')
      fetchDetail()
      onDimiss()
    }
  }

  return (
    <>
      <Modal title="กรุณาใส่น้ำหนักขยะ" visible={open} onCancel={onDimiss}>
        <ContentTop>
          <div>{name}</div>
          <div>{trackingno}</div>
        </ContentTop>
        <ContentBottom>
          <Box
            sx={{
              marginTop: '8px',
              color: '#2C2C2C'
            }}
          >
            <AppInputLabel>น้ำหนักขยะผ่านเกณฑ์</AppInputLabel>
            <InputBlock
              style={{
                width: '160px',
                height: '48px',
                border: `1px solid #CCDDD9`
              }}
            >
              <AppInput
                fullWidth
                type="number"
                placeholder="0"
                onChange={(e) =>
                  e.target.value.length < 15 && setQuantity(e.target.value)
                }
                onKeyDown={(e) => handleType(e)}
                sx={{
                  border: 'none',
                  background: 'transparent'
                }}
                value={quantity}
              />
              <div className="type">กก.</div>
            </InputBlock>
          </Box>
          <Column />

          <div className="earn-point">ได้รับคะแนน</div>
          <div className="number-point">{Number(quantity).toFixed(1)}</div>
          <div className="unit-point">คะแนน</div>
        </ContentBottom>

        <div style={{ display: 'flex', gap: '8px', marginLeft: '64px' }}>
          <AppButton
            sx={{ width: '157px', mt: '32px', height: '32px' }}
            onClick={handleChange}
          >
            ยืนยัน
          </AppButton>

          <Button onClick={onDimiss}>ยกเลิก</Button>
        </div>
      </Modal>
    </>
  )
}
