import React from 'react'
import styled from 'styled-components'
import Panel from '../../../component/Panel'
import { Line } from '../AddNewProduct/ProductImages'
import { Note } from '../AddNewProduct/ProductImages/ImageItem'
import { ContentBlock } from './ContentBlock'
import { BlueTitle } from './ProductInfoDetail'

const ImageBlock = styled.div`
  background: #eff1f4;
  border: 1px solid #ccddd9;
  border-radius: 8px;
  width: 100%;
  height: auto;
  padding: 14px 20px;
  margin: 24px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`

const Image = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
  height: 160px;
  background: #ffffff;
  border: 1px solid #ccddd9;
  box-sizing: border-box;
  border-radius: 4px;
`
export const ProductDescriptionDetail = ({ dataDetail }) => {
  return (
    <Panel title="หัวข้อ (3/4)  : รายละเอียดสินค้า   ">
      <ContentBlock
        title="คำอธิบายสินค้าแบบย่อ"
        content={dataDetail.excerpt || '-'}
        className="content-product"
      />

      <ContentBlock
        title="รายละเอียดสินค้า"
        content={dataDetail.description || '-'}
      />

      <ContentBlock
        title="วิธีการใช้สินค้า (ไม่จำเป็นต้องระบุ)"
        content={dataDetail.instruction || '- '}
      />
      <ContentBlock
        title="ข้อกำหนดเเละเงื่อนไข (ไม่จำเป็นต้องระบุ)"
        content={dataDetail.term || '- '}
      />
    </Panel>
  )
}
