import React from 'react'
import { toast } from 'react-toastify'

const customToast = {
  success: (message: any, options?: any) =>
    toast.success(message, {
      icon: <img src="/icons/success.svg" alt="" />,
      ...options
    }),
  error: (message: any, options?: any) =>
    toast.error(message, {
      icon: <img src="/icons/x.svg" alt="" />,
      ...options
    }),
  warning: (message: string, options?: any) =>
    toast.warning(message, {
      icon: null,
      ...options
    }),
  info: (message: string, options?: any) =>
    toast.info(message, {
      icon: null,
      ...options
    })
}
export default customToast
