import { createSlice } from '@reduxjs/toolkit'

const masterReducer = createSlice({
  name: 'master',
  initialState: {
    configMaster: {
      encryptUser: '',
      encryptPass: '',
      encryptPassConfirm: '',
      characterSeparate: '',
      encryptPublicUser: '',
      encryptPassNew: '',
      regex: {},
      productType: {},
      inventory: {},
      gender: {},
      accountType: {},
      status: {},
      postCode: {},
      statusTransaction: {},
      statusRedeem: {}
    }
  },
  reducers: {
    _updateConfig: (state, action) => {
      state.configMaster = { ...action.payload }
    }
  }
})

const { reducer, actions } = masterReducer
export const { _updateConfig } = actions
export default reducer
