import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import Panel from '../../../component/Panel'
import { TYPE_OF_DATE } from '../../../constants/date'
import { convertToThai } from '../../../utils/func'
import { ContentBlock, WrapperContent } from './ContentBlock'

export const BlueTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #00a796;
  margin-bottom: 24px;
`

const Categories = styled.div`
  padding: 24px 24px 0px;
  .title {
    font-weight: 400;
    font-size: 12px;
    color: #6b7c78;
  }
  .content {
    display: flex;
    gap: 8px;
  }
`

const Label = styled.div`
  background: #ccddd9;
  border-radius: 8px;
  color: #03030c;
  font-weight: 700;
  font-size: 16px;
  padding: 4px;
  width: fit-content;
`

const IconStatus = styled.div`
  width: 12px;
  height: 12px;
  background: ${({ active }) => (active ? '#167335' : '#E5141F')};
  border-radius: 50%;
`

export const ProductInfoDetail = ({ dataDetail }) => {
  return (
    <Panel title="หัวข้อ (1/4)  : ข้อมูลสินค้า   ">
      <BlueTitle>ข้อมูลทั่วไป</BlueTitle>

      <WrapperContent>
        <div className="title">สถานะการใช้งาน</div>
        <div className="content">
          <IconStatus active={dataDetail.status === 'active'} />
          <div>
            {dataDetail.status === 'active' ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
          </div>
        </div>
      </WrapperContent>
      <ContentBlock title="ชื่อสินค้า" content={dataDetail.name} />
      <ContentBlock title="ชนิดสินค้า" content={dataDetail.productType.name} />
      <ContentBlock title="แบรนด์" content={dataDetail.brandId.name} />

      <Categories>
        <div className="title">หมวดหมู่สินค้า</div>
        <div className="content">
          {dataDetail.categories.map((item) => (
            <Label>{item.name}</Label>
          ))}
        </div>
      </Categories>

      {/* value */}
      <ContentBlock title="มูลค่า" content={`${dataDetail.value} คะแนน`} />

      <div style={{ display: 'flex' }}>
        {/* inventory */}
        <ContentBlock title="คลังสินค้า" content={dataDetail.inventory.name} />
        {/* quantity */}
        <ContentBlock
          title="จำนวนสินค้า"
          content={`${dataDetail.quantity} ชิ้น`}
        />
      </div>

      <BlueTitle style={{ paddingTop: '48px' }}>
        ช่วงเวลาที่เเสดงสินค้าหน้าเเอพ
      </BlueTitle>

      <div style={{ display: 'flex' }}>
        <ContentBlock
          title="แสดงตั้งเเต่่"
          content={convertToThai(
            dataDetail.startDate,
            TYPE_OF_DATE.DATE_HOUR_FULL
          )}
        />
        <ContentBlock
          title="แสดงจนถึง"
          content={convertToThai(
            dataDetail.endDate,
            TYPE_OF_DATE.DATE_HOUR_FULL
          )}
        />
      </div>
    </Panel>
  )
}
