import Box from '@mui/material/Box'
import React, { useState } from 'react'
import { updateStatusWasteRequest } from '../../api'
import { AppButton } from '../../component/common/AppButton'
import AppInput from '../../component/common/AppInput'
import AppInputLabel from '../../component/common/AppInputLabel'
import { Modal } from 'antd'
import { handleType } from '../../function/handleType'
import { InputBlock } from '../../page/Login'
import { ContentTop } from './DetailRequest/DetailStyle'
import { Button } from '../../component/ModalDouleButton'

type Props = {
  open: boolean
  onDimiss: () => void
  id: any
  fetchDetail: any
  statusChange: any
  trackingno: any
  name: any
}
export const ModalPendingToReceive = ({
  open,
  onDimiss,
  id,
  fetchDetail,
  statusChange,
  trackingno,
  name
}: Props) => {
  const [value, setValue] = useState('')

  const handleChange = async () => {
    const res = await updateStatusWasteRequest({
      id,
      status: statusChange,
      parcelweight: Number(value)
    })
    if (!res.error) {
      setValue('')
      fetchDetail()
      onDimiss()
    }
  }

  return (
    <>
      <Modal title="กรุณาใส่น้ำหนักขยะ" visible={open} onCancel={onDimiss}>
        <ContentTop style={{ margin: '0 64px' }}>
          <div>{name}</div>
          <div>{trackingno}</div>

          <Box
            sx={{
              marginTop: '32px',
              color: '#2C2C2C'
            }}
          >
            <AppInputLabel>น้ำหนักพัสดุที่ระบุบนกล่อง</AppInputLabel>
            <InputBlock
              style={{
                width: '342px',
                height: '48px',
                border: `1px solid #CCDDD9`
              }}
            >
              <AppInput
                fullWidth
                type="number"
                placeholder="0"
                onChange={(e) =>
                  e.target.value.length < 15 && setValue(e.target.value)
                }
                onKeyDown={(e) => handleType(e)}
                sx={{
                  border: 'none',
                  background: 'transparent'
                }}
                value={value}
              />
              <div className="type">กก.</div>
            </InputBlock>
          </Box>
        </ContentTop>

        <div style={{ display: 'flex', gap: '8px', marginLeft: '64px' }}>
          <AppButton
            sx={{ width: '157px', mt: '32px', height: '32px' }}
            onClick={handleChange}
          >
            ยืนยัน
          </AppButton>

          <Button onClick={onDimiss}>ยกเลิก</Button>
        </div>
      </Modal>
    </>
  )
}
