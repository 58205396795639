import React from 'react'
import { useHistory } from 'react-router-dom'
import { Title } from '../../ProductCatalog/AddNewProduct/ProductImages/ImageItem'
import { AppButtonStyle } from '../../ProductCatalog/ProductDetail'
import {
  ContentField,
  Dot,
  TitleField,
  UserHeader,
  WrapperDetail
} from '../../WasteRequest/DetailRequest/DetailStyle'
import { customerAccountDetail } from '../../../route/RouteDefinition'

export const UserInfo = ({ item }) => {
  const route = useHistory()

  return (
    <>
      <WrapperDetail>
        <Title>ข้อมูลผู้ใช้</Title>

        <UserHeader>
          <img
            src={
              item.userId
                ? item.userId.avatar
                  ? item.userId.avatar
                  : '/images/avt-default.png'
                : '/images/avt-default.png'
            }
            alt="alt"
          />
          <div>
            <TitleField>คะแนนคงเหลือ</TitleField>
            <ContentField>
              {item.userId ? item.userId.balance : '-'} คะแนน
            </ContentField>
          </div>
          <Dot />
        </UserHeader>

        {/* Name*/}
        <TitleField>อีเมล</TitleField>
        <ContentField>{`${item.userId ? item.userId.firstname : '-'} ${
          item.userId ? item.userId.lastname : '-'
        }`}</ContentField>

        {/* Email*/}
        <TitleField>ชื่อ</TitleField>
        <ContentField>​{item.userId ? item.userId.email : '-'}</ContentField>

        {/* Phone*/}
        <TitleField>เบอร์โทร</TitleField>
        <ContentField>{item.userId ? item.userId.phone : '-'}</ContentField>

        <div style={{ display: 'flex', gap: '80px' }}>
          <div>
            {/* Gender*/}
            <TitleField>เพศ</TitleField>
            <ContentField>
              {item.userId
                ? item.userId.gender
                  ? item.userId.gender.name
                  : '-'
                : '-'}
            </ContentField>
          </div>
          <div>
            {/* age*/}
            <TitleField>อายุ</TitleField>
            <ContentField>{item.userId ? item.userId.age : '--'}</ContentField>
          </div>
        </div>

        {/* Location*/}
        <TitleField>จังหวัด</TitleField>
        <ContentField>
          {item.userId
            ? item.userId.province
              ? item.userId.province.name
              : '-'
            : '--'}
        </ContentField>

        {/* Address*/}
        <TitleField>ที่อยู่</TitleField>
        <ContentField>{item.userId ? item.userId.address : '--'}</ContentField>

        {/* Total waste weight*/}
        <TitleField>น้ำหนักขยะที่ส่งมาทั้งหมด</TitleField>
        <ContentField>
          {item.userId ? item.userId.totalWeight : '--'} กิโลกรัม
        </ContentField>

        {/* go to user account */}
        <AppButtonStyle
          sx={{ width: '100%', mt: '32px' }}
          onClick={() =>
            route.push({
              pathname: customerAccountDetail(item.userId.id),
              state: {
                id: item.userId.id,
                name: item.userId.firstname.toLowerCase()
              }
            })
          }
        >
          <img src="/images/user-icon.png" alt="usder" />
          ไปที่บัญชีผู้ใช้
        </AppButtonStyle>
      </WrapperDetail>
    </>
  )
}
