export const convertToThai = (date, type) => {
  const event = new Date(date)
  const optionsDate = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
  const optionsHour = {
    hour: 'numeric',
    minute: 'numeric'
  }
  switch (type) {
    case 'HOUR_ONLY':
      return event.toLocaleString('th-TH', optionsHour)
    case 'DATE_ONLY':
      return event.toLocaleString('th-TH', optionsDate)
    case 'DATE_HOUR_FULL':
      return `${event.toLocaleString(
        'th-TH',
        optionsDate
      )}, ${event.toLocaleString('th-TH', optionsHour)}`
    default:
      break
  }
}
