import atob from 'atob'

import * as crypto from 'crypto'
export const encryptCryptoRsa = (value, keySecret) => {
  try {
    if (!value || !keySecret) throw ''

    keySecret = atob(keySecret)
    const encrypted = crypto.publicEncrypt(
      keySecret,
      Buffer.from(value, 'utf8')
    )
    return encrypted.toString('base64')
  } catch (error) {
    throw ''
  }
}

export const decryptCryptoRsa = (value, key) => {
  try {
    if (!value || !key) throw ''
    value = Buffer.from(value, 'base64')
    const key1 = atob(key)
    const decrypted = crypto.privateDecrypt(
      {
        key: key1,
        padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
        oaepHash: 'sha256'
      },
      value
    )
    return decrypted.toString('utf8')
  } catch (error) {
    return ''
  }
}
