import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  cancleRequestRedeem,
  getRedeemDetail,
  updateDescriptionRedeemRequest
} from '../../../api'
import Header from '../../../component/Header'
import ModalDoubleButton from '../../../component/ModalDouleButton'
import ModalError from '../../../component/ModalError'
import Layout from '../../../layouts/DefaultLayout'
import { Content } from '../../../page/ForgotPassword'
import { SYSTEM_ADMIN_ROUTE } from '../../../route/RouteDefinition'
import { Title } from '../../ProductCatalog/AddNewProduct'
import { AppButtonStyle } from '../../ProductCatalog/ProductDetail'
import { ContentDetail } from '../../WasteRequest/DetailRequest'
import { BrandInfo } from './BrandInfo'
import { OrderInfo } from './OrderInfo'
import { UserInfo } from './UserInfo'

export const DetailRedeem = () => {
  const [detailOrder, setDetailOrder] = useState({})
  const route = useHistory()
  const [isCancel, setIsCancel] = useState(false)
  const [isError, setIsError] = useState(false)

  const idWaste = route.location.pathname.split('/')[3]

  const { userId } = detailOrder

  const fetchOrderDetail = async () => {
    const res = await getRedeemDetail({ id: idWaste })

    if (!res.error) {
      setDetailOrder(res.data)
    }
  }

  const handleCancel = async () => {
    setIsCancel(false)
    const res = await cancleRequestRedeem({ id: detailOrder.id })
    if (!res.error) {
      fetchOrderDetail()
    }
  }

  const handleEditDescription = async (description) => {
    const res = await updateDescriptionRedeemRequest({
      id: Number(detailOrder.id),
      description
    })
    if (!res.error) {
      fetchOrderDetail()
    } else {
      return
    }
  }

  useEffect(() => {
    fetchOrderDetail()
  }, [])

  return (
    <>
      <Layout>
        <Header>
          <div
            className="title-root"
            onClick={() =>
              route.push(SYSTEM_ADMIN_ROUTE.REDEEM_ORDER_LIST.path)
            }
          >
            รายการออเดอร์แลกของ{' '}
          </div>
          <div className="title-sub">
            / ออเดอร์แลกของ : {userId ? userId.firstname : ''}
          </div>
        </Header>
        <Title>รายละเอียดออเดอร์แลกของ</Title>
        <ContentDetail>
          <OrderInfo
            item={detailOrder}
            setIsCancel={setIsCancel}
            handleEditDescription={handleEditDescription}
            fetchOrderDetail={fetchOrderDetail}
          />
          <div>
            <UserInfo item={detailOrder} />
            <BrandInfo item={detailOrder} />
          </div>
        </ContentDetail>
        <AppButtonStyle
          sx={{ width: '200px', mt: '32px' }}
          onClick={() => route.push(SYSTEM_ADMIN_ROUTE.REDEEM_ORDER_LIST.path)}
        >
          <img src="/images/back.png" alt="back" />
          กลับ
        </AppButtonStyle>
        <ModalDoubleButton
          title="ยกเลิกออเดอร์"
          open={isCancel}
          onDimiss={() => setIsCancel(false)}
          handleButtonGreen={() => handleCancel()}
          handleButtonWhite={() => setIsCancel(false)}
          textButtonGreen="ยกเลิก"
          textButtonWhite="ออก"
        >
          <Content>คุณแน่ใจว่าจะยกเลิกออเดอร์นี้ไหม</Content>
        </ModalDoubleButton>

        <ModalError
          content="เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้งภายหลัง"
          open={isError}
          onDimiss={() => setIsError(false)}
        />
      </Layout>
    </>
  )
}
