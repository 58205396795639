import React, { useState } from 'react'
import styled from 'styled-components'
import { TableCell, TableRow, Theme } from '@mui/material'
import ModalDoubleButton from '../../../component/ModalDouleButton'
import { Content } from '../../../page/ForgotPassword'
import ModalBasic from '../../../component/ModalBasic'
import { setAdminStatus, resetPassWordAdmin } from '../../../api'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { PUBLIC_ROUTE } from '../../../route/RouteDefinition'
import { useDispatch } from 'react-redux'
import { globalLoading } from '../../../store/loadingSlice'
import { convertToThai } from '../../../utils/func'
import { TYPE_OF_DATE } from '../../../constants/date'

const Text = styled.span`
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #2c2c2c;
`

const ActiveStatus = styled.div`
  width: 16px;
  height: 16px;
  background: ${({ active }) => (active ? '#167335' : '#B9BAC2')};
  border-radius: 50%;
  margin-right: 8px;
`

const Status = styled.span`
  font-size: 1.4rem;
  color: #000000;
  font-weight: 700;
`

const TableCellStyle = styled(TableCell)`
  font-size: 1.4rem !important;
  color: #727272;
`
const ButtonLock = styled.button`
  position: relative;
  height: 40px;
  width: 40px;
  background: #00a796;
  border-radius: 4px;
  .lock {
    position: absolute;
    right: 17px;
    top: 15px;
  }
  .arrow {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`

const ButtonActive = styled.button`
  height: 40px;
  width: 40px;
  background: ${({ active }) => (active ? '#00a796' : '#DADADA')};
  border-radius: 4px;
  .active {
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .inactive {
    position: absolute;
    top: 10px;
    right: 9px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
`

const BlockStatus = styled.div`
  display: flex;
  align-items: center;
`
const Link = styled.div`
  color: #00a796;
  text-decoration-line: underline;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 24px;
  cursor: pointer;
`

const AdminItem = ({ item, roleList }): JSX.Element => {
  const [isNoti, setIsNoti] = useState(false)
  const [isDeactive, setIsDeactive] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [id, setId] = useState(null)
  const [idReset, setIdReset] = useState(null)
  const route = useHistory()
  const dispatch = useDispatch()

  const handleSetStatus = async (status: any) => {
    let res = await setAdminStatus({ id, status })

    if (!res.error) {
      setIsDeactive(false)
      setIsActive(false)
      location.reload()
    }
  }

  const handleResetPassWord = async (idReset: any) => {
    dispatch(globalLoading(true))
    try {
      let res = await resetPassWordAdmin({ id: idReset })
      if (res.error == 0) {
        dispatch(globalLoading(false))
        setIsNoti(true)
      } else {
        dispatch(globalLoading(false))
      }
    } catch (error) {
      console.log(`e`, error)
      dispatch(globalLoading(false))
    }
  }

  const contentNoti = (
    <>
      <div>กรุณาเข้าลิงก์ที่ส่งไปในอีเมล เพื่อตั้งรหัสผ่านใหม่</div>
      <Link onClick={() => handleResetPassWord(idReset)}>ส่งอีเมลอีกครั้ง</Link>
    </>
  )

  return (
    <>
      <TableRow
        sx={{
          verticalAlign: 'top',
          background: (theme: Theme) => theme.color.white,
          borderBottom: '1px solid #CCDDD9',
          '&': {
            transition: '.3s ease'
          },
          '&:last-child': {
            borderBottom: 'none'
          }
        }}
      >
        <TableCellStyle>
          <BlockStatus>
            <ActiveStatus active={item.status === 'active'} />
            <Text>{item.email}</Text>
          </BlockStatus>
        </TableCellStyle>

        <TableCellStyle>
          <Text>{item.userRole ? item.userRole.name : ''}</Text>
        </TableCellStyle>

        <TableCellStyle>
          <Text>{`${item.firstname} ${item.lastname}`}</Text>
        </TableCellStyle>

        <TableCellStyle>
          <Text>
            {item.createdAt
              ? convertToThai(item.createdAt, TYPE_OF_DATE.DATE_HOUR_FULL)
              : ''}
          </Text>
        </TableCellStyle>

        <TableCellStyle>
          <Text>
            {item.lastOnlineAt
              ? convertToThai(item.lastOnlineAt, TYPE_OF_DATE.DATE_HOUR_FULL)
              : ''}
          </Text>
        </TableCellStyle>
        <TableCellStyle>
          <ButtonGroup>
            <button
              onClick={() => {
                setIdReset(item.id)
                handleResetPassWord(item.id)
              }}
              style={{
                height: '40px',
                width: '40px',
                background: item.status === 'active' ? '#00a796' : '#DADADA',
                borderRadius: '4px'
              }}
              disabled={item.status !== 'active'}
            >
              <Tippy
                interactive={false}
                content="ตั้งรหัสผ่านใหม่"
                maxWidth={'150px'}
                offset={[30, 10]}
                className="icon-active"
                placement="top"
              >
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    position: 'relative'
                  }}
                >
                  <img
                    src="/icons/arrow.svg"
                    alt=""
                    className="arrow"
                    style={{ position: 'absolute', right: '8px', top: '10px' }}
                  />
                  <img
                    src="/icons/lock1.svg"
                    alt=""
                    className="lock"
                    style={{ position: 'absolute', right: '17px', top: '16px' }}
                  />
                </div>
              </Tippy>
            </button>

            <ButtonActive
              active={item.status === 'active'}
              onClick={() => {
                setId(item.id)
                item.status === 'active'
                  ? setIsDeactive(true)
                  : setIsActive(true)
              }}
            >
              <Tippy
                offset={[30, 10]}
                interactive={false}
                content={
                  item.status === 'active' ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'
                }
                className="icon-active"
              >
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    position: 'relative'
                  }}
                >
                  {item.status === 'active' ? (
                    <img src="/icons/activated.svg" alt="" className="active" />
                  ) : (
                    <img
                      src="/icons/inactive.svg"
                      alt=""
                      className="inactive"
                    />
                  )}
                </div>
              </Tippy>
            </ButtonActive>
          </ButtonGroup>
        </TableCellStyle>
      </TableRow>
      <ModalDoubleButton
        title="ยืนยันปิดการใช้งานบัญชีเเอทมิน"
        open={isDeactive}
        onDimiss={() => setIsDeactive(false)}
        handleButtonGreen={() => handleSetStatus('inactive')}
        handleButtonWhite={() => setIsDeactive(false)}
        textButtonGreen="ยืนยัน"
        textButtonWhite="ยกเลิก"
      >
        <Content>
          ยืนยืนการปิดใช้งานบัญชีเเอทมินนี้ไหม? หลังจากปิดการใช้งานบัญชีนี้{' '}
          <br /> จะไม่สามารถเข้าสู่ระบบได้จนกว่าจะเปิดการใช้งานอีกครั้งหนึ่ง
        </Content>
      </ModalDoubleButton>

      <ModalDoubleButton
        title="ยืนยันเปิดการใช้งานบัญชีเเอทมิน"
        open={isActive}
        onDimiss={() => setIsActive(false)}
        handleButtonGreen={() => handleSetStatus('active')}
        handleButtonWhite={() => setIsActive(false)}
        textButtonGreen="ยืนยัน"
        textButtonWhite="ยกเลิก"
      >
        <Content>
          ยืนยืนการเปิดใช้งานบัญชีเเอทมินนี้ไหม? <br />{' '}
          บัญชีนี้จะกลับมาใช้งานได้ภายใน 24 ชั่วโมงหลังจากเปิดการใช้งาน
        </Content>
      </ModalDoubleButton>

      <ModalBasic
        title="ส่งลิงก์ตั้งรหัสผ่านใหม่ไปที่อีเมลเเล้ว"
        open={isNoti}
        onDimiss={() => setIsNoti(false)}
      >
        <Content>{contentNoti}</Content>
      </ModalBasic>
    </>
  )
}

export default AdminItem
