import Box from '@mui/material/Box'
import React from 'react'
import styled from 'styled-components'
import AppInput from '../../../component/common/AppInput'
import AppInputLabel from '../../../component/common/AppInputLabel'
import Panel from '../../../component/Panel'
import { handleType } from '../../../function/handleType'
import { checkEmptyString } from '../../../utils/theme'
import { Required } from './ProductInfo'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`
export const ContactDetail = ({ setDataAdd, dataAdd, isCheck }) => {
  return (
    <>
      <Panel title="หัวข้อ (4/4)  : ช่องทางการติดต่อแบรนด์   ">
        <Wrapper>
          <div style={{ width: '48%' }}>
            <Box
              sx={{
                color: '#2C2C2C',
                width: '100%',
                fontSize: '12px'
              }}
            >
              <AppInputLabel>เบอร์โทรศัพท์</AppInputLabel>
              <AppInput
                fullWidth
                type="number"
                placeholder="ใส่เบอร์โทรศัพท์"
                sx={{
                  fontSize: '16px'
                }}
                onChange={(e) =>
                  setDataAdd({ ...dataAdd, phone: e.target.value })
                }
                onKeyDown={(e) => handleType(e)}
                value={dataAdd.phone}
                // errors={isCheck && checkEmptyString(dataAdd.phone)}
              />
              {/* {isCheck && checkEmptyString(dataAdd.phone) && (
              <Required style={{ position: 'absolute' }}>
                จำเป็นต้องระบุ
              </Required>
            )} */}
            </Box>

            <Box
              sx={{
                marginTop: '32px',
                color: '#2C2C2C',
                width: '100%',
                fontSize: '12px'
              }}
            >
              <AppInputLabel>ลิงก์ Line ID (ไม่จำเป็นต้องระบุ)</AppInputLabel>
              <AppInput
                fullWidth
                type="text"
                placeholder="ใส่ลิงก์ไลน์ไอดี"
                sx={{
                  fontSize: '16px'
                }}
                onChange={(e) =>
                  setDataAdd({ ...dataAdd, lineID: e.target.value })
                }
                value={dataAdd.lineID}
              />
            </Box>

            <Box
              sx={{
                marginTop: '32px',
                color: '#2C2C2C',
                width: '100%',
                fontSize: '12px'
              }}
            >
              <AppInputLabel>ลิงก์ Instagram</AppInputLabel>
              <AppInput
                fullWidth
                type="text"
                placeholder="ใส่ลิงก์อินสตาเเกรม"
                sx={{
                  fontSize: '16px'
                }}
                onChange={(e) =>
                  setDataAdd({ ...dataAdd, instagram: e.target.value })
                }
                value={dataAdd.instagram}
              />
            </Box>
          </div>

          <div style={{ width: '48%' }}>
            <Box
              sx={{
                color: '#2C2C2C',
                width: '100%',
                fontSize: '12px'
              }}
            >
              <AppInputLabel>อีเมล (ไม่จำเป็นต้องระบุ)</AppInputLabel>
              <AppInput
                fullWidth
                type="text"
                placeholder="ใส่อีเมล"
                sx={{
                  fontSize: '16px'
                }}
                onChange={(e) =>
                  setDataAdd({ ...dataAdd, email: e.target.value })
                }
                value={dataAdd.email}
              />
            </Box>

            <Box
              sx={{
                marginTop: '32px',
                color: '#2C2C2C',
                width: '100%',
                fontSize: '12px'
              }}
            >
              <AppInputLabel>ลิงก์ Facebook (ไม่จำเป็นต้องระบุ)</AppInputLabel>
              <AppInput
                fullWidth
                type="text"
                placeholder="ใส่ลิงก์เฟสบุค"
                sx={{
                  fontSize: '16px'
                }}
                onChange={(e) =>
                  setDataAdd({ ...dataAdd, facebook: e.target.value })
                }
                value={dataAdd.facebook}
              />
            </Box>

            <Box
              sx={{
                marginTop: '32px',
                color: '#2C2C2C',
                width: '100%',
                fontSize: '12px'
              }}
            >
              <AppInputLabel>ลิงก์เวปไซต์ (ไม่จำเป็นต้องระบุ)</AppInputLabel>
              <AppInput
                fullWidth
                type="text"
                placeholder="ใส่ลิงก์เวปไซต์"
                sx={{
                  fontSize: '16px'
                }}
                onChange={(e) =>
                  setDataAdd({ ...dataAdd, website: e.target.value })
                }
                value={dataAdd.website}
              />
            </Box>
          </div>
        </Wrapper>
      </Panel>
    </>
  )
}
