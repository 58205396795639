import { createSlice } from '@reduxjs/toolkit'
import { KEY } from '../constants/const'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuth: undefined
  },
  reducers: {
    _login: (state, action) => {
      state.isAuth = { ...action.payload }
    },
    _logout: (state) => {
      localStorage.removeItem(KEY.ACCESS_TOKEN)
      // state.auth = {
      //   isLogged: false
      // }
    }
  }
})

const { reducer, actions } = authSlice
export const { _login, _logout } = actions
export default reducer
