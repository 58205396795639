import React from 'react'
import styled from 'styled-components'
import { Title } from '../../ProductCatalog/AddNewProduct/ProductImages/ImageItem'
import {
  ContentField,
  ContentFieldBrand,
  TitleField,
  WrapperDetail
} from '../../WasteRequest/DetailRequest/DetailStyle'
import { ImgProduct } from '../AddRedeemRequest/AddProductInfo'

export const BrandInfo = ({ item }) => {
  return (
    <>
      <WrapperDetail style={{ marginTop: '8px' }}>
        <Title>ข้อมูลแบรนด์</Title>

        {/* Brand name */}
        <BlockBrand>
          <div>
            <TitleField style={{ marginTop: '0' }}>แบรนด์</TitleField>
            <ContentField>
              {item.brandId ? item.brandId.name : '-'}
            </ContentField>
          </div>
          <ImgProduct>
            <img
              src={
                item.productId
                  ? item.productId.logo
                  : '/images/product-example.png'
              }
            />
          </ImgProduct>
        </BlockBrand>

        {/* Phone */}
        <TitleField>เบอร์โทรศัพท์</TitleField>
        <ContentFieldBrand>
          {item.productId
            ? item.productId.phone
              ? item.productId.phone
              : '-'
            : '-'}
        </ContentFieldBrand>
        {/* Line Id */}
        <TitleField>Line ID</TitleField>
        <ContentFieldBrand>
          {item.productId
            ? item.productId.lineID
              ? item.productId.lineID
              : '-'
            : '-'}
        </ContentFieldBrand>
        {/* FB */}
        <TitleField>Facebook</TitleField>
        <ContentFieldBrand>
          {item.productId
            ? item.productId.facebook
              ? item.productId.facebook
              : ' -'
            : '-'}
        </ContentFieldBrand>
      </WrapperDetail>
    </>
  )
}

const BlockBrand = styled.div`
  display: flex;
  gap: 64px;
  margin-top: 24px;
`
