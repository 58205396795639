import { Box } from '@mui/material'
import React from 'react'
import Panel from '../../../../component/Panel'
import { AreaFieldAdd } from './AreaFieldAdd'
import styled from 'styled-components'
import AppInputLabel from '../../../../component/common/AppInputLabel'
import { checkEmptyString } from '../../../../utils/theme'

export const ProductDescription = ({ setDataAdd, dataAdd, isCheck }) => {
  return (
    <>
      <Panel title="หัวข้อ (3/4)  : รายละเอียดสินค้า   ">
        <AreaFieldAdd
          title="รายละเอียดแบบสั้น"
          placeholder="ใส่รายละเอียดแบบสั้น"
          maxLength={150}
          minRow={4}
          handleChange={(e) =>
            setDataAdd({ ...dataAdd, excerpt: e.target.value })
          }
          value={dataAdd.excerpt || ''}
          error={isCheck && checkEmptyString(dataAdd.excerpt)}
          isMarginTop={false}
        />

        <AreaFieldAdd
          title="รายละเอียดสินค้า"
          placeholder="ใส่รายละเอียดสินค้า"
          maxLength={750}
          minRow={7}
          handleChange={(e) =>
            setDataAdd({ ...dataAdd, description: e.target.value })
          }
          value={dataAdd.description || ''}
          error={isCheck && checkEmptyString(dataAdd.description)}
          isMarginTop={true}
        />
        <AreaFieldAdd
          title="วิธีการใช้สินค้า (ไม่จำเป็นต้องระบุ)"
          placeholder="ใส่วิธีใช้สินค้า"
          maxLength={150}
          minRow={4}
          handleChange={(e) =>
            setDataAdd({ ...dataAdd, instruction: e.target.value })
          }
          value={dataAdd.instruction || ''}
          isMarginTop={true}
        />
        <Box sx={{ marginTop: '40px' }}>
          <AppInputLabel>
            ข้อกำหนดเเละเงื่อนไข (ไม่จำเป็นต้องระบุ)
          </AppInputLabel>
          <TextAreaWrapper>
            <AreaFieldAdd
              title="ข้อกำหนดเเละเงื่อนไข (ไม่จำเป็นต้องระบุ)"
              placeholder="ใส่ข้อกำหนดเเละเงื่อนไข"
              minRow={7}
              handleChange={(e) =>
                setDataAdd({ ...dataAdd, term: e.target.value })
              }
              value={dataAdd.term || ''}
              isTerm={true}
              isMarginTop={true}
            />
          </TextAreaWrapper>
        </Box>
      </Panel>
    </>
  )
}

const TextAreaWrapper = styled.div`
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #ccddd9;
  width: 746px;
  & div {
    width: unset;
    margin-top: unset;
  }
  & textarea {
    width: unset;
    border: none;
    padding: unset;
  }
  & textarea:focus {
    outline: none;
  }
`
