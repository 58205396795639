import axios, { Method } from 'axios'
import { KEY } from '../constants/const'
import { deviceDetec } from '../deviceDetec/deviceDetector'

export const request = async (
  method: Method,
  url: string,
  data?: any,
  type?: 'Bearer',
  formData?: any
) => {
  let fullUrl = process.env.REACT_APP_API_URL + url
  let realMethod = method.toUpperCase()
  if (['GET', 'DELETE'].includes(realMethod)) {
    fullUrl = dataToParams(fullUrl, data)
  }

  let accessToken = localStorage.getItem(KEY.ACCESS_TOKEN)
  let configs: any = {
    method: method,
    url: fullUrl,
    data: realMethod === 'POST' ? data : {},
    headers: {
      device: JSON.stringify(deviceDetec),
      Authorization:
        accessToken && !type
          ? `Bearer ${accessToken}`
          : `Basic ${process.env.REACT_APP_TOKEN}`,
      'Accept-Language': 'th'
    }
  }

  if (formData) {
    let bodyFormData = new FormData()

    bodyFormData.append('file', data)
    configs = {
      method: method,
      url: fullUrl,
      data: bodyFormData,
      headers: {
        device: JSON.stringify(deviceDetec),
        Authorization:
          accessToken && !type
            ? `Bearer ${accessToken}`
            : `Basic ${process.env.REACT_APP_TOKEN}`,
        'Accept-Language': 'en'
      }
    }
  }

  try {
    const request = await axios.request(configs)
    if (request.data.logout) {
      localStorage.removeItem(KEY.ACCESS_TOKEN)
      localStorage.removeItem('expiredAtUTC')
    }
    return request.data
  } catch (error: any) {
    if (error.response) {
      return error.response.data
    }
  }
}

const dataToParams = (url: string, data: any) => {
  let str = ''
  if (Object.keys(data).length > 0) {
    str = Object.keys(data)
      .map(function (key) {
        return key + '=' + data[key]
      })
      .join('&')
  }

  return url + '?' + str
}
