import styled from '@emotion/styled'
import { Pagination, Stack } from '@mui/material'
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#00A796',
        color: 'white',
        fontSize: '16px'
      }
    },
    '& li': {
      '& button': {
        borderRadius: 0,
        margin: 0,
        fontSize: '16px'
      }
    }
  }
}))

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
`
const PaginationSquare = ({ dataSearch, setDataSearch, totalPage }) => {
  const classes = useStyles()
  return (
    <Wrapper>
      <Stack spacing={2}>
        <Pagination
          count={totalPage}
          shape="rounded"
          sx={{ background: '#fff' }}
          classes={{
            root: classes.ul
          }}
          page={dataSearch.start}
          onChange={(event: React.ChangeEvent<unknown>, value: number) =>
            setDataSearch({ ...dataSearch, start: value })
          }
        />
      </Stack>
    </Wrapper>
  )
}

export default PaginationSquare
