import { Select } from 'antd'
import React from 'react'
import styled from 'styled-components'

type Props = {
  width?: number
  options: any
  handleSelect?: any
  type: string
  placeholder?: string
  value?: any
  error?: boolean
  fontSize?: string
  waste?: boolean
  isStatus?: boolean
  disabled?: boolean
}

const SelectSimple = ({
  width,
  options,
  handleSelect,
  type,
  placeholder,
  value,
  error,
  fontSize,
  waste,
  isStatus,
  disabled
}: Props) => {
  return (
    <Select
      value={value || null}
      placeholder={placeholder}
      disabled={disabled}
      style={{
        width: width,
        fontSize: fontSize
        // border: `2px solid ${error ? '#FD4A1E' : ''}`
      }}
      onChange={(item) => handleSelect(item)}
      className={(error && `error-input`) || (waste && `waste-select`)}
      onClick={(e) => e.stopPropagation()}
    >
      {options.map((option) => (
        <Select.Option key={type === 'value' ? option.value : option.id}>
          {option.name || option.value || option.country}
        </Select.Option>
      ))}
    </Select>
  )
}

export default SelectSimple
