import React from 'react'
import styled from 'styled-components'

export const WrapperContent = styled.div`
  padding: 24px 24px 0;
  word-break: break-all;
  .title {
    font-weight: 400;
    font-size: 12px;
    color: #6b7c78;
  }

  .content {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`
type Props = {
  title: string
  content: any
  className?: any
}
export const ContentBlock = ({ title, content, className }: Props) => {
  return (
    <WrapperContent className={className}>
      <div className="title">{title}</div>
      <div className="content">{content}</div>
    </WrapperContent>
  )
}
