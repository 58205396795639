import { createTheme } from '@mui/material/styles'
import { ThemeOptions as ThemeOptionsOld } from '@mui/material/styles/createTheme'
import { KEY } from '../constants/const'

// LINE_HEIGHT
const LINE_HEIGHT = '24px'

// Colors
const themeColors = {
  size: {
    menu: '100%'
  },
  color: {
    mainBg: '#03030C',
    ellipse3: '#2C2C2C',
    ellipse4: '#E3E3E3',
    ellipse5: 'linear-gradient(90deg, #32D384 0%, #029586 100%)',
    teal: '#00A796',
    deepTeal: '#02665C',

    //gray
    gray1: '#E5E5E5',
    gray3: '#CCDDD9',
    gray7: '#6B7C78',
    //main
    midnight: '#03030C',

    error: '#E5141F',

    black: '#000',
    blackStone: '#2C2C2C',
    white: '#FFF',
    grey: '#444444',
    red: '#FD4A1E'
  }
} as const

// Override style Mui
const themeOptions: ThemeOptionsOld = {
  ...themeColors,
  // breakpoints
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900, // 900
      lg: 1300, // 1200
      xl: 1536
    }
  },
  //
  palette: {
    primary: {
      main: themeColors.color.teal
    },
    secondary: {
      main: themeColors.color.teal
    }
    // error: {
    //   // main: red.A400,
    // },
  },
  //
  typography: {
    fontSize: 14,
    fontFamily: 'Noto Sans Thai'
  },
  //
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          // background: themeColors.color.bg3,
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '14px',
          lineHeight: LINE_HEIGHT,
          padding: 0,
          margin: 0,

          '& .MuiFormControlLabel-label.MuiTypography-root': {
            fontSize: '14px'
          }
        },
        a: {
          color: 'inherit',
          textDecoration: 'none'
        },
        '*': {
          boxSizing: 'border-box'
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        // fixed: false,
        disableGutters: true
      },
      styleOverrides: {
        fixed: {
          maxWidth: 'auto'
        },
        root: {
          maxWidth: '1184px'
        }
      }
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          // color: '#000!important',
          '&&&:hover': {
            // backgroundColor: 'transparent',
          },

          '&.Mui-disabled': {
            color: '#B1B5C3'
          }
        }
      }
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          // color: themeColors.color.main,
          '&&&:hover': {
            background: 'none'
          },
          '&.Mui-checked': {
            // color: themeColors.color.main,
          }
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E6E8EC'
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#E6E8EC'
            }
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E6E8EC',
            borderWidth: 1
          }
        }
      }
    },

    MuiInputBase: {
      styleOverrides: {
        adornedStart: {
          height: 40,
          borderRadius: '40px !important',
          fontSize: 12
        },
        root: {
          // color: themeColors.color.gray6,
          lineHeight: LINE_HEIGHT,
          '&:before': {
            border: 'none !important'
          },
          '&:after': {
            border: 'none !important'
          }
        }
      }
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#fff',
          color: '#000',
          boxShadow: 'none',
          borderBottom: '1px solid',
          // borderColor: themeColors.color.border2,
          marginTop: '0px'
        }
      }
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#1652f0'
        }
      }
    },

    MuiTab: {
      styleOverrides: {
        root: {
          padding: 27,
          textTransform: 'unset',
          backgroundColor: '#fff',
          '&.Mui-selected': {
            color: '#1652f0'
          }
        }
      }
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root': {
            // color: themeColors.color.text6,
            padding: '11px 16px',
            fontWeight: 500,
            '&:hover': {
              // backgroundColor: `${themeColors.color.hover} !important`,
            },
            '&.Mui-selected': {
              // backgroundColor: themeColors.color.hover,
            }
          }
        },
        rounded: {
          marginTop: '10px',
          borderRadius: '12px',
          boxShadow: '0px 16px 64px -11px rgba(31, 47, 70, 0.15)'
        }
      }
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14
        }
      }
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '72px !important',

          '@media (min-width: 900px)': {
            minHeight: '76px !important'
          }
        }
      }
    },

    MuiRadio: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          // color: themeColors.color.main,

          '&+.MuiFormControlLabel-label': {
            fontWeight: 500
            // color: themeColors.color.gray6,
          },
          '&.Mui-checked+.MuiFormControlLabel-label': {
            // color: themeColors.color.main,
          }
        }
      }
    },

    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 'bold',
          fontSize: '64px'
        },
        h2: {
          fontWeight: 'bold',
          fontSize: '48px',
          lineHeight: '56px'
        },
        h3: {
          fontWeight: 'bold',
          fontSize: '40px',
          lineHeight: '72px'
        },
        h4: {
          color: themeColors.color.black,
          fontSize: '2.8rem',
          fontWeight: 400,
          textAlign: 'center'
        },
        h5: {
          fontWeight: 'bold',
          fontSize: '24px',
          lineHeight: '32px'
        },
        body1: {
          fontSize: '16px'
        },
        body2: {
          fontSize: '14px'
        }
      }
    }
  }
}

// Update for Typescript
type CustomTheme = {
  [Key in keyof typeof themeColors]: typeof themeColors[Key]
}
declare module '@mui/material/styles/createTheme' {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

// Create theme
export const theme = createTheme({ ...themeColors, ...themeOptions })

export function shortenString(string = '', start) {
  if (string === '-') {
    return '-'
  }
  if (string.length <= 20) {
    return string
  }
  return `${string.substring(0, start)}...`
}

export const remove = () => {
  localStorage.removeItem(KEY.ACCESS_TOKEN)
  localStorage.removeItem('expiredAtUTC')
}

export const checkEmptyString = (data: any) => {
  return !data || String(data).trim() === ''
}

export const toBuddhistYear = (moment: any, format: any) => {
  var christianYear = moment.format('YYYY')
  var buddhishYear = (parseInt(christianYear) + 543).toString()
  return moment
    .format(
      format
        .replace('YYYY', buddhishYear)
        .replace('YY', buddhishYear.substring(2, 4))
    )
    .replace(christianYear, buddhishYear)
}
