import styled from '@emotion/styled'
import { Box } from '@mui/material'
import React from 'react'
const Number = styled.span`
  padding: 0 6px;
  font-weight: 700;
  font-size: 1.6rem;
`
const DisplayResult = ({ total }) => {
  return (
    <Box>
      <span style={{ fontSize: '1.6rem', color: '#444' }}>ผลลัพธ์</span>
      <Number>{total}</Number>
      <span style={{ fontSize: '1.6rem', color: '#444' }}>รายการ</span>
    </Box>
  )
}

export default DisplayResult
