import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import React, { useEffect, useState } from 'react'
import {
  handleTime,
  Time
} from '../../ProductCatalog/AddNewProduct/ProductInfo'
import SelectSimple from '../../../component/SelectSimple'
import { deliveryList, provinceList } from '../../../api'
import { PAGE_SIZE } from '../../../page/ProductCatalog'
import { useAppMaster } from '../../../store/_hook'
import moment from 'moment'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 8px;
  padding-top: 16px;
`
type Props = {
  dataSearch: any
  setDataSearch: any
}

const handleSelectedStatus = (status, listStatus) => {
  const result = listStatus.filter((item) => item.value === status)

  return result[0].name
}

export const handleTimeDate = (startDate) => {
  const dateObj = new Date(startDate)

  const month =
    dateObj.getMonth() + 1 > 9
      ? dateObj.getMonth() + 1
      : `0${dateObj.getMonth() + 1}`
  const day =
    dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`

  return dateObj.getFullYear() + '/' + month + '/' + day
}

const FilterWaste = ({ dataSearch, setDataSearch }: Props) => {
  const [dataDeliveryList, setDataDeliveryList] = useState<any>([])
  const [dataProvinceList, setDataProvinceList] = useState<any>([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const {
    state: { configMaster }
  } = useAppMaster()

  const fetchDeliveryAndProvince = async () => {
    const resDelivery = await deliveryList()
    const resProvince = await provinceList()

    if (!resDelivery.error && !resProvince.error) {
      setDataDeliveryList(resDelivery.data)
      setDataProvinceList(resProvince.data)
    }
  }

  useEffect(() => {
    fetchDeliveryAndProvince()
  }, [])

  const handleDelivery = (delivery) => {
    const result = dataDeliveryList.filter(
      (item) => item.id === Number(delivery)
    )

    return result[0].name
  }

  const handleProvince = (province) => {
    const result = dataProvinceList.filter(
      (item) => item.id === Number(province)
    )
    return result[0].name
  }

  return (
    <>
      <Wrapper>
        <TimeBlock style={{ justifyContent: 'space-between' }}>
          <label style={{ display: 'flex' }}>
            {' '}
            <img src="/images/calendar.png" alt="calendar" />
            <DatePicker
              selected={startDate}
              highlightDates={new Date()}
              onChange={(date: Date) => {
                setStartDate(date)
                setDataSearch({
                  ...dataSearch,
                  start: 1,
                  startDate: handleTimeDate(date)
                })
              }}
              placeholderText="เริ่มวันที่"
              maxDate={moment().toDate()}
            />
          </label>
          <Line />
          <label style={{ display: 'flex' }}>
            <img src="/images/calendar.png" alt="calendar" />
            <DatePicker
              selected={endDate}
              onChange={(date: Date) => {
                const dateFinal = handleTimeDate(date)
                setEndDate(date)
                setDataSearch({
                  ...dataSearch,
                  start: 1,
                  endDate: handleTimeDate(date) + ' 23:59:59'
                })
              }}
              placeholderText="ถึงวันที่"
              maxDate={moment().toDate()}
            />
          </label>
        </TimeBlock>

        {/* Delivery  company */}
        <SelectSimple
          options={dataDeliveryList}
          handleSelect={(value) =>
            setDataSearch({
              ...dataSearch,
              deliveryId: Number(value),
              start: 1
            })
          }
          type="name"
          placeholder="บริษัทขนส่ง"
          value={
            dataSearch.deliveryId ? handleDelivery(dataSearch.deliveryId) : ''
          }
        />

        {/* Location */}
        <SelectSimple
          options={dataProvinceList}
          handleSelect={(value) =>
            setDataSearch({
              ...dataSearch,
              provinceId: Number(value),
              start: 1
            })
          }
          type="name"
          placeholder="จังหวัด"
          value={
            dataSearch.provinceId ? handleProvince(dataSearch.provinceId) : ''
          }
        />

        {/* Status */}
        <SelectSimple
          options={Object.values(configMaster.statusTransaction)}
          handleSelect={(value) => {
            setDataSearch({ ...dataSearch, status: value, start: 1 })
          }}
          type="value"
          placeholder="สถานะ"
          value={
            dataSearch.status
              ? handleSelectedStatus(
                  dataSearch.status,
                  Object.values(configMaster.statusTransaction)
                )
              : ''
          }
        />
      </Wrapper>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          sx={{
            paddingTop: '8px',
            paddingBottom: '32px',
            fontWeight: 700,
            fontSize: '1.4rem',
            textDecoration: 'underline',
            cursor: 'pointer',
            width: '66px'
          }}
          style={{ color: '#6B7C78' }}
          onClick={() => {
            setDataSearch({ start: 1, number: PAGE_SIZE }),
              setStartDate(null),
              setEndDate(null)
          }}
        >
          ล้างข้อมูล
        </Typography>
      </div>
    </>
  )
}

export default FilterWaste

export const TimeBlock = styled.div`
  background: #ffffff;
  border: 1px solid #ccddd9;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 28px;
  input:focus {
    outline: none;
  }
  input {
    border: none;
    width: 100px;
  }

  img {
    padding-right: 8px;
  }
  .react-datepicker__day--today {
    border-radius: 50%;
    background-color: #ccddd9;
  }
`

export const Line = styled.div`
  height: 32px;
  width: 1px;
  background: #6b7c78;
  margin-right: 18px;
`
