import { Box, TableCell, TableRow, Theme, Typography } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'
import SelectSimple from '../../../component/SelectSimple'
import { wasteDetailRequestLink } from '../../../route/RouteDefinition'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { ModalPendingToReceive } from '../ModalPendingToReceived'
import { ModalCheckingToPoint } from '../ModalCheckingToPoint'
import { ModalPendingToPoint } from '../ModalPendingToPoint'
import ModalDoubleButton from '../../../component/ModalDouleButton'
import { Content } from '../../../page/ForgotPassword'
import { cancelWasteRequest, updateStatusWasteRequest } from '../../../api'
import ModalError from '../../../component/ModalError'
import { shortenString } from '../../../utils/theme'
import { convertToThai } from '../../../utils/func'
import { TYPE_OF_DATE } from '../../../constants/date'

const DeliveryImage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const Name = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  color: #2c2c2c;
  display: block;
`

const Image = styled.img`
  margin-right: 3px;
  width: 16px;
  height: 16px;
`

const TextBold = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  color: #2c2c2c;
  display: block;
  margin-bottom: 8px;
`
const TextThin = styled.span`
  font-weight: 400;
  font-size: 1.4rem;
  color: #2c2c2c;
  display: block;
  line-height: 24px;
`

const Status = styled.div`
  display: flex;
  align-items: center;
`
const ActiveStatus = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`

const ActiveName = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  color: #2c2c2c;
  display: block;
  margin-left: 8px;
`

const TableCellStyle = styled(TableCell)`
  display: flex !important;
  justify-content: flex-end !important;
  .ant-select {
    width: 280px !important;
  }
`

const Paragraph = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: right;
  color: #2c2c2c;
`

type Props = {
  dataItem: any
  fetchData: any
}

const RequestItem = ({ dataItem, fetchData }: Props) => {
  const route = useHistory()
  const [pendingToReceived, setPendingToReceived] = useState(false)
  const [checkingToPoint, setCheckingToPoint] = useState(false)
  const [pendingToPoint, setPendingToPoint] = useState(false)
  const [statusChange, setStatusChange] = useState('')
  const [isCancel, setIsCancel] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleCancel = async () => {
    const res = await cancelWasteRequest({ id: Number(dataItem.id) })
    if (!res.error) {
      fetchData()
      setIsCancel(false)
    } else setIsError(true)
  }

  const handleChangeStatus = async (status) => {
    const res = await updateStatusWasteRequest({
      id: Number(dataItem.id),
      status
    })
    if (!res.error) {
      fetchData()
    }
  }

  return (
    <>
      <TableRow
        sx={{
          verticalAlign: 'top',
          background: (theme: Theme) => theme.color.white,
          cursor: 'pointer',
          transition: '.3s ease',
          borderBottom: '1px solid #CCDDD9',
          '&:last-child': {
            borderBottom: 'unset'
          },
          '&:hover': {
            background: (theme: Theme) => theme.color.gray3
          }
        }}
        onClick={(e) => {
          e.stopPropagation(),
            !pendingToReceived &&
              !checkingToPoint &&
              !pendingToPoint &&
              !isCancel &&
              route.push(wasteDetailRequestLink(dataItem.id))
        }}
      >
        <TableCell>
          <TextThin>{dataItem.id}</TextThin>
        </TableCell>
        <TableCell>
          <DeliveryImage>
            {dataItem.deliveryId.image ? (
              <Image src={dataItem.deliveryId.image} />
            ) : (
              <span></span>
            )}
            <Name>{dataItem.deliveryId.name || '-'}</Name>
          </DeliveryImage>
          <TextThin>{shortenString(dataItem.trackingno || '-', 30)}</TextThin>
          <TextThin>{dataItem.parcelweight} kg</TextThin>
        </TableCell>
        <TableCell>
          <TextBold>
            {dataItem.senderId
              ? `${dataItem.senderId.firstname} ${dataItem.senderId.lastname}`
              : '-'}
          </TextBold>
          <TextThin>{dataItem.provinceId.name || '-'}</TextThin>
          <TextThin>
            {dataItem.senderId ? dataItem.senderId.phone : '-'}
          </TextThin>
        </TableCell>
        <TableCell>
          <TextThin>
            {convertToThai(dataItem.createdAt, TYPE_OF_DATE.DATE_ONLY)}
          </TextThin>
          <TextThin>
            {convertToThai(dataItem.createdAt, TYPE_OF_DATE.HOUR_ONLY)}
          </TextThin>
        </TableCell>
        <TableCell>
          <Status>
            <ActiveStatus className={dataItem.status.value}></ActiveStatus>
            <ActiveName>{dataItem.status.name}</ActiveName>
          </Status>
        </TableCell>

        <TableCellStyle>
          <Box>
            {dataItem.listStatus.length > 0 && (
              <SelectSimple
                options={dataItem.listStatus}
                handleSelect={(value) => {
                  setStatusChange(value)

                  if (
                    dataItem.status.value === 'pending' &&
                    ['received', 'checking'].includes(value)
                  ) {
                    setPendingToReceived(true)
                  } else if (
                    ['checking', 'received'].includes(dataItem.status.value) &&
                    value === 'completed'
                  ) {
                    setCheckingToPoint(true)
                  } else if (
                    dataItem.status.value === 'pending' &&
                    value === 'completed'
                  ) {
                    setPendingToPoint(true)
                  } else if (value === 'cancelled') {
                    setIsCancel(true)
                  } else {
                    handleChangeStatus(value)
                  }
                }}
                type="value"
                placeholder="เปลี่ยนสถานะ"
                isStatus={true}
              />
            )}
            <Paragraph>
              <Typography
                sx={{
                  marginRight: '5px',
                  display: 'inline-block'
                }}
              >
                อัปเดตล่าสุด
              </Typography>
              {convertToThai(dataItem.updatedAt, TYPE_OF_DATE.DATE_HOUR_FULL)}
            </Paragraph>
          </Box>
        </TableCellStyle>

        {/* from pending to received or pending to checking */}
        <ModalPendingToReceive
          open={pendingToReceived}
          onDimiss={() => setPendingToReceived(false)}
          id={dataItem.id}
          fetchDetail={fetchData}
          statusChange={statusChange}
          trackingno={dataItem.trackingno}
          name={
            dataItem.senderId
              ? `${dataItem.senderId.firstname} ${dataItem.senderId.lastname}`
              : '-'
          }
        />

        {/* from checking to point added */}
        <ModalCheckingToPoint
          open={checkingToPoint}
          onDimiss={() => setCheckingToPoint(false)}
          id={dataItem.id}
          fetchDetail={fetchData}
          trackingno={dataItem.trackingno}
          name={
            dataItem.senderId
              ? `${dataItem.senderId.firstname} ${dataItem.senderId.lastname}`
              : '-'
          }
        />

        {/* from pending to point added */}
        <ModalPendingToPoint
          open={pendingToPoint}
          onDimiss={() => setPendingToPoint(false)}
          id={dataItem.id}
          fetchDetail={fetchData}
          trackingno={dataItem.trackingno}
          name={
            dataItem.senderId
              ? `${dataItem.senderId.firstname} ${dataItem.senderId.lastname}`
              : '-'
          }
        />

        {/* Modal Cancle */}
        <ModalDoubleButton
          title="ยกเลิกออเดอร์"
          open={isCancel}
          onDimiss={() => setIsCancel(false)}
          handleButtonGreen={handleCancel}
          handleButtonWhite={() => setIsCancel(false)}
          textButtonGreen="ยกเลิก"
          textButtonWhite="ออก"
        >
          <Content>คุณแน่ใจว่าจะยกเลิกออเดอร์นี้ไหม</Content>
        </ModalDoubleButton>

        {/* Modal Error */}
        <ModalError
          content="เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้งภายหลัง"
          open={isError}
          onDimiss={() => setIsError(false)}
        />
      </TableRow>
    </>
  )
}

export default RequestItem
