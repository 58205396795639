import React from 'react'
import TableBlock from '../../../../component/TableBlock'
import THead from '../../../../component/THead'
import { TableCellStyle } from '../../../../page/ProductCatalog'
import { TableBody } from '@mui/material'
import RedeemRow from './RedeemRow'

const RedeemTable = ({ listHistory, dataSearch, setDataSearch }) => {
  return (
    <>
      <TableBlock>
        <THead>
          <TableCellStyle>เลขที่ </TableCellStyle>
          <TableCellStyle
            onClick={() =>
              setDataSearch({
                ...dataSearch,
                optionSort: 'createdAt',
                typeSort: !dataSearch.typeSort
                  ? 'ASC'
                  : dataSearch.typeSort === 'ASC'
                  ? 'DESC'
                  : 'ASC'
              })
            }
            style={{ cursor: 'pointer' }}
          >
            วันที่สร้าง{' '}
            <span>
              <img src="/images/resort.svg" />
            </span>
          </TableCellStyle>
          <TableCellStyle>สินค้า</TableCellStyle>
          <TableCellStyle>บริษัทขนส่ง</TableCellStyle>
          <TableCellStyle>Tracking no.</TableCellStyle>
          <TableCellStyle>คะแนนที่ใช้</TableCellStyle>
          <TableCellStyle>สถานะออเดอร์</TableCellStyle>
        </THead>
        <TableBody>
          {listHistory.map((item) => {
            return <RedeemRow item={item} />
          })}
        </TableBody>
      </TableBlock>
    </>
  )
}

export default RedeemTable
