import { Box, Theme, Typography } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getSystemAdminPath } from '../../../../route/RouteConfig/PrivateRoute'
import { SYSTEM_ADMIN_ROUTE } from '../../../../route/RouteDefinition'

const ProductEmpty = ({ type }) => {
  const history = useHistory()
  const handleClick = () => {
    history.push(getSystemAdminPath(SYSTEM_ADMIN_ROUTE.ADD_NEW_PRODUCT.path))
  }
  return (
    <Box
      sx={{
        background: (theme: Theme) => theme.color.white,
        border: '1px solid #6B7C78',
        minHeight: '397px',
        padding: '106px 319px 243px 349px',
        margin: '0px auto',
        textAlign: 'center',
        borderRadius: '4px',
        fontSize: '1.6rem',
        color: (theme: Theme) => theme.color.gray7
      }}
    >
      <Box>
        {type === 'product' ? (
          <>
            <div>ไม่มีรายการสินค้าในขณะนี้ </div>
            <div>
              หลังจาก{' '}
              <strong
                style={{
                  color: '#00A796',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                onClick={() => handleClick()}
              >
                เพิ่มสินค้าใหม่
              </strong>{' '}
              รายการสินค้าจะแสดงตรงนี้
            </div>
          </>
        ) : type === 'redeem' ? (
          <span>ไม่มีออเดอร์เเลกของในขณะนี้</span>
        ) : type === 'customer-account' ? (
          <span>ไม่มีบัญชีผู้ใช้ในขณะนี้</span>
        ) : (
          <span>ไม่พบผลลัพธ์ที่ค้นหา</span>
        )}
      </Box>
    </Box>
  )
}

export default ProductEmpty
