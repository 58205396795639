import React from 'react'
import styled from 'styled-components'

export const UserTransRedeem = ({
  dataListUser,
  setUserIdSelected,
  setShowUserList,
  fetchDetailUser
}) => {
  return (
    <>
      <Wrapper>
        {dataListUser.length > 0 ? (
          dataListUser.map((user, index) => (
            <User
              key={index}
              onClick={() => {
                setUserIdSelected(user.id),
                  setShowUserList(false),
                  fetchDetailUser(user.id)
              }}
            >
              <div> {user.firstname + ' ' + user.lastname}</div>
              <div className="text-thin">{user.email || '-'}</div>
              <div className="text-thin">{user.phone || '-'}</div>
            </User>
          ))
        ) : (
          <User />
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  border: 1px solid #ccddd9;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  margin-top: 6px;
  height: 270px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6.6px;
    height: 6.6px;
    background-color: #ccddd9;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #00a796;
  }
  z-index: 2;
`

const User = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 2fr;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 24px;

  .text-thin {
    color: #6b7c78;
  }
  :hover {
    background: #ccddd9;
  }
`
