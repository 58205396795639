import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { createProduct, getBrand, setStatus, uploadImg } from '../../../api'
import { AppButton } from '../../../component/common/AppButton'
import Header from '../../../component/Header'
import ModalBasic from '../../../component/ModalBasic'
import ModalDoubleButton from '../../../component/ModalDouleButton'
import ModalError from '../../../component/ModalError'
import Layout from '../../../layouts/DefaultLayout'
import { Content } from '../../../page/ForgotPassword'
import { SYSTEM_ADMIN_ROUTE } from '../../../route/RouteDefinition'
import { globalLoading } from '../../../store/loadingSlice'
import { brandList } from '../../../store/selectData'
import { useAppMaster } from '../../../store/_hook'
import { AppButtonStyle } from '../ProductDetail'
import { ContactDetail } from './ContactDetail'
import { ProductDescription } from './ProductDescription'
import ProductImage from './ProductImages'
import ProductInfo, { handleTime } from './ProductInfo'

type Props = {}

const Wrapper = styled.div``

export const Title = styled.div`
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  padding-top: 48px;
`

const AddNewProduct = ({}: Props): JSX.Element => {
  const route = useHistory()
  const dispatch = useDispatch()
  const {
    state: { configMaster }
  } = useAppMaster()
  const [dataAdd, setDataAdd] = useState({
    name: '',
    productType: '',
    brandId: '',
    categories: [],
    value: '',
    inventory: '',
    quantity: '',
    excerpt: '',
    description: '',
    phone: '',
    status: 'inactive',
    listImage: [],
    logo: [],
    brandName: '',
    startDate: handleTime(new Date(), new Date()),
    endDate: handleTime(new Date(), new Date())
  })

  const [isBack, setIsBack] = useState(false)
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isBefore, setIsBefore] = useState(false)
  const [isHourBefore, setIsHourBefore] = useState(false)

  const [isAllow, setIsAllow] = useState(false)

  const handleCheck = () => {
    !isHourBefore && setIsAllow(true)
  }

  const handleCreate = async () => {
    const {
      name,
      productType,
      brandId,
      categories,
      value,
      inventory,
      quantity,
      excerpt,
      description,
      phone,
      status,
      listImage,
      logo,
      brandName
    } = dataAdd

    try {
      if (
        name.trim() === '' ||
        productType === '' ||
        brandId === '' ||
        categories.length === 0 ||
        value.trim() === '' ||
        inventory === '' ||
        quantity.trim() === '' ||
        excerpt.trim() === '' ||
        description.trim() === '' ||
        (brandId == '0' && brandName.trim() === '') ||
        isBefore ||
        isHourBefore === false
      ) {
        setIsCheck(true)
        handleCheck()
        setIsWarningModal(true)
      } else {
        //handle logo
        dispatch(globalLoading(true))
        let logoFinal = ''
        const resLogo =
          logo.length > 0 && (await uploadImg(logo[0].originFileObj))
        if (resLogo && !resLogo.error) {
          logoFinal = resLogo.data
        }
        //handle img product
        const resCalls =
          (listImage.length > 0 &&
            listImage.map(async (img) => {
              return await uploadImg(img.originFileObj)
            })) ||
          []

        const resImg = await Promise.all(resCalls)
        let listLinkImg = []
        resImg.length > 0 ? resImg.map((res) => listLinkImg.push(res.data)) : []

        if (listLinkImg.length === listImage.length) {
          const res = await createProduct({
            ...dataAdd,
            listImage: listLinkImg,
            logo: logoFinal,
            value: Number(value),
            inventory: Number(inventory),
            brandId: Number(brandId),
            quantity: Number(quantity)
          })
          if (!res.error) {
            await setStatus({ id: res.data.id, status: status })
            dispatch(globalLoading(false))
            route.push(SYSTEM_ADMIN_ROUTE.PRODUCT_CATALOG.path)
            if (res.refreshBrand) {
              const res = await getBrand()
              dispatch(brandList(res.data))
            }
          } else {
            dispatch(globalLoading(false))
            setIsError(true)
          }
        }
      }
    } catch (error) {
      dispatch(globalLoading(false))
      setIsError(true)
    }
  }

  return (
    <>
      <Layout>
        <Header>
          <div className="title-root" onClick={() => setIsBack(true)}>
            รายการสินค้า{' '}
          </div>
          <div className="title-sub"> / เพิ่มสินค้าใหม่ </div>
        </Header>

        <Wrapper>
          <Title>เพิ่มสินค้าใหม่</Title>
          <ProductInfo
            setDataAdd={setDataAdd}
            dataAdd={dataAdd}
            isCheck={isCheck}
            isBefore={isBefore}
            setIsBefore={setIsBefore}
            isHourBefore={isHourBefore}
            setIsHourBefore={setIsHourBefore}
            isAllow={isAllow}
          />

          <ProductImage setDataAdd={setDataAdd} dataAdd={dataAdd} />

          <ProductDescription
            setDataAdd={setDataAdd}
            dataAdd={dataAdd}
            isCheck={isCheck}
          />

          <ContactDetail
            setDataAdd={setDataAdd}
            dataAdd={dataAdd}
            isCheck={isCheck}
          />

          <div>
            <AppButtonStyle
              sx={{ width: '200px', mt: '32px' }}
              onClick={() => handleCreate()}
            >
              <img src="/images/save.png" />
              บันทึก
            </AppButtonStyle>

            <AppButton
              sx={{ width: '134px', mt: '32px', marginLeft: '10px' }}
              color="secondary"
              onClick={() => setIsBack(true)}
            >
              ยกเลิก
            </AppButton>
          </div>
        </Wrapper>

        <ModalDoubleButton
          title="ยังไม่ได้บันทึกข้อมูล"
          open={isBack}
          onDimiss={() => setIsBack(false)}
          handleButtonGreen={() => setIsBack(false)}
          handleButtonWhite={() =>
            route.push(SYSTEM_ADMIN_ROUTE.PRODUCT_CATALOG.path)
          }
          textButtonGreen="อยู่ต่อหน้านี้"
          textButtonWhite="ออก"
        >
          <Content>คุณแน่ใจว่าจะออกจากหน้านี้ ข้อมูลทั้งหมดจะถูกลบ</Content>
        </ModalDoubleButton>

        <ModalBasic
          title=""
          open={isWarningModal}
          onDimiss={() => setIsWarningModal(false)}
        >
          <Content>กรุณาใส่ข้อมูลที่จำเป็นทั้งหมด</Content>
        </ModalBasic>

        <ModalError
          content="เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้งภายหลัง"
          open={isError}
          onDimiss={() => setIsError(false)}
        />
      </Layout>
    </>
  )
}

export default AddNewProduct
