import { Box, Theme, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { getUserDetail, getUserHistory } from '../../../api'
import Header from '../../../component/Header'
import PaginationSquare from '../../../component/PaginationSquare'
import SelectSimple from '../../../component/SelectSimple'
import Layout from '../../../layouts/DefaultLayout'
import { PAGE_SIZE } from '../../../page/ProductCatalog'
import { SYSTEM_ADMIN_ROUTE } from '../../../route/RouteDefinition'
import { Title } from '../../ProductCatalog/AddNewProduct'
import DisplayResult from '../../ProductCatalog/ProductList/DisplayResult'
import AccountDetailTable from './AccountDetailTable'
import BlockProfile from './BlockProfile'
import ProductEmpty from '../../ProductCatalog/ProductList/ProductEmpty'
import RedeemTable from './RedeemTable'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const Paging = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`
const WrapperDropDown = styled.div`
  margin-top: 36px;
`
const Number = styled.span`
  padding: 0 6px;
  font-weight: 700;
  font-size: 1.6rem;
`

const CustomerAccountDetail = () => {
  const [total, setTotal] = useState(0)
  const [dataSearch, setDataSearch] = useState({
    start: 1,
    number: PAGE_SIZE
  })
  const [details, setDetails] = useState([])
  const route = useHistory()
  const location = useLocation()
  const idRouter = location.state ? location.state.name : ''
  const [serviceId, setServiceId] = useState(1)
  const [listHistory, setListHistory] = useState([])

  const idWaste = route.location.pathname.split('/')[2]

  const fetchDetail = async (id) => {
    let data = { id }
    try {
      const userDetail = await getUserDetail(data)
      if (!userDetail.error) {
        setDetails(userDetail.data)
      }
    } catch (error) {}
  }

  const getHistory = async () => {
    try {
      const res = await getUserHistory({
        ...dataSearch,
        id: idWaste,
        serviceId
      })
      if (!res.error) {
        setListHistory(res.data)
        setTotal(res.total)
      }
    } catch (error) {}
  }

  const optionDropDown = [
    { value: 1, name: 'ประวัติการส่งขยะ', id: 1000129 },
    { value: 2, name: 'ประวัติการแลกของ', id: 1000130 }
  ]

  const handleGetType = (value) => {
    let obj = optionDropDown.find((item) => item.value == value)
    return obj ? obj.name : ''
  }

  useEffect(() => {
    getHistory()
  }, [serviceId, dataSearch])

  useEffect(() => {
    fetchDetail(idWaste)
  }, [idWaste])

  return (
    <>
      <Layout>
        <Header>
          <div
            className="title-root"
            onClick={() =>
              route.push(SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_MANAGER.path)
            }
          >
            จัดการบัญชีผู้ใช้{' '}
          </div>
          <div className="title-sub"> / บัญชีผู้ใช้ : {idRouter} </div>
        </Header>
        <Wrapper>
          <Title>รายละเอียดบัญชีผู้ใช้</Title>
        </Wrapper>
        <BlockProfile dataDetail={details} idRouter={idRouter} />
        <WrapperDropDown>
          <SelectSimple
            options={optionDropDown}
            value={handleGetType(serviceId)}
            handleSelect={(value) => {
              setServiceId(value)
              setDataSearch({ ...dataSearch, start: 1 })
            }}
            type="value"
            width={400}
          />
        </WrapperDropDown>

        {listHistory && listHistory.length > 0 ? (
          <>
            <Paging>
              <DisplayResult total={total} />
              <PaginationSquare
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
                totalPage={Math.ceil(total / PAGE_SIZE)}
              />
            </Paging>
            {serviceId == 1 ? (
              <AccountDetailTable
                idRouter={idRouter}
                listHistory={listHistory}
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
              />
            ) : (
              <RedeemTable
                idRouter={idRouter}
                listHistory={listHistory}
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '8px'
              }}
            >
              <PaginationSquare
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
                totalPage={Math.ceil(total / PAGE_SIZE)}
              />
            </Box>
          </>
        ) : (
          <div>
            <Box sx={{ margin: '16px 0px' }}>
              <span style={{ fontSize: '1.6rem', color: '#444' }}>ผลลัพธ์</span>
              <Number>{total}</Number>
              <span style={{ fontSize: '1.6rem', color: '#444' }}>รายการ</span>
            </Box>
            <Box
              sx={{
                background: 'white',
                border: '1px solid #6B7C78',
                minHeight: '397px',
                padding: '106px 319px 243px 349px',
                margin: '0px auto',
                textAlign: 'center',
                borderRadius: '4px',
                fontSize: '1.6rem',
                color: '#6B7C78'
              }}
            >
              <Box>
                <span>ไม่มีข้อมูลในขณะนี้</span>
              </Box>
            </Box>
          </div>
        )}
      </Layout>
    </>
  )
}

export default CustomerAccountDetail
