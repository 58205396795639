import React, { useState } from 'react'
import styled from 'styled-components'
import Panel from '../../../../component/Panel'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { AntSwitch } from '../../../../component/AntSwitch'
import AppInputLabel from '../../../../component/common/AppInputLabel'
import AppInput from '../../../../component/common/AppInput'
import SelectSimple from '../../../../component/SelectSimple'
import { useAppMaster } from '../../../../store/_hook'
import { Required } from '../../../ProductCatalog/AddNewProduct/ProductInfo'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/_store'
import { ItemRender } from 'antd/lib/upload/interface'
import { alphaOnly } from '../../../../function/alpha'

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #00a796;
  margin-bottom: 24px;
`

const Status = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #2c2c2c;
`

const Info = styled.div`
  display: flex;
  gap: 80px;
  margin-bottom: 40px;
`
const Birth = styled.div`
  display: flex;
  gap: 20px;
`
type Props = {
  setdataCreateProfile: any
  dataCreateProfile: any
  isCheck?: boolean
  isHadEmail?: boolean
  validate?: any
  valid: any
  isPhone?: any
  type?: StringConstructor
}

const AddProfile = ({
  dataCreateProfile,
  setdataCreateProfile,
  isCheck,
  isHadEmail,
  validate,
  valid,
  isPhone,
  type
}: Props): JSX.Element => {
  const {
    state: { configMaster }
  } = useAppMaster()

  const { dataProvince } = useSelector((state: RootState) => state.selectSlice)

  const listAccountType = [
    {
      id: 1000086,
      name: 'บุคคลทั่วไป',
      value: 0
    },
    {
      id: 1000090,
      name: 'องค์กร',
      value: 4
    }
  ]

  const handleValidatePhone = (e: any) => {
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      e.target.value.length <= 10 &&
        setdataCreateProfile({
          ...dataCreateProfile,
          phone: e.target.value
        })
    }
  }

  return (
    <>
      <Panel title={'หัวข้อ (1/2)  : ข้อมูลบัญชีผู้ใช้   '}>
        <Title className="title">ข้อมูลบัญชี</Title>
        <Box sx={{ fontSize: '1.2rem' }}>
          สถานะการใช้งาน
          <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={(e) =>
                setdataCreateProfile({
                  ...dataCreateProfile,
                  status: e.target.checked ? 'active' : 'inactive'
                })
              }
            />
            <Status>
              {dataCreateProfile.status === 'inactive'
                ? 'ปิดใช้งาน'
                : 'เปิดใช้งาน'}
            </Status>
          </Stack>
        </Box>
        <Box
          sx={{
            marginTop: '40px',
            color: '#2C2C2C',
            width: '746px'
          }}
        >
          <AppInputLabel
            sx={{
              marginBottom: '4px'
            }}
          >
            อีเมล (ที่จำเป็น)
          </AppInputLabel>
          <AppInput
            fullWidth
            type="text"
            sx={{
              fontSize: '16px'
            }}
            errors={
              (isCheck && dataCreateProfile.email.trim() == '') ||
              isHadEmail ||
              (!valid && dataCreateProfile.email.trim() != '')
            }
            placeholder="ใส่อีเมล"
            onChange={(e) => {
              validate(e.target.value),
                setdataCreateProfile({
                  ...dataCreateProfile,
                  email: e.target.value
                })
            }}
          />
          <AppInputLabel
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#6B7C78',
              paddingTop: '4px'
            }}
          >
            {isCheck && dataCreateProfile.email.trim() == '' && (
              <Required>จำเป็นต้องระบุ</Required>
            )}
            {isHadEmail && (
              <Required>
                อีเมลนี้เคยใช้ลงทะเบียนเเล้ว กรุณาเปลี่ยนอีเมลใหม่
              </Required>
            )}
            {!valid && dataCreateProfile.email.trim() != '' && (
              <Required>อีเมลไม่ถูกต้อง</Required>
            )}
          </AppInputLabel>
        </Box>
        <Box
          sx={{
            marginTop: '40px',
            color: '#2C2C2C',
            width: '746px',
            marginBottom: '50px'
          }}
        >
          <AppInputLabel
            sx={{
              marginBottom: '4px'
            }}
          >
            ประเภทบัญชี (ที่จำเป็น)
          </AppInputLabel>
          <SelectSimple
            width={428}
            options={Object.values(listAccountType)}
            error={isCheck && dataCreateProfile.groupId.trim() === ''}
            type="value"
            placeholder="เลือกประเภทบัญชี"
            value={
              (type
                ? dataCreateProfile.groupId.name
                : dataCreateProfile.groupId) || ''
            }
            handleSelect={(value) =>
              setdataCreateProfile({
                ...dataCreateProfile,
                groupId: value
              })
            }
          />
          {isCheck && dataCreateProfile.groupId === '' && (
            <Required>จำเป็นต้องระบุ</Required>
          )}
        </Box>
        <Title className="title">ข้อมูลผู้ใช้</Title>

        <Info>
          <Box width={400}>
            <AppInputLabel
              sx={{
                marginBottom: '4px'
              }}
            >
              ชื่อ (ที่จำเป็น)
            </AppInputLabel>
            <AppInput
              fullWidth
              placeholder="ชื่อ"
              value={dataCreateProfile.firstname}
              errors={isCheck && dataCreateProfile.firstname.trim() === ''}
              onKeyDown={(e) => alphaOnly(e)}
              onChange={(e) => {
                setdataCreateProfile({
                  ...dataCreateProfile,
                  firstname: e.target.value
                })
              }}
            />
            <AppInputLabel
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#6B7C78',
                paddingTop: '4px'
              }}
            >
              {isCheck && dataCreateProfile.firstname.trim() == '' && (
                <Required>จำเป็นต้องระบุ</Required>
              )}
            </AppInputLabel>
          </Box>
          <Box width={400}>
            <AppInputLabel
              sx={{
                marginBottom: '4px'
              }}
            >
              นามสกุล (ที่จำเป็น)
            </AppInputLabel>
            <AppInput
              fullWidth
              placeholder="นามสกุล"
              errors={isCheck && dataCreateProfile.lastname.trim() === ''}
              value={dataCreateProfile.lastname}
              onKeyDown={(e) => alphaOnly(e)}
              onChange={(e) =>
                setdataCreateProfile({
                  ...dataCreateProfile,
                  lastname: e.target.value
                })
              }
            />
            <AppInputLabel
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#6B7C78',
                paddingTop: '4px'
              }}
            >
              {isCheck && dataCreateProfile.lastname.trim() == '' && (
                <Required>จำเป็นต้องระบุ</Required>
              )}
            </AppInputLabel>
          </Box>
        </Info>

        <Info>
          <Box width={400}>
            <AppInputLabel
              sx={{
                marginBottom: '4px'
              }}
            >
              เบอร์โทรศัพท์ (ที่จำเป็น)
            </AppInputLabel>
            <AppInput
              fullWidth
              placeholder="ใส่เบอร์โทรศัพท์"
              type="text"
              value={dataCreateProfile.phone}
              errors={
                (isCheck && dataCreateProfile.phone.trim() === '') ||
                (!isPhone && dataCreateProfile.phone.trim() !== '')
              }
              onChange={(e) => handleValidatePhone(e)}
            />
            <AppInputLabel
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#6B7C78',
                paddingTop: '4px'
              }}
            >
              {isCheck && dataCreateProfile.phone.trim() == '' && (
                <Required>จำเป็นต้องระบุ</Required>
              )}

              {!isPhone && dataCreateProfile.phone.trim() !== '' && (
                <Required>โทรศัพท์ไม่ถูกต้อง</Required>
              )}
            </AppInputLabel>
          </Box>
          <Box width={400}>
            <AppInputLabel
              sx={{
                marginBottom: '4px'
              }}
            >
              วันเกิด (ไม่จำเป็นต้องระบุ){' '}
            </AppInputLabel>
            <Birth>
              <SelectSimple
                width={120}
                options={optionsDay}
                type="value"
                placeholder="วัน"
                value={dataCreateProfile.dateOfBirth}
                handleSelect={(value) =>
                  setdataCreateProfile({
                    ...dataCreateProfile,
                    dateOfBirth: value
                  })
                }
              />

              <SelectSimple
                width={120}
                options={optionsMonth}
                type="value"
                placeholder="เดือน"
                value={dataCreateProfile.monthOfBirth}
                handleSelect={(value) =>
                  setdataCreateProfile({
                    ...dataCreateProfile,
                    monthOfBirth: value
                  })
                }
              />
              <SelectSimple
                width={120}
                options={yearFormat}
                type="value"
                placeholder="ปี"
                value={dataCreateProfile.yearOfBirth}
                handleSelect={(value) =>
                  setdataCreateProfile({
                    ...dataCreateProfile,
                    yearOfBirth: value
                  })
                }
              />
            </Birth>
          </Box>
        </Info>
        <Info>
          <Box width={400}>
            <AppInputLabel
              sx={{
                marginBottom: '4px'
              }}
            >
              จังหวัด (ไม่จำเป็นต้องระบุ)
            </AppInputLabel>
            <SelectSimple
              width={400}
              options={Object.values(dataProvince)}
              type="id"
              placeholder="เลือกจังหวัด"
              value={
                (type
                  ? dataCreateProfile.province.name
                  : dataCreateProfile.province) || ''
              }
              handleSelect={(value) =>
                setdataCreateProfile({
                  ...dataCreateProfile,
                  province: value
                })
              }
            />
          </Box>
          <Box width={120}>
            <AppInputLabel
              sx={{
                marginBottom: '4px'
              }}
            >
              เพศ (ไม่จำเป็นต้องระบุ)
            </AppInputLabel>
            <SelectSimple
              width={120}
              options={Object.values(configMaster.gender)}
              type="value"
              placeholder="เลือกเพศ"
              value={
                (type
                  ? dataCreateProfile.gender.name
                  : dataCreateProfile.gender) || ''
              }
              handleSelect={(value) => {
                setdataCreateProfile({
                  ...dataCreateProfile,
                  gender: value
                })
              }}
            />
          </Box>
        </Info>
      </Panel>
    </>
  )
}

export default AddProfile

export const optionsMonth = [
  {
    value: '01/',
    id: 1,
    name: 'January'
  },
  {
    value: '02/',
    id: 2,
    name: 'February'
  },
  {
    value: '03/',
    id: 3,
    name: 'March'
  },
  {
    value: '04/',
    id: 4,
    name: 'April'
  },
  {
    value: '05/',
    id: 5,
    name: 'May'
  },
  {
    value: '06/',
    id: 6,
    name: 'June'
  },
  {
    value: '07/',
    id: 7,
    name: 'July'
  },
  {
    value: '08/',
    id: 8,
    name: 'August'
  },
  {
    value: '09/',
    id: 9,
    name: 'September'
  },
  {
    value: '10/',
    id: 10,
    name: 'October'
  },
  {
    value: '11/',
    id: 11,
    name: 'November'
  },
  {
    value: '12/',
    id: 12,
    name: 'December'
  }
]
export const optionsDay = [
  {
    value: '01',
    id: 1,
    name: '01'
  },
  {
    value: '02',
    id: 2,
    name: '02'
  },
  {
    value: '03',
    id: 3,
    name: '03'
  },
  {
    value: '04',
    id: 4,
    name: '04'
  },
  {
    value: '05',
    id: 5,
    name: '05'
  },
  {
    value: '06',
    id: 6,
    name: '06'
  },
  {
    value: '07',
    id: 7,
    name: '07'
  },
  {
    value: '08',
    id: 8,
    name: '08'
  },
  {
    value: '09',
    id: 9,
    name: '09'
  },
  {
    value: '10',
    id: 10,
    name: '10'
  },
  {
    value: '11',
    id: 11,
    name: '11'
  },
  {
    value: '12',
    id: 12,
    name: '12'
  },
  {
    value: '13',
    id: 13,
    name: '13'
  },
  {
    value: '14',
    id: 14,
    name: '14'
  },
  {
    value: '15',
    id: 15,
    name: '15'
  },
  {
    value: '16',
    id: 16,
    name: '16'
  },
  {
    value: '17',
    id: 17,
    name: '17'
  },
  {
    value: '18',
    id: 18,
    name: '18'
  },
  {
    value: '19',
    id: 19,
    name: '19'
  },
  {
    value: '20',
    id: 20,
    name: '20'
  },
  {
    value: '21',
    id: 21,
    name: '21'
  },
  {
    value: '22',
    id: 22,
    name: '22'
  },
  {
    value: '23',
    id: 23,
    name: '23'
  },
  {
    value: '24',
    id: 24,
    name: '24'
  },
  {
    value: '25',
    id: 25,
    name: '25'
  },
  {
    value: '26',
    id: 26,
    name: '26'
  },
  {
    value: '27',
    id: 27,
    name: '27'
  },
  {
    value: '28',
    id: 28,
    name: '28'
  },
  {
    value: '29',
    id: 29,
    name: '29'
  },
  {
    value: '30',
    id: 30,
    name: '30'
  },
  {
    value: '31',
    id: 31,
    name: '31'
  }
]
export const optionsYear = [
  {
    value: '1932/',
    id: 1932,
    name: '1932'
  },
  {
    value: '1933/',
    id: 1933,
    name: '1933'
  },
  {
    value: '1934/',
    id: 1934,
    name: '1934'
  },

  {
    value: '1935/',
    id: 1935,
    name: '1935'
  },
  {
    value: '1936/',
    id: 1936,
    name: '1936'
  },
  {
    value: '1937/',
    id: 1937,
    name: '1937'
  },
  {
    value: '1938/',
    id: 1938,
    name: '1938'
  },
  {
    value: '1939/',
    id: 1939,
    name: '1939'
  },
  {
    value: '1940/',
    id: 1940,
    name: '1940'
  },
  {
    value: '1941/',
    id: 1941,
    name: '1941'
  },
  {
    value: '1942/',
    id: 1942,
    name: '1942'
  },
  {
    value: '1943/',
    id: 1943,
    name: '1943'
  },
  {
    value: '1944/',
    id: 1944,
    name: '1944'
  },
  {
    value: '1945/',
    id: 1945,
    name: '1945'
  },
  {
    value: '1946/',
    id: 1946,
    name: '1946'
  },
  {
    value: '1947/',
    id: 1947,
    name: '1947'
  },
  {
    value: '1948/',
    id: 1948,
    name: '1948'
  },
  {
    value: '1949/',
    id: 1949,
    name: '1949'
  },
  {
    value: '1950/',
    id: 1950,
    name: '1950'
  },
  {
    value: '1951/',
    id: 1951,
    name: '1951'
  },
  {
    value: '1952/',
    id: 1952,
    name: '1952'
  },
  {
    value: '1953/',
    id: 1953,
    name: '1953'
  },
  {
    value: '1954/',
    id: 1954,
    name: '1954'
  },
  {
    value: '1955/',
    id: 1955,
    name: '1955'
  },
  {
    value: '1956/',
    id: 1956,
    name: '1956'
  },
  {
    value: '1957/',
    id: 1957,
    name: '1957'
  },
  {
    value: '1958/',
    id: 1958,
    name: '1958'
  },

  {
    value: '1959/',
    id: 1959,
    name: '1959'
  },
  {
    value: '1960/',
    id: 1960,
    name: '1960'
  },
  {
    value: '1961/',
    id: 1961,
    name: '1961'
  },
  {
    value: '1962/',
    id: 1962,
    name: '1962'
  },
  {
    value: '1963/',
    id: 1963,
    name: '1963'
  },
  {
    value: '1964/',
    id: 1964,
    name: '1964'
  },

  {
    value: '1965/',
    id: 1965,
    name: '1965'
  },
  {
    value: '1966/',
    id: 1966,
    name: '1966'
  },
  {
    value: '1967/',
    id: 1967,
    name: '1967'
  },
  {
    value: '1968/',
    id: 1968,
    name: '1968'
  },
  {
    value: '1969/',
    id: 1969,
    name: '1969'
  },

  {
    value: '1970/',
    id: 1970,
    name: '1970'
  },
  {
    value: '1971/',
    id: 1971,
    name: '1971'
  },
  {
    value: '1972/',
    id: 1972,
    name: '1972'
  },
  {
    value: '1973/',
    id: 1973,
    name: '1973'
  },
  {
    value: '1974/',
    id: 1974,
    name: '1974'
  },
  {
    value: '1975/',
    id: 1975,
    name: '1975'
  },
  {
    value: '1976/',
    id: 1976,
    name: '1976'
  },
  {
    value: '1977/',
    id: 1977,
    name: '1977'
  },

  {
    value: '1978/',
    id: 1978,
    name: '1978'
  },
  {
    value: '1979/',
    id: 1979,
    name: '1979'
  },
  {
    value: '1980/',
    id: 1980,
    name: '1980'
  },
  {
    value: '1981/',
    id: 1981,
    name: '1981'
  },
  {
    value: '1982/',
    id: 1982,
    name: '1982'
  },
  {
    value: '1983/',
    id: 1983,
    name: '1983'
  },
  {
    value: '1984/',
    id: 1984,
    name: '1984'
  },
  {
    value: '1985/',
    id: 1985,
    name: '1985'
  },
  {
    value: '1986/',
    id: 1986,
    name: '1986'
  },
  {
    value: '1987/',
    id: 1987,
    name: '1987'
  },
  {
    value: '1988/',
    id: 1988,
    name: '1988'
  },
  {
    value: '1989/',
    id: 1989,
    name: '1989'
  },
  {
    value: '1990/',
    id: 1990,
    name: '1990'
  },
  {
    value: '1991/',
    id: 1991,
    name: '1991'
  },
  {
    value: '1992/',
    id: 1992,
    name: '1992'
  },
  {
    value: '1993/',
    id: 1993,
    name: '1993'
  },
  {
    value: '1994/',
    id: 1994,
    name: '1994'
  },
  {
    value: '1995/',
    id: 1995,
    name: '1995'
  },

  {
    value: '1996/',
    id: 1996,
    name: '1996'
  },
  {
    value: '1997/',
    id: 1997,
    name: '1997'
  },
  {
    value: '1998/',
    id: 1998,
    name: '1998'
  },
  {
    value: '1999/',
    id: 1999,
    name: '1999'
  },
  {
    value: '2000/',
    id: 2000,
    name: '2000'
  },
  {
    value: '2001/',
    id: 2001,
    name: '2001'
  },
  {
    value: '2002/',
    id: 2002,
    name: '2002'
  },
  {
    value: '2003/',
    id: 2003,
    name: '2003'
  },
  {
    value: '2004/',
    id: 2004,
    name: '2004'
  },
  {
    value: '2005/',
    id: 2005,
    name: '2005'
  },
  {
    value: '2006/',
    id: 2006,
    name: '2006'
  },
  {
    value: '2007/',
    id: 2007,
    name: '2007'
  },
  {
    value: '2008/',
    id: 2008,
    name: '2008'
  },
  {
    value: '2009/',
    id: 2009,
    name: '2009'
  },
  {
    value: '2010/',
    id: 2010,
    name: '2010'
  },

  {
    value: '2011/',
    id: 2011,
    name: '2011'
  },
  {
    value: '2012/',
    id: 2012,
    name: '2012'
  },
  {
    value: '2013/',
    id: 2013,
    name: '2013'
  },
  {
    value: '2014/',
    id: 2014,
    name: '2014'
  },
  {
    value: '2015/',
    id: 2015,
    name: '2015'
  },
  {
    value: '2016/',
    id: 2016,
    name: '2016'
  },
  {
    value: '2017/',
    id: 2017,
    name: '2017'
  },
  {
    value: '2018/',
    id: 2018,
    name: '2018'
  },
  {
    value: '2019/',
    id: 2019,
    name: '2019'
  },
  {
    value: '2020/',
    id: 2020,
    name: '2020'
  },
  {
    value: '2021/',
    id: 2021,
    name: '2021'
  },
  {
    value: '2022/',
    id: 2022,
    name: '2022'
  }
]

const yearFormat = optionsYear.reverse()

const regexPhone =
  '^(086|096|097|098|032|033|034|035|036|037|038|039|090|093|0120|0121|0122|0126|0128|0896|091|094|083|084|085|081|082|092|056|058|099|059|0296|0254|0209|0204|0291|0222|0275|0256|0274|0271|0252|0290|0292|0206|0236|0262|0261|0215|0251|0277|0269|0226|024|0239|0220|0225|0293|079|028|0221|0258|0297|0260|0213|0263|0205|0214|0272|0228|0238|0259|0229|0257|0232|0235|0255|0203|0233|0299|0212|0276|0227|0208|0237|0234|0273|0294|0207|0270|0216|08|06|09)([0-9]{6,9})$'
