import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AppButton } from '../../../component/common/AppButton'
import {
  editProductDetail,
  deleteProductDetail,
  uploadImg,
  getProductList,
  getBrand,
  productDetail
} from '../../../api'
import Header from '../../../component/Header'
import Layout from '../../../layouts/DefaultLayout'
import {
  productDetailItemLink,
  SYSTEM_ADMIN_ROUTE
} from '../../../route/RouteDefinition'
import { Title } from '../AddNewProduct'
import { ContactDetail } from '../AddNewProduct/ContactDetail'
import { ProductDescription } from '../AddNewProduct/ProductDescription'
import ProductImage from '../AddNewProduct/ProductImages'
import ProductInfo from '../AddNewProduct/ProductInfo'
import { AppButtonStyle, TitleBlock } from '../ProductDetail'
import ModalDoubleButton from '../../../component/ModalDouleButton'
import { Content } from '../../../page/ForgotPassword'
import ModalBasic from '../../../component/ModalBasic'
import ModalError from '../../../component/ModalError'
import { PAGE_SIZE } from '../../../page/ProductCatalog'
import { brandList } from '../../../store/selectData'
import { useDispatch } from 'react-redux'
import { globalLoading } from '../../../store/loadingSlice'

export const EditProduct = () => {
  const route = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [isDelete, setIsDelete] = useState(false)
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isBack, setIsBack] = useState(false)
  const [dataAdd, setDataAdd] = useState()
  const [isBackPrev, setIsBackPrev] = useState(true)
  const [isBefore, setIsBefore] = useState(false)
  const idProduct = location.pathname.split('/')[3]
  const [isHourBefore, setIsHourBefore] = useState(false)

  const [isAllow, setIsAllow] = useState(false)

  const handleCheck = () => {
    !isHourBefore && setIsAllow(true)
  }

  const fetchDetail = async () => {
    const res = await productDetail({
      id: idProduct
    })

    if (res && !res.error && res.data) {
      setDataAdd(res.data)
    } else route.push(SYSTEM_ADMIN_ROUTE.PRODUCT_CATALOG.path)
  }

  useEffect(() => {
    fetchDetail()
  }, [])

  let categoriesEdit = []

  console.log("DATA ADD", dataAdd)

  const handleEdit = async () => {
    if (dataAdd.categories.length > 0 && dataAdd.categories[0].id) {
      dataAdd.categories.map((item) => categoriesEdit.push(item.id))
    }

    let dataEdit = {
      ...dataAdd,
      brandId: dataAdd.brandId.id ? dataAdd.brandId.id : dataAdd.brandId,
      inventory: dataAdd.inventory.value
        ? dataAdd.inventory.value
        : dataAdd.inventory,
      productType: dataAdd.productType.value
        ? dataAdd.productType.value
        : dataAdd.productType,
      categories:
        dataAdd.categories.length > 0 && dataAdd.categories[0].id
          ? categoriesEdit
          : dataAdd.categories.length > 0
          ? dataAdd.categories
          : []
    }
    const {
      name,
      value,
      quantity,
      categories,
      excerpt,
      description,
      phone,
      listImage,
      logo,
      inventory,
      brandId
    } = dataEdit
    try {
      if (
        name.trim() === '' ||
        categories.length === 0 ||
        Number(value) === 0 ||
        // Number(quantity) === 0 ||
        excerpt.trim() === '' ||
        description.trim() === '' ||
        isBefore ||
        isHourBefore === false
      ) {
        setIsCheck(true)
        handleCheck()
        setIsWarningModal(true)
      } else {
        // handle logo
        dispatch(globalLoading(true))
        let logoFinal = logo
        if (logo.length > 0) {
          if (logo[0].originFileObj) {
            const resLogo = await uploadImg(logo[0].originFileObj)
            if (!resLogo.error) {
              logoFinal = resLogo.data
            }
          } else {
            logoFinal = logo[0].split('/')[3]
          }
        } else {
          logoFinal = ''
        }

        // handle img when upload
        const listNewImgUpload = listImage.filter((item) => item.originFileObj)
        const listOldImgUpload = listImage.filter((item) => !item.originFileObj)

        const resCalls =
          (listNewImgUpload.length > 0 &&
            listNewImgUpload.map(async (img) => {
              return await uploadImg(img.originFileObj)
            })) ||
          []

        const resNewImg = await Promise.all(resCalls)
        let listLinkNewImg = []
        resNewImg.map((res) => listLinkNewImg.push(res.data))

        const listImgFinal = listOldImgUpload
          .map((item) => item.split('/')[3])
          .concat(listLinkNewImg)

        //cal api update product
        const res = await editProductDetail({
          ...dataEdit,
          listImage: listImgFinal,
          logo: logoFinal,
          inventory: Number(inventory),
          value: Number(value),
          brandId: Number(brandId),
          quantity: Number(quantity)
        })
        if (!res.error) {
          dispatch(globalLoading(false))
          route.push(SYSTEM_ADMIN_ROUTE.PRODUCT_CATALOG.path)
          if (res.refreshBrand) {
            const res = await getBrand()
            dispatch(brandList(res.data))
          }
        } else {
          dispatch(globalLoading(false))
          setIsError(true)
        }
      }
    } catch (error) {
      dispatch(globalLoading(false))
      setIsError(true)
    }
  }

  const handleDelete = async () => {
    try {
      const res = await deleteProductDetail({ id: dataAdd.id })
      if (!res.error) {
        route.push(SYSTEM_ADMIN_ROUTE.PRODUCT_CATALOG.path)
      } else setIsError(true)
    } catch (error) {
      setIsError(true)
    }
  }

  const handleIsBackPrev = () => (setIsBack(true), setIsBackPrev(false))

  return (
    <Layout>
      {dataAdd && (
        <>
          <Header>
            <div className="title-root" onClick={() => handleIsBackPrev()}>
              รายการสินค้า{' '}
            </div>

            <div
              className="title-root title-root-child"
              onClick={() => setIsBack(true)}
            >
              / รายละเอียดสินค้า{' '}
            </div>
            <div className="title-sub">
              {' '}
              / รายละเอียดสินค้า : {dataAdd.name}{' '}
            </div>
          </Header>

          <div>
            <TitleBlock>
              <Title>แก้ไขรายละเอียดสินค้า</Title>
              <AppButtonStyle
                sx={{ width: '228px', mt: '32px' }}
                color="secondary"
                onClick={() => setIsDelete(true)}
              >
                <img src="/images/delete.png" alt="delete" />
                ลบสินค้า
              </AppButtonStyle>
            </TitleBlock>

            <ProductInfo
              setDataAdd={setDataAdd}
              dataAdd={dataAdd}
              type="edit"
              isCheck={isCheck}
              isBefore={isBefore}
              setIsBefore={setIsBefore}
              isHourBefore={isHourBefore}
              setIsHourBefore={setIsHourBefore}
              isAllow={isAllow}
            />

            <ProductImage setDataAdd={setDataAdd} dataAdd={dataAdd} />

            <ProductDescription
              setDataAdd={setDataAdd}
              dataAdd={dataAdd}
              isCheck={isCheck}
            />

            <ContactDetail
              setDataAdd={setDataAdd}
              dataAdd={dataAdd}
              isCheck={isCheck}
            />
          </div>

          <div>
            <AppButtonStyle
              sx={{ width: '200px', mt: '32px' }}
              onClick={handleEdit}
            >
              <img src="/images/save.png" alt="save" />
              บันทึก
            </AppButtonStyle>

            <AppButton
              sx={{ width: '134px', mt: '32px', marginLeft: '10px' }}
              color="secondary"
              onClick={() => setIsBack(true)}
            >
              ยกเลิก
            </AppButton>
          </div>

          {/* Modal delete */}
          <ModalDoubleButton
            title="ลบสินค้า"
            open={isDelete}
            onDimiss={() => setIsDelete(false)}
            handleButtonGreen={handleDelete}
            handleButtonWhite={() => setIsDelete(false)}
            textButtonGreen="ยืนยัน"
            textButtonWhite="ยกเลิก"
          >
            <Content>ยืนยันการลบสินค้าชิ้นนี้?</Content>
          </ModalDoubleButton>

          <ModalBasic
            title=""
            open={isWarningModal}
            onDimiss={() => setIsWarningModal(false)}
          >
            <Content>กรุณาใส่ข้อมูลที่จำเป็นทั้งหมด</Content>
          </ModalBasic>

          <ModalError
            content="เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้งภายหลัง"
            open={isError}
            onDimiss={() => setIsError(false)}
          />
          {/* Modal Cancel */}
          <ModalDoubleButton
            title="ยังไม่ได้บันทึกข้อมูล"
            open={isBack}
            onDimiss={() => setIsBack(false)}
            handleButtonGreen={() => setIsBack(false)}
            handleButtonWhite={() =>
              isBackPrev
                ? route.push(productDetailItemLink(dataAdd.id))
                : route.push(SYSTEM_ADMIN_ROUTE.PRODUCT_CATALOG.path)
            }
            textButtonGreen="อยู่ต่อหน้านี้"
            textButtonWhite="ออก"
          >
            <Content>
              คุณแน่ใจว่าจะออกจากหน้านี้ใช่ไหม
              ข้อมูลที่ใส่ไว้จะไม่ถูกบันทึกไว้นะ
            </Content>
          </ModalDoubleButton>
        </>
      )}
    </Layout>
  )
}
