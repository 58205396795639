import React, { useState, useEffect } from 'react'
import Layout from '../../../layouts/DefaultLayout'
import AddProfile from './AddProfile'
import AddAddress from './AddAddress'
import { AppButton } from '../../../component/common/AppButton'
import styled from 'styled-components'
import Header from '../../../component/Header'
import { SYSTEM_ADMIN_ROUTE } from '../../../route/RouteDefinition'
import { useHistory } from 'react-router-dom'
import { Title } from '../../ProductCatalog/AddNewProduct'
import { createAccount, setUserStatus } from '../../../api'
import ModalDoubleButton from '../../../component/ModalDouleButton'
import { Content } from '../../../page/ForgotPassword'
import ModalBasic from '../../../component/ModalBasic'
import { useAppMaster } from '../../../store/_hook'
import { encryptAuth } from '../../../function/ecrypt'

const AppButtonStyle = styled(AppButton)`
  img {
    padding-right: 8px;
  }
`
const Wrapper = styled.div`
  margin-top: 30px;
`

const CreateAccount = () => {
  const route = useHistory()
  const [isCheck, setIsCheck] = useState(false)
  const [isCheckEx, setIsCheckEx] = useState(false)
  const [isBack, setIsBack] = useState(false)
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [isHadEmail, setIsHadEmail] = useState(false)
  const [isPhone, setIsPhone] = useState(false)
  const [valid, setValid] = useState(false)
  const {
    state: { configMaster }
  } = useAppMaster()

  const validateEmail = (email: any) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  const validate = (email: any) => {
    if (validateEmail(email)) {
      setValid(true)
    } else {
      setValid(false)
    }
    return false
  }

  const [dataCreateProfile, setdataCreateProfile] = useState({
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    groupId: '',
    province: '',
    birthday: '',
    gender: '',
    status: 'inactive',
    dateOfBirth: '',
    monthOfBirth: '',
    yearOfBirth: ''
  })
  const [addressExtend, setAddressExtend] = useState([
    {
      firstname: '',
      lastname: '',
      phone: '',
      province: '',
      district: '',
      ward: '',
      address: '',
      postcode: '',
      isDefault: true
    }
  ])

  const handleUpdate = (indexItem: any, itemToAdd: any) => {
    let newListUpdate = [...addressExtend]
    newListUpdate.map((item, index) => {
      if (index == indexItem) {
        item.firstname = itemToAdd.firstname
        item.lastname = itemToAdd.lastname
        item.phone = itemToAdd.phone
        item.province = itemToAdd.province
        item.district = itemToAdd.district
        item.ward = itemToAdd.ward
        item.address = itemToAdd.address
        item.postcode = itemToAdd.postcode
        item.isDefault = JSON.parse(itemToAdd.isDefault)
      }
    })
    setAddressExtend(newListUpdate)
  }

  const handlePush = () => {
    const newAddress = [...addressExtend]
    newAddress.push({
      firstname: '',
      lastname: '',
      phone: '',
      province: '',
      district: '',
      ward: '',
      address: '',
      postcode: '',
      isDefault: false
    })
    setAddressExtend(newAddress)
  }

  const handleDelete = (indexItem: any) => {
    let newList = [...addressExtend]
    newList.splice(indexItem, 1)
    setAddressExtend(newList)
  }

  const handleChangeDefault = (indexItem: any) => {
    let newList = [...addressExtend]
    newList.map((item, index) => {
      if (index != indexItem) {
        return (item.isDefault = false)
      }
    })

    setAddressExtend(newList)
  }

  // call api
  const dataOriginToAdd = {
    ...dataCreateProfile,
    birthday: dataCreateProfile.yearOfBirth.concat(
      dataCreateProfile.monthOfBirth,
      dataCreateProfile.dateOfBirth
    ),
    addressExtend
  }

  delete dataOriginToAdd.dateOfBirth
  delete dataOriginToAdd.yearOfBirth
  delete dataOriginToAdd.monthOfBirth

  const { email, firstname, lastname, phone, groupId, status } = dataOriginToAdd

  const { addressExtend: arr } = dataOriginToAdd

  let payload = Object.assign({}, dataOriginToAdd)

  const checkRequired = () => {
    if (
      email.trim() === '' ||
      firstname.trim() === '' ||
      lastname.trim() === '' ||
      phone.trim() === '' ||
      groupId === '' ||
      valid === false ||
      isPhone === false
    ) {
      return true
    } else {
      return false
    }
  }

  const checkRequiredEx = () => {
    for (const item of arr) {
      if (
        item.address.trim() === '' ||
        item.firstname.trim() === '' ||
        item.lastname.trim() === '' ||
        item.phone.trim() === '' ||
        item.postcode.trim() === '' ||
        item.province.trim() === '' ||
        item.district.trim() === '' ||
        item.ward.trim() === ''
      ) {
        return true
      }
    }
    return false
  }

  const checkValidPhoneEx = () => {
    for (const item of arr) {
      if (item.phone.trim().length != 10) {
        return true
      }
    }
    return false
  }

  const handleAdd = async () => {
    const isRequiredEx = checkRequiredEx()
    const isRequired = checkRequired()
    const checkPhoneValid = checkValidPhoneEx()
    try {
      if (isRequiredEx || isRequired || checkPhoneValid) {
        setIsCheckEx(true)
        setIsCheck(true)
        setIsWarningModal(true)
      } else {
        payload.email = encryptAuth(
          email,
          configMaster.encryptPublicUser,
          configMaster.characterSeparate
        )
        const res = await createAccount(payload)
        if (!res.error) {
          await setUserStatus({ id: res.data.id, status: status })
          route.push(SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_MANAGER.path)
        } else {
          if (res.message === 'มีอีเมลแล้ว') {
            setIsHadEmail(true)
          }
        }
      }
    } catch (error) {
      console.log(`error`, error)
    }
  }

  useEffect(() => {
    setIsPhone(dataOriginToAdd.phone.length == 10)
  }, [dataOriginToAdd.phone])

  return (
    <>
      <Layout>
        <Header>
          <div className="title-root" onClick={() => setIsBack(true)}>
            จัดการบัญชีผู้ใช้{' '}
          </div>
          <div className="title-sub"> / สร้างบัญชีผู้ใช้ใหม่ </div>
        </Header>

        <Wrapper>
          <Title>สร้างบัญชีผู้ใช้ใหม่</Title>
        </Wrapper>
        <AddProfile
          dataCreateProfile={dataCreateProfile}
          setdataCreateProfile={setdataCreateProfile}
          isCheck={isCheck}
          isHadEmail={isHadEmail}
          validate={validate}
          valid={valid}
          isPhone={isPhone}
        />
        <AddAddress
          addressExtend={addressExtend}
          handlePush={handlePush}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          handleChangeDefault={handleChangeDefault}
          isCheck={isCheckEx}
        />

        <div>
          <AppButtonStyle
            sx={{ width: '200px', mt: '32px' }}
            onClick={() => {
              handleAdd()
            }}
          >
            <img src="/images/save.png" alt="save" />
            บันทึก
          </AppButtonStyle>

          <AppButton
            sx={{ width: '134px', mt: '32px', marginLeft: '10px' }}
            color="secondary"
            onClick={() => setIsBack(true)}
          >
            ยกเลิก
          </AppButton>
        </div>

        <ModalDoubleButton
          title="ยังไม่ได้บันทึกข้อมูล"
          open={isBack}
          onDimiss={() => setIsBack(false)}
          handleButtonGreen={() => setIsBack(false)}
          handleButtonWhite={() =>
            route.push(SYSTEM_ADMIN_ROUTE.CUSTOMER_ACCOUNT_MANAGER.path)
          }
          textButtonGreen="อยู่ต่อหน้านี้"
          textButtonWhite="ออก"
        >
          <Content>คุณแน่ใจว่าจะออกจากหน้านี้ ข้อมูลทั้งหมดจะถูกลบ</Content>
        </ModalDoubleButton>

        <ModalBasic
          title=""
          open={isWarningModal}
          onDimiss={() => setIsWarningModal(false)}
        >
          <Content>กรุณาใส่ข้อมูลที่จำเป็นทั้งหมด</Content>
        </ModalBasic>
      </Layout>
    </>
  )
}
export default CreateAccount
