import React from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import Panel from '../../../component/Panel'
import { Wrapper } from '../AddNewProduct/ContactDetail'
import { ContentBlock } from './ContentBlock'

const WrapperStyle = styled(Wrapper)`
  justify-content: flex-start;
`
const ProductColumn = styled.div`
  flex-basis: 50%;
`
export const ProductContactDetail = ({ dataDetail }) => {
  return (
    <Panel title="หัวข้อ (4/4)  : ช่องทางการติดต่อแบรนด์   ">
      <WrapperStyle>
        <ProductColumn>
          <ContentBlock
            title="เบอร์โทรศัพท์"
            content={dataDetail.phone || '-'}
            className="content-product"
          />
          <ContentBlock
            title="ลิงก์ Line ID (ไม่จำเป็นต้องระบุ)"
            content={dataDetail.lineID || '-'}
          />
          <ContentBlock
            title="ลิงก์ Instagram"
            content={dataDetail.instagram || '-'}
          />
        </ProductColumn>

        <ProductColumn>
          <ContentBlock
            title="อีเมล (ไม่จำเป็นต้องระบุ)"
            content={dataDetail.email || '-'}
            className="content-product"
          />
          <ContentBlock
            title="ลิงก์ Facebook (ไม่จำเป็นต้องระบุ)"
            content={dataDetail.facebook || '-'}
          />
          <ContentBlock
            title="ลิงก์เวปไซต์ (ไม่จำเป็นต้องระบุ)"
            content={dataDetail.website || '-'}
          />
        </ProductColumn>
      </WrapperStyle>
    </Panel>
  )
}
