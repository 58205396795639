import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../../../component/Header'
import Layout from '../../../layouts/DefaultLayout'
import { SYSTEM_ADMIN_ROUTE } from '../../../route/RouteDefinition'
import { Title } from '../../ProductCatalog/AddNewProduct'
import { RequestInfo } from './RequestInfo'
import { UserDetail } from './UserDetail'
import styled from 'styled-components'
import { AppButtonStyle } from '../../ProductCatalog/ProductDetail'
import {
  cancelWasteRequest,
  detailSendWaste,
  updateDescriptionWasteRequest
} from '../../../api'
import ModalDoubleButton from '../../../component/ModalDouleButton'
import { Content } from '../../../page/ForgotPassword'
import ModalError from '../../../component/ModalError'

export const DetailRequest = () => {
  const route = useHistory()
  const [dataDetail, setDataDetail] = useState()
  const [isCancel, setIsCancel] = useState(false)
  const [isError, setIsError] = useState(false)

  const idWaste = route.location.pathname.split('/')[3]

  const fetchDetail = async () => {
    const res = await detailSendWaste({ id: idWaste })

    if (!res.error) {
      setIsCancel(false)
      setDataDetail(res.data)
    }
  }

  useEffect(() => {
    fetchDetail()
  }, [])

  const handleCancel = async () => {
    const res = await cancelWasteRequest({ id: Number(idWaste) })
    if (!res.error) {
      fetchDetail()
    } else setIsError(true)
  }

  const handleEditDescription = async (description) => {
    const res = await updateDescriptionWasteRequest({
      id: Number(idWaste),
      description
    })
    if (!res.error) {
      fetchDetail()
    } else {
      return
    }
  }

  return (
    <>
      <Layout>
        {dataDetail && (
          <>
            {' '}
            <Header>
              <div
                className="title-root"
                onClick={() =>
                  route.push(SYSTEM_ADMIN_ROUTE.WASTE_REQUEST.path)
                }
              >
                รายการออเดอร์ส่งขยะ{' '}
              </div>
              {/* @ts-ignore */}
              <div className="title-sub">
                {/* @ts-ignore */}/ ออเดอร์ส่งขยะ :{' '}
                {dataDetail.userId.firstname}
              </div>
            </Header>
            <Title>รายละเอียดออเดอร์ส่งขยะ</Title>
            <ContentDetail>
              <RequestInfo
                dataDetail={dataDetail}
                setIsCancel={setIsCancel}
                handleEditDescription={handleEditDescription}
                fetchDetail={fetchDetail}
              />
              <UserDetail dataDetail={dataDetail} />
            </ContentDetail>
            <AppButtonStyle
              sx={{ width: '200px', mt: '32px' }}
              onClick={() => route.push(SYSTEM_ADMIN_ROUTE.WASTE_REQUEST.path)}
            >
              <img src="/images/back.png" alt="back" />
              กลับ
            </AppButtonStyle>
            {/* Modal Cancle */}
            <ModalDoubleButton
              title="ยกเลิกออเดอร์"
              open={isCancel}
              onDimiss={() => setIsCancel(false)}
              handleButtonGreen={handleCancel}
              handleButtonWhite={() => setIsCancel(false)}
              textButtonGreen="ยกเลิก"
              textButtonWhite="ออก"
            >
              <Content>คุณแน่ใจว่าจะยกเลิกออเดอร์นี้ไหม</Content>
            </ModalDoubleButton>
            {/* Modal Error */}
            <ModalError
              content="เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้งภายหลัง"
              open={isError}
              onDimiss={() => setIsError(false)}
            />
          </>
        )}
      </Layout>
    </>
  )
}

export const ContentDetail = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 8px;
  margin-top: 32px;
`
